import React, { useEffect }from "react";
import Slide from "@material-ui/core/Slide";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { userActions } from "../actions";
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon  from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from "@material-ui/icons/Group";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import Paper from "@material-ui/core/Paper";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from "@material-ui/core/TextField";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";


import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import TableSortLabel from '@material-ui/core/TableSortLabel';

import ClientAssignedGroupsTable from "./ClientAssignedGroupsTable";
import ClientsUploadedIdProofs from "./ClientsUploadedIdProofs";
import ClientAddresses from './ClientAddresses';
import ClientMobileNumbers from './ClientMobileNumbers';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(ClientOtherContacts, comparator) {
  const stabilizedThis = ClientOtherContacts && ClientOtherContacts.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: 'group_id', numeric: false, label: 'Group Id' ,},
  { id: 'group_id', numeric: false, label: '#' ,},
  { id: 'name', numeric: false, label: 'Name', },
  { id: 'email', numeric: false, label: 'Email' ,},
  { id: 'pan', numeric: false, label: 'PAN' ,},
  { id: 'phone', numeric: false, label: 'Phone' ,},
  { id: 'actions', numeric: false, label: 'Actions' ,},
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'center' : 'center'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              style={{fontWeight: 'bold', fontSize: 17, }}
              // style={ headCell.id === "group_id" ? {fontWeight: 'bold', fontSize: 17,  width: "5%" } : {fontWeight: 'bold', fontSize: 17,  width: "10%" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  // <span className={classes.visuallyHidden}>
                  <span style={{border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,}}
                  >
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
          ))}
        </TableRow>
      </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  table: {
    // minWidth: 400, 
    width:'100%'
  },
  tableWrapper:{
    boxShadow: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '0px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  instructions: {
    marginBottom: theme.spacing(1),
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  headingTitle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
  },
  AddAddress: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "blue",
    textAlign: "left",
  },
  subheadingTitle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    // fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  linkBackToCase: {
    color: "blue",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    textAlign: "right",
  },
  linkNoUnderline: {
    textDecoration: "none",
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
    marginLeft: "-10%",
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  paperModalAddAddress: {
    position: 'absolute',
    width: 500,
    // height: 810,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'1%',
    display:'block'
  },
  modalBodyAddAddress: {
    marginTop: '-2%',
    padding: theme.spacing(2, 4, 3),  
  },
  paperModalForEdit: {
    position: 'absolute',
    width: 500,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    width:'100%',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },

  paperAddNewNumber: {
    position: 'absolute',
    width: 500,
    // height: 650,
    // top:'2%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  modalBodyNewNumber: {
    padding: theme.spacing(2, 4, 3), 
  }
}));

function TaxManagementClientDetailsUI({ intl }) {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [Flag, setFlag] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [navigatedFrom, setNavigatedFrom] = React.useState(JSON.parse(localStorage.getItem('navigated_toshow_client_details_from')));
  const [selected_Client_Showin_Ui, setselected_Client_Showin_Ui] = React.useState();
  const [open, setopen] = React.useState(false);
  const [ClientDetails, setClientDetails] = React.useState();
  const [ClientOtherContactDetails, setClientOtherContactDetails] = React.useState([]);
  const [ClientGroupDetails, setClientGroupDetails] = React.useState([]);
  const [OverallGroupDetails, setOverallGroupDetails] = React.useState([]);
  const [AllGroups, setAllGroups] = React.useState([]);
  const [ClientPhoneNumbers, setClientPhoneNumbers] = React.useState([]);
  const [ClientAddress, setClientAddress] = React.useState([]);
  const [ClientRecentCases, setClientRecentCases] = React.useState([]);
  const [ClientOtherContacts, setClientOtherContacts] = React.useState([]);

  const [OpenAddAddress, setOpenAddAddress] = React.useState(false);
  const [NewAddress, setNewAddress] = React.useState();
  const [newAddressError, setnewAddressError] = React.useState();
  const [NewAddressType, setNewAddressType] = React.useState();
  const [newAddressTypeError, setnewAddressTypeError] = React.useState();
  // const [NewAddressActive, setNewAddressActive] = React.useState('');
  // const [newAddressActiveErr, setnewAddressActiveErr] = React.useState();
  const [RefreshAddressTablePage, setRefreshAddressTablePage] = React.useState(true);

  const [OpenUploadIdProofs, setOpenUploadIdProofs] = React.useState(false);
  const [NewIdProof, setNewIdProof] = React.useState();
  const [NewIdProofName, setNewIdProofName] = React.useState();
  const [newIdProofError, setnewIdProofError] = React.useState();
  const [NewIdProofType, setNewIdProofType] = React.useState('');
  const [newIdProofTypeErr, setnewIdProofTypeErr] = React.useState();
  const [RefreshIdProofTablePage, setRefreshIdProofTablePage] = React.useState(true);

  const [RedirectToAddAnotherClient, setRedirectToAddAnotherClient] = React.useState(false);
  const [RefreshGrpTablePage, setRefreshGrpTablePage] = React.useState(true);

  const [RedirectToClientCases, setRedirectToClientCases] = React.useState(false);

  // let selected_Client_Showin_UiHere = JSON.parse(localStorage.getItem('selected_Client_Showin_Ui'));
  // let selected_Client_Showin_Ui_id = selected_Client_Showin_UiHere.client_id;
  let selected_Client_Showin_Ui_id = JSON.parse(localStorage.getItem('selected_Client_Showin_Ui'));

  const[editContactDetails, seteditContactDetails] = React.useState({
    assesse_id: "",
    name: "",
    email: "",
    phone: "",
    country_code: "",
    pan: "",
    is_active: "",
    client_id: selected_Client_Showin_Ui_id,
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const[openEditContact, setopenEditContact] = React.useState(false);
  const [onlyCountryArray, setonlyCountryArray] = React.useState([
    'in','us', 'gb', 'ca', 'ae', 'sg','np', 'jp', 'de', 'bt', 'au'
  ]);
  const[CountryCode, setCountryCode] = React.useState();
  const[asseNameErr, setasseNameErr] = React.useState("");
  const[asseEmailErr, setasseEmailErr] = React.useState("");
  const[assePhoneErr, setassePhoneErr] = React.useState("");
  const[assePanErr, setassePanErr] = React.useState("");

  const [RefreshMobileNumberTablePage, setRefreshMobileNumberTablePage] = React.useState(true);
  const [openAddNewContact, setopenAddNewContact] = React.useState(false);
  const [newPhoneNumberDetails, setnewPhoneNumberDetails] = React.useState({
    phone: "",
    country_code: "",
    phone_type: "",
    is_active: "",
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')), 
  });
  const [CountryCodeClients, setCountryCodeClients] = React.useState("");
  const [phoneNumberErr, setphoneNumberErr] = React.useState("");
  const [isActiveErr, setisActiveErr] = React.useState("");
  const [phoneTypeErr, setphoneTypeErr] = React.useState("");

  const dispatch = useDispatch();
  const caseDetailsShowFromRedux = useSelector(state => state.caseDetails.caseDetailsShow);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)

  // sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
  rowsPerPage - Math.min(rowsPerPage, ClientOtherContacts && ClientOtherContacts.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  };

  useEffect( () => {
    dispatch(userActions.checkSessionValidity());

    // localStorage.setItem('view_clients_active_cases', []);
    localStorage.removeItem('view_clients_active_cases');
    // to get clients assigned groups
    setShowProcedure(true);
    setFlag(true);
    let selected_Client_Showin_Ui = JSON.parse(localStorage.getItem('selected_Client_Showin_Ui'));
    console.log("selected_Client_Showin_Ui all in client details======== ",selected_Client_Showin_Ui)


    setselected_Client_Showin_Ui(selected_Client_Showin_Ui)

    let navigatedFrom = JSON.parse(localStorage.getItem('navigated_toshow_client_details_from'));
    console.log("=========navigatedFrom on client details",navigatedFrom)

    // if( navigatedFrom ==  "TaxManagemantClientGroup"){
    //   let selected_Client_Showin_Ui = JSON.parse(localStorage.getItem('selected_Client_Showin_Ui'));
    //   let selected_Client_Showin_Ui_id = selected_Client_Showin_Ui.user_id;
    //   console.log("selected_Client_Showin_Ui all in client details======== ",selected_Client_Showin_Ui)
    //   console.log("selected_Client_Showin_Ui_id in client details======== ",selected_Client_Showin_Ui_id)
      
    //   const requestOptions = {
    //     method: 'POST',
    //     headers: authHeader(),
    //     body: JSON.stringify({'client_id': selected_Client_Showin_Ui_id,
    //       'device_token': device_token, 'session_id': session_id })
    //   };
    // }
    // else{
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'client_id': selected_Client_Showin_Ui,
          'device_token': device_token, 'session_id': session_id, 'business_id': business_id })
      };
    

      fetch( process.env.REACT_APP_GET_CLIENT_DETAILS , requestOptions)
      .then((Response) => Response.json())
      .then( async(clientDetails) => {
        setShowProcedure(false);
        console.log("clientDetails from api",clientDetails.data.client_details.addresses.addresses);
        if(clientDetails.success == true)
        {
          setClientDetails(clientDetails.data.client_details);
          setClientOtherContactDetails(clientDetails.data.client_details.contacts);

          console.log("clientDetails.data.client_details",clientDetails.data.client_details.phone_numbers)
          if(clientDetails.data.client_details.phone_numbers){
            setClientPhoneNumbers(clientDetails.data.client_details.phone_numbers.phonenumbers)
          }
          else{
            setClientPhoneNumbers([])
          }

          if(clientDetails.data.client_details.addresses.addresses){
            console.log("check == if ==")
            setClientAddress(clientDetails.data.client_details.addresses.addresses)
          }
          else{
            console.log("check == else ==")
            setClientAddress([])
          }

          if(clientDetails.data.client_details.recent_cases){
            setClientRecentCases(clientDetails.data.client_details.recent_cases)
          }
          else{
            setClientRecentCases([])
          }

          if(clientDetails.data.client_details.contacts){
            setClientOtherContacts(clientDetails.data.client_details.contacts)
          }
          else{
            setClientRecentCases([])
          }
          

          // // 
          // // console.log("requestOptions for client groups",requestOptions);
          // fetch( process.env.REACT_APP_GET_CLIENT_GROUPS , requestOptions)
          // .then((Response) => Response.json())
          // .then( async(clientGroupDetails) => {
          //   setShowProcedure(false);
          //   console.log("clientGroupDetails from api",clientGroupDetails);
          //   if(clientGroupDetails.success == true)
          //   {
          //     setClientGroupDetails(clientGroupDetails.data.client_groups_info.client_groups);
          //   }
          //   else if(clientGroupDetails.success == false){
          //     setClientGroupDetails([]);
          //     Swal.fire({
          //       icon: 'error',
          //       text: clientGroupDetails.errors,
          //       confirmButtonColor: '#d33',
          //       confirmButtonText: 'OK',
          //       timer: 9000,
          //     })
          //   }
          // })
          // .catch(err => {
          //   setShowProcedure(false);
          //   Swal.fire({
          //     icon: 'error',
          //     text: "Server Error. Please try again.",
          //     confirmButtonColor: '#d33',
          //     confirmButtonText: 'OK'
          //   })
          // });

          // 
          const requestOptionsBusinessClientGrp = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'business_id': business_id,
              'device_token': device_token, 'session_id': session_id })
          };
          fetch( process.env.REACT_APP_GET_BUSINESS_CLIENT_GROUPS , requestOptionsBusinessClientGrp)
          .then((Response) => Response.json())
          .then( async(AllGroups) => {
            setShowProcedure(false);
            console.log("AllGroups on show case table",AllGroups);
            if(AllGroups.success == true)
            {
              let allGroups = AllGroups.data.client_groups
              console.log("allGroups are",allGroups, allGroups.length)
              setAllGroups(allGroups);
            }
            else if(AllGroups.success == false){
              setAllGroups([]);
            }
          })
          .catch(err => {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });

        }
        else if(clientDetails.success == false){
          setClientDetails([]);
          setClientPhoneNumbers([]);
          setClientAddress([]);
          setClientRecentCases([]);
          setClientOtherContacts([])
          Swal.fire({
            icon: 'error',
            // text: clientDetails.errors,
            text: "Client is not Registered yet",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    // }

  }, [Flag]);
  
  // Add address functions
  const handleAddAddressOpen = () => {
    dispatch(userActions.checkSessionValidity());
    setOpenAddAddress(true);
  }

  const handleAddAddressClose = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("handleAddAddressClose",handleAddAddressClose);
    setOpenAddAddress(false);
    setNewAddress();
    setNewAddressType();
    // setNewAddressActive('');

    setnewAddressError();
    setnewAddressTypeError();
    // setnewAddressActiveErr();
  }

  const handelAddAddressMinimize = () => {
    setOpenAddAddress(false);
  }

  const handleAddAddressClear = () => {
    console.log("handleAssignGroupClear",handleAssignGroupClear);
    setNewAddress();
    setNewAddressType();
    // setNewAddressActive('');

    setnewAddressError();
    setnewAddressTypeError();
    // setnewAddressActiveErr();
  }

  const validateAddAddress = () => {
    let newAddressError = '';
    let newAddressTypeError = '';
    // let newAddressActiveErr = '';

    if(!NewAddress)  {
      newAddressError = 'Please Enter Address';
    }

    if(!NewAddressType)  {
      newAddressTypeError = 'Please Select Address Type';
    }

    // if( NewAddressActive == "" &&  NewAddressActive !== false  && NewAddressActive !== true)  {
    //   newAddressActiveErr = 'Please Select Is Active';
    // }
    

    if(newAddressError || newAddressTypeError ){
      setnewAddressError(newAddressError);
      setnewAddressTypeError(newAddressTypeError);
      // setnewAddressActiveErr(newAddressActiveErr);
      
      return false;
    }

    return true;
  }

  const handleAddAddressSubmit = () => {
    dispatch(userActions.checkSessionValidity());

    let updated_by_here = '';
    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
    console.log("Role of user",role_of_user)
    if( role_of_user === "client"){
      updated_by_here = "self"
    }
    else{
      updated_by_here = "business"
    }

    console.log("handleAddAddressSubmit",NewAddress);
    console.log("ClientDetails . userid",ClientDetails.user_id)
    console.log("ClientDetails . client_id",ClientDetails.client_id)
    const isValid = validateAddAddress();
    if(isValid){

      setOpenAddAddress(false);
      setShowProcedure(true);
      setRefreshAddressTablePage(false);

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          // 'client_user_id':ClientDetails.user_id , 
          'client_id':ClientDetails.client_id,
          'updated_by': updated_by_here,
          'device_token': device_token, 'session_id': session_id,
          'address_id': '', 'full_address': NewAddress, 'is_active': true, 'address_type': NewAddressType, 'business_id': business_id
        })
      };
      fetch( process.env.REACT_APP_CREATE_ADDRESS , requestOptions)
        .then((Response) => Response.json())
        .then(addressAdded => {
          setShowProcedure(false)
          console.log("addressAdded",addressAdded);
          console.log("addressAdded",addressAdded.success);

          if(addressAdded.success == true){
            Swal.fire({
              icon: 'success',
              text: "Address Added Succesfully",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setShowProcedure(false);
            setNewAddress();
            setNewAddressType();
            // setNewAddressActive('');

            setnewAddressError();
            setnewAddressTypeError();
            // setnewAddressActiveErr();
            setRefreshAddressTablePage(true);
          }
          if(addressAdded.success == false ){
            setOpenAddAddress(true);
            Swal.fire({
              icon: 'error',
              text: addressAdded.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              // timer: 5000,
            })
          } 
        setFlag(false);

        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      
    }
  }

  const bodyAddAddress = (
    <div className={classes.paperModalAddAddress}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={10} >
            <h2 style={{marginLeft:'43%'}}>Add Address</h2>
          </Grid>
          {/* <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handelAddAddressMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handleAddAddressClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid> */}
        </Grid> 
      </div>

      <div className={classes.modalBodyAddAddress}>
            
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Full Address"
              name="address"
              autoComplete="address"
              inputProps={{ maxLength: 200 }}
              autoFocus
              value={NewAddress}
              onChange={(e) => setNewAddress(e.target.value)}
            />
            <div className={classes.validation}>{(NewAddress) ? (<div></div>) : (newAddressError)}</div>

            <FormControl className={classes.formControl}>
              <InputLabel id="address_type">Address Type*</InputLabel>
              <Select
                labelId="address_type"
                id="address_type"
                value={NewAddressType}
                onChange={(e) => setNewAddressType(e.target.value)}
              >
                {/* {yesNo.map(item => */}
                  <MenuItem value="home">Home</MenuItem>
                  <MenuItem value="work">Work</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                {/* )} */}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(NewAddressType)?(<div></div>):(newAddressTypeError)}</div>

            {/* <FormControl className={classes.formControl}>
              <InputLabel id="is_active">Is Active*</InputLabel>
              <Grid container item xs={12} style={{marginTop:"8%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  defaultValue={NewAddressActive}
                  onChange={ (e) => setNewAddressActive(JSON.parse(e.target.value))}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
            </FormControl>
            <div className={classes.validation}>{(NewAddressActive == "" &&  NewAddressActive !== false  && NewAddressActive !== true)?(newAddressActiveErr):(<div></div>)}</div> */}
            
          </Grid>
        </Grid>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'10%'}}>
          <Button
            type="submit"
            //   fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleAddAddressSubmit}
            >
              Add
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              // color="grey"
              className={classes.submit}
              onClick={handleAddAddressClose}
            >
              Cancel
            </Button>
        </div>
      </div>
    </div>
  )


  // Upload Id Proof functions
  const handleUploadIdProofsOpen = () => {
    dispatch(userActions.checkSessionValidity());
    setOpenUploadIdProofs(true);
  }

  const handleUploadIdProofsClose = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("handleAddAddressClose",handleAddAddressClose);
    setOpenUploadIdProofs(false);
    setNewIdProof();
    setNewIdProofType('');
    setnewIdProofError();
    setNewIdProofName();
    setnewIdProofTypeErr();
  }

  const handelUploadIdProofsMinimize = () => {
    setOpenUploadIdProofs(false);
  }

  const handleUploadIdProofsClear = () => {
    console.log("handleUploadIdProofsClear");
    setNewIdProof();
    setNewIdProofType('');
    setnewIdProofError();
    setNewIdProofName();
    setnewIdProofTypeErr();
  }

  const validateUploadIdProofs = () => {
    let newIdProofError = '';
    let newIdProofTypeErr = '';

    if(!NewIdProof)  {
      newIdProofError = 'Please Select File';
    }

    if(!NewIdProofType)  {
      newIdProofTypeErr = 'Please Select Id Proof Type';
    }

    if(newIdProofError || newIdProofTypeErr){
      setnewIdProofError(newIdProofError);
      setnewIdProofTypeErr(newIdProofTypeErr)
      
      return false;
    }

    return true;
  }

  const handleUploadIdProofsSubmit = () => {
    dispatch(userActions.checkSessionValidity());
    
    let updated_by_here = '';
    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
    console.log("Role of user",role_of_user)
    if( role_of_user === "client"){
      updated_by_here = "self"
    }
    else{
      updated_by_here = "business"
    }

    console.log("handleUploadIdProofsSubmit",NewIdProofName,NewIdProof );
    const isValid = validateUploadIdProofs();
    
    if(isValid){
      console.log("handleUploadIdProofsSubmit in is valid",NewIdProofName,NewIdProof );
      setRefreshIdProofTablePage(false);
      setShowProcedure(true);

      const requestOptionsSecond = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'doc_type': 'proof', 'doc_name': NewIdProof.name, 'doc_extra_info': '',
          'device_token': device_token, 'session_id': session_id, 
          'client_user_id': ClientDetails.user_id, 'business_id': business_id
        })
      }

      return fetch( process.env.REACT_APP_GET_UPLOAD_INFO , requestOptionsSecond)
        .then((Response) => Response.json())
        .then(checkResponseOfS3Upload => {
          console.log("checkResponseOfS3Upload", checkResponseOfS3Upload)
          if (checkResponseOfS3Upload.success === true) {
            // console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
            console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.upload_info.url)

            const formData = new FormData();
            Object.keys(checkResponseOfS3Upload.data.upload_info.fields).forEach(key => {
              formData.append(key, checkResponseOfS3Upload.data.upload_info.fields[key]);
            });
            formData.append("file", NewIdProof);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", checkResponseOfS3Upload.data.upload_info.url, true);
            xhr.send(formData);

            xhr.onload = function () {
              if (this.status === 204) {
                console.log("-----SUCCESSFULLY UPLOADED");

                // console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
                console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.upload_info.url)
                
                let user = JSON.parse(localStorage.getItem('user'));

                const requestOptions = {
                  method: 'POST',
                  headers: {'Authorization': 'Token ' + user.key},
                  // headers: authHeader(),
                  // body: formdata,
                  body: JSON.stringify({
                    'updated_by': updated_by_here, 
                    // 'client_user_id': ClientDetails.user_id, 
                    'client_id':ClientDetails.client_id,
                    'idproof': checkResponseOfS3Upload.data.public_url.url, 'proof_type': NewIdProofType,
                    'device_token': device_token, 'session_id': session_id, 'business_id': business_id
                  })
                };
                return fetch( process.env.REACT_APP_UPLOAD_ID_PROOF , requestOptions)
                  .then((Response) => Response.json())
                  .then( IdProofSaved => {
                    setShowProcedure(false);
                    console.log("IdProofSaved ",IdProofSaved.success);
                    console.log("IdProofSaved ",IdProofSaved);
                    if(IdProofSaved.success == true){
                      Swal.fire({
                      icon: 'success',
                      text: "Id Proof Saved Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setOpenUploadIdProofs(false);
                    setShowProcedure(false);
                    setNewIdProof();
                    setNewIdProofType('');
                    setnewIdProofError();
                    setNewIdProofName();
                    setnewIdProofTypeErr();
                    setRefreshIdProofTablePage(true);
                  }
                  if(IdProofSaved.success == false){
                    setShowProcedure(false);
                    setOpenUploadIdProofs(true);
                    Swal.fire({
                      icon: 'error',
                      text: IdProofSaved.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setRefreshIdProofTablePage(true);
                  }  
                  setFlag(false);    
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                  setRefreshIdProofTablePage(true);
                });

              }
              else {
                console.log("---------FAILED UPLOAD");
                setShowProcedure(false);
                setRefreshIdProofTablePage(true);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Upload failed to s3",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            }
          }
          else {
            setShowProcedure(false);
            setRefreshIdProofTablePage(true);
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error (s3). Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          setRefreshIdProofTablePage(true);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error (s3). Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        })
      

      
    }
  }

  const onUploadIdProof = (event) => {
    console.log("event.target.files[0]",event.target.files[0])
    console.log("event.target.files[0] name",event.target.files[0].name)
    setNewIdProofName(event.target.files[0].name);
    setNewIdProof(event.target.files[0])
  }

  const bodyUploadIdProofs = (
    <div className={classes.paperModalAddAddress}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={10} >
            <h2 style={{marginLeft:'43%'}}>Upload Id Proof</h2>
          </Grid>
          {/* <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handelAddAddressMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handleAddAddressClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid> */}
        </Grid> 
      </div>

      <div className={classes.modalBodyAddAddress}>
            
        <Grid item xs={12} container spacing={2} style={{marginTop:'2%'}}>

          <FormControl className={classes.formControl}>
            <InputLabel id="NewIdProofType">Proof Type*</InputLabel>
            <Select
              labelId="NewIdProofType"
              id="NewIdProofType"
              value={NewIdProofType}
              onChange={(e) => setNewIdProofType(e.target.value) }
            >
              <MenuItem value={'AADHAR'}>Aadhaar Card</MenuItem>
              <MenuItem value={'PAN-CARD'}>PAN Card</MenuItem>
              <MenuItem value={'PASSPORT'}>Passport</MenuItem>
              <MenuItem value={'VOTER-ID'}>Voting Card</MenuItem>
              <MenuItem value={'DRIVING-LICENSE'}>Driving License</MenuItem>
            </Select>
          </FormControl>
          <div className={classes.validation}>{(NewIdProofType)?(<div></div>):(newIdProofTypeErr)}</div>

          <Grid item xs={12}>
          <div style={{marginTop:'8%'}}>
            <input type='file' 
              // accept=".xlsx"
              id="idProf"
              onChange={ (e) => onUploadIdProof(e)} 
            />
          </div>
            <div className={classes.validation}>{(NewAddress) ? (<div></div>) : (newIdProofError)}</div>
          </Grid>
        </Grid>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'10%'}}>
          <Button
            type="submit"
            //   fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleUploadIdProofsSubmit}
            >
              Upload
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              // color="grey"
              className={classes.submit}
              onClick={handleUploadIdProofsClose}
            >
              Cancel
            </Button>
        </div>
      </div>
    </div>
  )


  // Assign group functions
  const handleAssignGroupOpen = () => {
    // console.log("handleAssignGroupOpen",handleAssignGroupOpen);
    dispatch(userActions.checkSessionValidity());
    if( AllGroups.length < 1 ){
      Swal.fire({
        icon: 'error',
        text: "Groups are not created yet.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }
    else{
      setShowProcedure(true);
      setopen(true);
      const requestOptionsBusinessClientGrp = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'business_id': business_id,
          'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_BUSINESS_CLIENT_GROUPS , requestOptionsBusinessClientGrp)
        .then((Response) => Response.json())
        .then( async(AllGroups) => {
          setShowProcedure(false);
          console.log("AllGroups on show case table",AllGroups);
          if(AllGroups.success == true)
          {
            let clientGrps = ClientGroupDetails
            let allGroups = AllGroups.data.client_groups
            // console.log("allGroups are===================",allGroups, allGroups.length)
            // console.log("clientGrps are===================",clientGrps, clientGrps.length)
            await setOverallGroupDetails(
              allGroups.map((f) => {
                // console.log("allGroups.map!!!!!!",f)
                for (let index = 0; index < clientGrps.length; index++) {
                  if(f.client_group_id === clientGrps[index].group_id){
                    // console.log("if f.client_group_id === clientGrps[index].group_id!!!!!",f)
                    f.selected = true;
                  break;
                  }
                  else{
                    // console.log("else f.client_group_id === clientGrps[index].group_id!!!!!!",f)
                    f.selected = false;
                  }
                }
                return f ;
              })
            )
          }
          else if(AllGroups.success == false){
            setOverallGroupDetails([])
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const handleAssignGroupClose = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("handleAssignGroupClose",handleAssignGroupClose);
    setopen(false);
  }

  const handelAssignGroupsMinimize = () => {
    setopen(false);
  }

  const handleAssignGroupClear = () => {
    console.log("handleAssignGroupClear",handleAssignGroupClear);
  }

  const toggleClientGroupCheckBox = async(value,item) => {
    dispatch(userActions.checkSessionValidity());
    console.log("toggleClientGroupCheckBox",value,item)
    await setOverallGroupDetails(
      OverallGroupDetails.map((f)=> {
        if(f.client_group_id === item.client_group_id){
          f.selected = !f.selected;
        }
        return f ;
      })
    )
  }

  const handleAssignGroupSubmit = () => {
    setRefreshGrpTablePage(false);
    dispatch(userActions.checkSessionValidity());
    console.log("handleAssignGroupSubmit",handleAssignGroupSubmit);
    setShowProcedure(true)
    setopen(false);
    const clientNewGroups = [];
    OverallGroupDetails.map((f)=> {
      if(f.selected == true){
        // console.log("true f is",f)
        clientNewGroups.push(f.client_group_id);
      }
    })
    console.log("clientNewGroups is",clientNewGroups);

    const requestOptionsClientNewGroups = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'client_groups': clientNewGroups, 'client_id': selected_Client_Showin_Ui,
        'device_token': device_token, 'session_id': session_id, 'business_id': business_id })
    };
    
    // console.log("requestOptionsClientNewGroups",requestOptionsClientNewGroups)
    fetch( process.env.REACT_APP_UPDATE_GROUP_CLIENT , requestOptionsClientNewGroups)
      .then((Response) => Response.json())
      .then( async(clientGroupsUpdated) => {
        setShowProcedure(false)
        setFlag(false)
        setRefreshGrpTablePage(true);
        console.log("clientGroupsUpdated in api",clientGroupsUpdated);
        if(clientGroupsUpdated.success == true)
        {
          console.log("clientGroupsUpdated on show case details ",clientGroupsUpdated.data.client_groups_info.client_groups_info);
          // refreshClientGroups();
          Swal.fire({
            icon: 'success',
            text: "Groups Assigned to client",
            confirmButtonColor: 'primary',
            confirmButtonText: 'OK'
          })
        }
        else if(clientGroupsUpdated.success == false){
          console.log("clientGroupsUpdated false ",clientGroupsUpdated);
          Swal.fire({
            icon: 'error',
            text: clientGroupsUpdated.error,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
        setFlag(false);
      })
      .catch(err => {
        setShowProcedure(false);
        setRefreshGrpTablePage(true);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const body = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={10} >
            <h2 style={{marginLeft:'43%'}}>Assign Groups</h2>
          </Grid>
          {/* <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handelAssignGroupsMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handleAssignGroupClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid> */}
        </Grid> 
      </div>

      <div className={classes.modalBody} style={{position:'absolute'}}>
        {/* {
          ( OverallGroupDetails && OverallGroupDetails.lenght < 2)?
          (
            <div>
              <Grid>There are no groups created yet.</Grid>
            </div>
          ):( */}
            <ul style={{listStyle: "none", marginLeft: "-8%"}}>
              {OverallGroupDetails && OverallGroupDetails.map((item, index) =>
                <li key={item.item_id}>
                  <Grid container style={{marginTop:"1%", backgroundColor:'#d9abf5'}}>
                    <Grid container item xs={1} style={{marginTop:"2.5%", marginLeft:'2%'}}>
                      <input
                        type="checkbox"
                        checked={item.selected}
                        onChange={e => toggleClientGroupCheckBox(e.target.checked, item)}
                      />
                    </Grid>
                    <Grid container item xs={10}>
                      <Grid item xs={11} style={{ fontSize: 15, color:'black', padding: 10 }}>{item.name}</Grid>
                    </Grid>
                  </Grid>  
                  {/* <hr/>  */}
                </li>
              )}
            </ul>
          {/* )
        } */}
        

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            //   fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleAssignGroupSubmit}
            >
              Assign
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              // color="grey"
              className={classes.submit}
              onClick={handleAssignGroupClose}
            >
              Cancel
            </Button>
        </div>
      </div>
    </div>
  )

  // 
  const handleAddAnotherContactClick = async() => {
    console.log("Add another Client");
    await localStorage.setItem('navigated_to_add_contact_from', JSON.stringify("ClientDetailsUi"));
    await localStorage.setItem('client_details_to_add_contact', JSON.stringify(ClientDetails));
    await setRedirectToAddAnotherClient(true);
  }

  //To edit other contact details
  const handleEditContact = async(item) =>{
    console.log("contact selected",item)
    await seteditContactDetails({ ...editContactDetails,
      assesse_id: item.assesse_id,
      name: item.name,
      email: item.email,
      phone: item.phone,
      country_code: item.country_code,
      pan: item.pan,
      is_active: item.is_active,
      client_id: selected_Client_Showin_Ui,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
    });
    if(item.phone){
      console.log("Phone Number is=======",item.phone)
      let phoneHere = item.phone
      let splitsNois = phoneHere.split('-',1)
      console.log("splitsNois in edit=-=-=-=-=",splitsNois)
      // var onlyCodeIs = splitsNois.split(" ",1);
      // console.log("splitsNois only country code in edit=-=-=-=-=",splitsNois)
      if(splitsNois == "+975"){
        console.log("split is 975)))))))))",splitsNois)
        setCountryCode("bt")
      }
      else if(splitsNois == "+61"){
        console.log("split is 61)))))))))",splitsNois)
        setCountryCode("au")
      }
      else if(splitsNois == "+1"){
        console.log("split is 1)))))))))",splitsNois)
        setCountryCode("us")
      }
      else if(splitsNois == "+49"){
        console.log("split is 49)))))))))",splitsNois)
        setCountryCode("de")
      }
      else if(splitsNois == "+91"){
        console.log("split is 91)))))))))",splitsNois)
        setCountryCode("in")
      }
      else if(splitsNois == "+81"){
        console.log("split is 81)))))))))",splitsNois)
        setCountryCode("jp")
      }
      else if(splitsNois == "+977"){
        console.log("split is 977)))))))))",splitsNois)
        setCountryCode("np")
      }
      else if(splitsNois == "+65"){
        console.log("split is 65)))))))))",splitsNois)
        setCountryCode("sg")
      }
      else if(splitsNois == "+971"){
        console.log("split is 971)))))))))",splitsNois)
        setCountryCode("ae")
      }
      else if(splitsNois == "+44"){
        console.log("split is 44)))))))))",splitsNois)
        setCountryCode("gb")
      }
      // else if(splitsNois == "+1"){
      //   console.log("split is canada",splitsNois)
      //   setCountryCode("ca")
      // }
    }
    console.log("editContactDetails",editContactDetails);
    handleEditContactDetailsOpen();
  } 

  const handleEditContactDetailsOpen = async() => {
    setopenEditContact(true);
  }

  const handleEditContactDetailsClose = async() => {
    setopenEditContact(false);
    setShowProcedure(false);
    await seteditContactDetails({
      assesse_id: "",
      name: "",
      email: "",
      phone: "",
      country_code: "",
      pan: "",
      is_active: "",
      client_id: selected_Client_Showin_Ui,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
    });
    setasseNameErr("");
    setasseEmailErr("");
    setassePhoneErr("");
    setassePanErr("");
  }

  const validateEditContact = () =>{
    let asseNameErr = '';
    let asseEmailErr = '';
    let assePhoneErr = '';
    let assePanErr = '';

    if( !editContactDetails.name ){
      asseNameErr = "Please Enter Name"
    }

    if( !editContactDetails.email ){
      asseEmailErr = "Please Enter Email"
    }
    else if(typeof editContactDetails.email !== "undefined"){
      let lastAtPos = editContactDetails.email.lastIndexOf('@');
      let lastDotPos = editContactDetails.email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
        asseEmailErr = "Email is not valid";
          if(asseEmailErr) {
            setasseEmailErr(asseEmailErr);
          }
      }
    }

    if( !editContactDetails.phone ){
      console.log("!editContactDetails.phone ")
      assePhoneErr = "Please Enter Phone Number"
    }
    else if (typeof editContactDetails.phone !== "undefined") {
      console.log("typeof editContactDetails.phone !== undefined",editContactDetails.phone)
      if (CountryCode == "in"){
        console.log("editContactDetails.phone.length",editContactDetails.phone.length)
        if(editContactDetails.phone.length !== 10){
            assePhoneErr = "Please enter valid phone number.";
          }
      }
      else if(CountryCode == "us"){
        console.log("editContactDetails.phone.length us",editContactDetails.phone.length)
        if(editContactDetails.phone.length !== 10){
            assePhoneErr = "Please enter valid phone number.";
          }
      }
      else if(CountryCode == "gb"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(editContactDetails.phone.length !== 10){
          assePhoneErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "ca"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(editContactDetails.phone.length !== 10){
          assePhoneErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "ae"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(editContactDetails.phone.length !== 12){
          assePhoneErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "sg"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(editContactDetails.phone.length !== 8){
          assePhoneErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "np"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(editContactDetails.phone.length !== 12){
          assePhoneErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "jp"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(editContactDetails.phone.length !== 10){
          assePhoneErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "de"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(editContactDetails.phone.length !== 12){
          assePhoneErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "bt"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(editContactDetails.phone.length !== 12){
          assePhoneErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "au"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(editContactDetails.phone.length !== 13){
          assePhoneErr = "Please enter valid phone number.";
        }
      }
    }

    if( !editContactDetails.pan ){
      assePanErr = "Please Enter PAN Number"
    }
    else if (typeof editContactDetails.pan !== "undefined") {
      var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
      if(editContactDetails.pan.length !== 10){
        console.log("in != 10")
        assePanErr = "PAN Number is Not Valid";
      }
      else if (!pattern.test(editContactDetails.pan)) {
        console.log("in string check")
        assePanErr = "PAN Number is Not Valid";
      } 
    }

    if( asseNameErr || asseEmailErr || assePhoneErr || assePanErr ){
      setasseNameErr(asseNameErr);
      setasseEmailErr(asseEmailErr);
      setassePhoneErr(assePhoneErr);
      setassePanErr(assePanErr);

      return false;
    }
    return true;
  }

  const handleEditContactDetailsSubmit = () => {
    console.log("handleEditContactDetailsSubmit",editContactDetails)
    setShowProcedure(true);
    const isValid = validateEditContact();
    if(isValid){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(editContactDetails)
      };
      return fetch( process.env.REACT_APP_UPDATE_CLIENT_CONTACT , requestOptions)
        .then((Response) => Response.json())
        .then(contactEdited => {
          setShowProcedure(false);
          console.log("contactEdited",contactEdited.success);
          console.log("contactEdited",contactEdited);
          if(contactEdited.success == true){
            Swal.fire({
              icon: 'success',
              text: "Contact Details Updated Succesfully",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setopenEditContact(false);
            seteditContactDetails({
              assesse_id: "",
              name: "",
              email: "",
              phone: "",
              country_code: "",
              pan: "",
              is_active: "",
              client_id: selected_Client_Showin_Ui,
              device_token: JSON.parse(localStorage.getItem('device_token')),
              session_id: JSON.parse(localStorage.getItem('session_id')), 
            });
            setasseNameErr("");
            setasseEmailErr("");
            setassePhoneErr("");
            setassePanErr("");
          }
          if(contactEdited.success == false ){
            setopenEditContact(true);
            Swal.fire({
              icon: 'error',
              text: contactEdited.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              // timer: 5000,
            })
          } 
          setFlag(false);            
      })
      .catch(err => {
        setShowProcedure(false);
        setopenEditContact(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  const phoneNumberChange = (clientNumber,e,formattedValue,value) => {
    console.log("phoneNumberChange clientNumber",clientNumber)
    console.log("phoneNumberChange e",e)
    console.log("phoneNumberChange value",value)
    let countryCodeIs = "+" + e.dialCode
    let splitNoIs = ""
    if(e.countryCode == "in"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "us"){
      // splitNoIs = value.split(" ")[1];
      // splitNoIs = value.substr(value.indexOf(' ')+1);

      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "gb"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ca"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ae"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "sg"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "np"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "jp"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "de"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "bt"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "au"){
      splitNoIs = value.split(" ")[1];
    }
    console.log("splitNoIs",splitNoIs)
    let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
    console.log("noHereIs",noHereIs)
    setCountryCode(e.countryCode)
    // seteditContactDetails({...editContactDetails, phone:noHereIs })
    seteditContactDetails({...editContactDetails, phone: splitNoIs, country_code: countryCodeIs })
  }

  const bodyEditContact = (
    <div className={classes.paperModalForEdit}>
    <div className={classes.addNewBusinessHeaderBackgroud}> 
      <h2>Edit Contact</h2>
    </div>
    <div className={classes.modalBody}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        autoFocus
        value={editContactDetails.name}
        onChange={(e) => seteditContactDetails({...editContactDetails, name:e.target.value }) }
      />
      <div className={classes.validation}>{(editContactDetails.name)?(<div></div>):(asseNameErr)}</div>
      
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="email"
        name="email"
        value={editContactDetails.email}
        onChange={(e) => seteditContactDetails({...editContactDetails, email:e.target.value }) }
      />
      {/* <div className={classes.validation}>{(editContactDetails.email)?(<div></div>):(asseEmailErr)}</div> */}
      <div className={classes.validation}>{asseEmailErr}</div>

      <TextField
        margin="normal"
        required
        fullWidth
        id="pan"
        label="pan"
        name="pan"
        placeholder="XXXXX0000X"
        inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
        value={editContactDetails.pan}
        onChange={(e) => seteditContactDetails({...editContactDetails, pan:e.target.value.toUpperCase() }) }
      />
      <div className={classes.validation}>{assePanErr}</div>

      <Grid item xs={12} className={classes.subHeadingLabel} style={{marginTop:'3%'}}>
        Phone Number*
      </Grid>
      <Grid item xs={12}>
        <PhoneInput
          inputStyle={{
            width: "100%",
            height: "50px",
            fontSize: "15px",
          }}
          countryCodeEditable={false}
          onlyCountries={onlyCountryArray}
          masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                  sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                  au: '.............'  }}
          specialLabel=""
          country={'in'}
          // value={editContactDetails.phone}
          value={editContactDetails.country_code + editContactDetails.phone}
          onChange={(usersNumber,e,formattedValue,value) => phoneNumberChange(usersNumber,e,formattedValue,value)}
        />
      </Grid>
      <div className={classes.validation}>{assePhoneErr}</div>

    <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'13%'}}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleEditContactDetailsSubmit}
        >
          Update Contact
        </Button>
        <div style={{marginLeft:'2%'}}></div>
        <Button
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handleEditContactDetailsClose}
        >
          Cancel
        </Button>
      </div>
    </div>
    </div>
  )

  // Add New Mobile Number 
  const handelAddNewContactOpen = () =>{
    setopenAddNewContact(true)
  }

  const handelAddNewContactClose = () =>{
    setopenAddNewContact(false);
    setnewPhoneNumberDetails({
      phone: "",
      country_code: "",
      phone_type: "",
      is_active: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
    });
    setphoneNumberErr("");
    setisActiveErr("");
    setphoneTypeErr("");
  }
  const validateAddNewPhoneNumber = () => {
    console.log("CountryCodeClients is",CountryCodeClients)
    let phoneNumberErr = '';
    let isActiveErr = '';
    let phoneTypeErr = '';

    if(!newPhoneNumberDetails.phone)  {
        phoneNumberErr = 'Please Enter Phone Number';
    }
    else if (typeof newPhoneNumberDetails.phone !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/)

      // if (!pattern.test(newPhoneNumberDetails.phone)) {
      //   // isValid = false;
      //   phoneNumberErr = "Please enter only numbers.";
      // }else if(newPhoneNumberDetails.phone.length <= 10){
      //   // isValid = false;
      //   phoneNumberErr = "Please enter valid phone number.";
      // }
      if (CountryCodeClients == "in"){
        // if(newPhoneNumberDetails.phone.length !== 15){
          if(newPhoneNumberDetails.phone.length !== 10){
            phoneNumberErr = "Please enter valid phone number.";
          }
      }
      else if(CountryCodeClients == "us"){
        // if(newPhoneNumberDetails.phone.length !== 17){
        if(newPhoneNumberDetails.phone.length !== 10){
            phoneNumberErr = "Please enter valid phone number.";
          }
      }
      else if(CountryCodeClients == "gb"){
        console.log("newPhoneNumberDetails.phone.length gb",newPhoneNumberDetails.phone.length)
        if(newPhoneNumberDetails.phone.length !== 10){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "ca"){
        console.log("newPhoneNumberDetails.phone.length gb",newPhoneNumberDetails.phone.length)
        if(newPhoneNumberDetails.phone.length !== 10){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "ae"){
        console.log("newPhoneNumberDetails.phone.length gb",newPhoneNumberDetails.phone.length)
        if(newPhoneNumberDetails.phone.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "sg"){
        console.log("newPhoneNumberDetails.phone.length gb",newPhoneNumberDetails.phone.length)
        if(newPhoneNumberDetails.phone.length !== 8){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "np"){
        console.log("newPhoneNumberDetails.phone.length gb",newPhoneNumberDetails.phone.length)
        if(newPhoneNumberDetails.phone.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "jp"){
        // console.log("clientDetails.phone.length gb",newPhoneNumberDetails.phone.length)
        if(newPhoneNumberDetails.phone.length !== 10){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "de"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(newPhoneNumberDetails.phone.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "bt"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(newPhoneNumberDetails.phone.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCodeClients == "au"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(newPhoneNumberDetails.phone.length !== 13){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
    }
    if(!newPhoneNumberDetails.phone_type)  {
      phoneTypeErr = 'Please Select Phone Type';
    }
    

    if(newPhoneNumberDetails.is_active == "" &&  newPhoneNumberDetails.is_active !== false  && newPhoneNumberDetails.is_active !== true ){
        isActiveErr = 'Please Select Is Active';
    }

    if(phoneNumberErr || isActiveErr || phoneTypeErr ){
      setphoneNumberErr(phoneNumberErr);
      setisActiveErr(isActiveErr);
      setphoneTypeErr(phoneTypeErr);
      return false;
    }
    return true;
  }

  const handelAddContactSubmit = () => {
    console.log("Add Contact Clicked",newPhoneNumberDetails);
    newPhoneNumberDetails.business_id = business_id;

    let updated_by_here = '';
    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
    console.log("Role of user",role_of_user)
    if( role_of_user === "client"){
      updated_by_here = "self"
    }
    else{
      updated_by_here = "business"
    }

    let PhoneNumberDetailsHiere = { ...newPhoneNumberDetails, 
      'client_id':ClientDetails.client_id,
      'updated_by': updated_by_here
    }
    const isValid = validateAddNewPhoneNumber();
    if(isValid){
      setShowProcedure(true);
      setRefreshMobileNumberTablePage(false)
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(PhoneNumberDetailsHiere)
      };
      return fetch( process.env.REACT_APP_CREATE_PHONE_NUMBER , requestOptions)
        .then((Response) => Response.json())
        .then(phoneAdded => {
          setRefreshMobileNumberTablePage(true)
          setShowProcedure(false);
          console.log("phoneAdded",phoneAdded.success);
          console.log("phoneAdded",phoneAdded);
          if(phoneAdded.success == true){
            Swal.fire({
              icon: 'success',
              text: "Phone Number Added Succesfully",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setopenAddNewContact(false);
            setnewPhoneNumberDetails({
              phone: "",
              phone_type: "",
              country_code: "",
              is_active: "",
              device_token: JSON.parse(localStorage.getItem('device_token')),
              session_id: JSON.parse(localStorage.getItem('session_id')), 
            });
            setphoneNumberErr("");
            setisActiveErr("");
            setphoneTypeErr("");
          }
          if(phoneAdded.success == false ){
            setopenAddNewContact(true);
            Swal.fire({
              icon: 'error',
              text: phoneAdded.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              // timer: 5000,
            })
          } 
          setFlag(false);            
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  const phoneNumberChangeClients = (clientNumber,e,formattedValue,value) => {
    console.log("phoneNumberChange clientNumber",clientNumber)
    console.log("phoneNumberChange e",e)
    console.log("phoneNumberChange value",value)
    let countryCodeIs = "+" + e.dialCode
    let splitNoIs = ""
    if(e.countryCode == "in"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "us"){
      // splitNoIs = value.split(" ")[1];
      // splitNoIs = value.substr(value.indexOf(' ')+1);

      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "gb"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ca"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ae"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "sg"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "np"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "jp"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "de"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "bt"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "au"){
      splitNoIs = value.split(" ")[1];
    }
    console.log("splitNoIs",splitNoIs)
    let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
    console.log("noHereIs",noHereIs)
    setCountryCodeClients(e.countryCode)
    // setnewPhoneNumberDetails({...newPhoneNumberDetails, phone:noHereIs })
    setnewPhoneNumberDetails({...newPhoneNumberDetails, phone: splitNoIs, country_code: countryCodeIs })
  }

  const bodyAddNewContact = (
    <div className={classes.paperAddNewNumber}>
    <div className={classes.addNewBusinessHeaderBackgroud}> 
      <h2>Add New Contact</h2>
    </div>
    <div className={classes.modalBodyNewNumber}>
      {/* <TextField
        margin="normal"
        required
        fullWidth
        id="phone"
        label="Phone Number"
        name="phone"
        autoComplete="phone"
        value={newPhoneNumberDetails.phone}
        onChange={(e) => setnewPhoneNumberDetails({...newPhoneNumberDetails, phone:e.target.value }) }
      />
      <div className={classes.validation}>{(newPhoneNumberDetails.phone)?(<div></div>):(phoneNumberErr)}</div> */}

      {/* <InputLabel id="is_active">Phone Number*</InputLabel> */}
      <Grid item xs={12} className={classes.subHeadingLabel}>
        Phone Number
      </Grid>
      <Grid item xs={12}>
      <PhoneInput
        inputStyle={{
          width: "100%",
          height: "50px",
          fontSize: "15px",
        }}
        countryCodeEditable={false}
        onlyCountries={onlyCountryArray}
        specialLabel=""
        country={'in'}
        masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                  sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                  au: '.............'  }}
        // value={newPhoneNumberDetails.phone}
        value={newPhoneNumberDetails.country_code + newPhoneNumberDetails.phone}
        // onChange={(usersNumber) => setnewPhoneNumberDetails({...newPhoneNumberDetails, phone:usersNumber })}
        onChange={(usersNumber,e,formattedValue,value) => phoneNumberChangeClients(usersNumber,e,formattedValue,value)}
      />
      <div className={classes.validation}>{phoneNumberErr}</div>
      </Grid>

      <FormControl className={classes.formControl}>
        <InputLabel id="phone_type">Phone Type*</InputLabel>
        <Select
          labelId="phone_type"
          id="phone_type"
          value={newPhoneNumberDetails.phone_type}
          onChange={(e) => setnewPhoneNumberDetails({...newPhoneNumberDetails, phone_type:e.target.value }) }
        >
            <MenuItem value="primary">Primary</MenuItem>
            <MenuItem value="secondary">Secondary</MenuItem>
            <MenuItem value="emergency">Emergency</MenuItem>
            <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
      <div className={classes.validation}>{(newPhoneNumberDetails.is_active)?(<div></div>):(phoneTypeErr)}</div>

      <FormControl className={classes.formControl}>
        <InputLabel id="is_active">Is Active*</InputLabel>
        <Grid container item xs={12} style={{marginTop:"8%"}}>
          <RadioGroup
            row
            id ="is_active"
            aria-label="is_active"
            name="is_active"
            defaultValue={JSON.stringify(newPhoneNumberDetails.is_active)}
            onChange={ (e) => setnewPhoneNumberDetails({...newPhoneNumberDetails, is_active: JSON.parse(e.target.value) })}
          >                  
            <div>
              <FormControlLabel
                value="true"
                classes={{ label: classes.label }}
                control={<Radio size="small" color="primary" />}
                label= "Yes"
              />
            </div>
            <div>
              <FormControlLabel
                value="false"
                classes={{ label: classes.label }}
                control={<Radio size="small" color="primary" />}
                label= "No"
              />
            </div>
          </RadioGroup>
        </Grid>
        {/* <Select
          labelId="is_active"
          id="is_active"
          value={newPhoneNumberDetails.is_active}
          onChange={(e) => setnewPhoneNumberDetails({...newPhoneNumberDetails, is_active:e.target.value }) }
        >
          {yesNo.map(item =>
            <MenuItem value={item.value}>{item.name}</MenuItem>
          )}
        </Select> */}
      </FormControl>
      <div className={classes.validation}>{(newPhoneNumberDetails.is_active == "" &&  newPhoneNumberDetails.is_active !== false  && newPhoneNumberDetails.is_active !== true)?(isActiveErr):(<div></div>)}</div>

      <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handelAddContactSubmit}
        >
          Add Contact
        </Button>
        <div style={{marginLeft:'2%'}}></div>
        <Button
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handelAddNewContactClose}
        >
          Cancel
        </Button>
      </div>
    </div>
    </div>
  )





  const handleViewActiveCases = async() => {
    console.log( " in handle view active cases ")
    setRedirectToClientCases(true)
    await localStorage.setItem('view_clients_active_cases', JSON.stringify(ClientDetails));
  }



  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

  if(RedirectToAddAnotherClient == true){
    return <Redirect to={`/AddNewContactForClient`} />
  }
  if(RedirectToClientCases == true){
    return <Redirect to={`/cases`} />
  }
  else{
  return (
    // console.log("ClientGroupDetails=======",ClientGroupDetails),
    // console.log("ClientOtherContacts=======",ClientOtherContacts),
    // console.log("selected_Client_Showin_Ui",selected_Client_Showin_Ui),
    // console.log("OverallGroupDetails=",OverallGroupDetails.lenght,OverallGroupDetails),
    // console.log("AllGroups in return",AllGroups, AllGroups.length),
    // console.log("ClientDetails=",ClientDetails ),
    // console.log("ClientOtherContactDetails",ClientOtherContactDetails),
    // console.log("role_of_user in client details",role_of_user),
    <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </modal>: null}
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <div className={classes.wrapper}>
          <Grid item xs={12}container>
          <Grid >
                {
                  (navigatedFrom && navigatedFrom == "SerachClient")?
                  (
                    <Button href="/searchresult" className={classes.backButton}>
                      <span className={classes.backArrow}>
                        <ArrowBackIcon fontSize="small" />
                      </span>
                      <FormattedMessage id="backLabel" />
                    </Button>
                  ):
                  (
                    ( navigatedFrom && navigatedFrom == "TaxManagemantClient" )? 
                    (
                      <Button href="/client" className={classes.backButton}>
                        <span className={classes.backArrow}>
                          <ArrowBackIcon fontSize="small" />
                        </span>
                        <FormattedMessage id="backLabel" />
                      </Button>
                    ):
                    (
                      <div></div>
                    )
                  )
                } 
                {
                  ( navigatedFrom && navigatedFrom == "TaxManagemantCase" )?
                  (
                    <Button href="/cases" className={classes.backButton}>
                      <span className={classes.backArrow}>
                        <ArrowBackIcon fontSize="small" />
                      </span>
                      <FormattedMessage id="backLabel" />
                    </Button>
                  ):
                  (
                    ( navigatedFrom && navigatedFrom == "TaxManagemantCaseDetails" )? 
                    (
                      <Button href="/TaxManagementCaseDetails" className={classes.backButton}>
                        <span className={classes.backArrow}>
                          <ArrowBackIcon fontSize="small" />
                        </span>
                        <FormattedMessage id="backLabel" />
                      </Button>
                    ):
                    (
                      <div></div>
                    )
                  )
                }
                {
                  ( navigatedFrom && navigatedFrom == "TaxManagemantClientGroup" )?
                  (
                    <Button href="/TaxManagementClientGroupDetails" className={classes.backButton}>
                      <span className={classes.backArrow}>
                        <ArrowBackIcon fontSize="small" />
                      </span>
                      <FormattedMessage id="backLabel" />
                    </Button>
                  ):
                  (
                    
                      <div></div>
                    
                  )
                }
                         
              </Grid>
          </Grid>
          <Grid container className={classes.pageHeaderContainer} style={{marginTop:'0.5%'}}>
            <Grid item xs={12} className={classes.pageHeaderLabel}>
                Client Details
            </Grid>
          </Grid>
          {/* <Grid container className={classes.linkBackToCase}>
            <Grid item xs={12}>
              <Link to="/searchresult" className={classes.linkNoUnderline}>
                Navigate to search
              </Link>
            </Grid>
          </Grid> */}
          {/* <hr /> */}
          {/* <Typography className={classes.instructions}> */}
          <Paper className={classes.paper} style={{marginTop:'-4%'}}>
            <div>
            {/* {
              ( ClientDetails.lenght == 0)?
              (
                <Grid>ClientDetails.lenght == 0</Grid>
              ):
              (
                <Grid>ClientDetails.lenght is not 0</Grid>
              )
            } */}
            </div>
            <Grid>
                

                {/* {
                  ( role_of_user == "client" )?
                  ( */}
                    
                    {/* View Active Cases */}
                    <Grid item xs={12} >
                      {/* <Grid item xs={6} className={classes.pageHeaderLabel}></Grid> */}
                      <Grid container justify="flex-end"  
                      >
                        <Grid className={classes.linkBackToCase} 
                        onClick={() => handleViewActiveCases()}
                        style={{ cursor:'pointer'}}>
                          View Active Cases
                        </Grid>
                        
                      </Grid>
                    </Grid>
                  {/* ):
                  (<Grid></Grid>)
                } */}
                
                <Modal
                  open={open}
                  onClose={handleAssignGroupClose}
                  className={classes.modal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {body}
                </Modal>

                <Grid item xs={12} container style={{marginLeft:'2%'}}>
                  <Grid container>
                      <Grid style={{marginTop:'-0.3%'}}>
                        <PersonIcon />
                      </Grid>
                      <Grid className={classes.headingTitle} style={{marginLeft:'1%'}}>
                        {/* Name :  */}
                        {
                        ( ClientDetails && ClientDetails.client_type === "person" )?
                        (
                          <>
                            Client Name :
                          </>
                        ):
                        (
                          <>
                            Organization Name :
                          </>
                        )
                      }
                    </Grid>
                    <Grid className={classes.subheadingTitle} style={{marginLeft:'2%'}}>
                      {/* {ClientDetails && ClientDetails.name} */}
                      
                      {
                        ( ClientDetails && ClientDetails.client_type === "person" )?
                        (
                          <>
                            {ClientDetails && ClientDetails.first_name + " " + ClientDetails.middle_name + " " + ClientDetails.surname }
                          </>
                        ):
                        (
                          <>
                            {ClientDetails && ClientDetails.org_name}
                          </>
                        )
                      }
                    </Grid>
                  </Grid>

                  <Grid container style={{marginTop:'1%'}}>
                    <Grid style={{marginTop:'-0.3%'}}>
                      <EmailIcon/>
                    </Grid>
                    <Grid className={classes.headingTitle} style={{marginLeft:'1%'}}>
                      EmailID : 
                    </Grid>
                    <Grid className={classes.subheadingTitle} style={{marginLeft:'2%'}}>
                      {ClientDetails && ClientDetails.email}
                    </Grid>
                  </Grid>

                  <Grid container style={{marginTop:'1%'}}>
                    <Grid style={{marginTop:'-0.3%'}}>
                      <BadgeOutlinedIcon />
                    </Grid>
                    <Grid className={classes.headingTitle} style={{marginLeft:'1%'}}>
                      PAN Number : 
                    </Grid>
                    <Grid className={classes.subheadingTitle} style={{marginLeft:'2%'}}>
                      {ClientDetails && ClientDetails.pan}
                    </Grid>
                  </Grid>

                  <Grid container style={{marginTop:'1%'}}>
                    <Grid style={{marginTop:'-0.3%'}}>
                      <AssignmentIndIcon />
                    </Grid>
                    <Grid className={classes.headingTitle} style={{marginLeft:'1%'}}>
                      Client Status : 
                    </Grid>
                    <Grid className={classes.subheadingTitle} style={{marginLeft:'2%'}}>
                      {ClientDetails && ClientDetails.client_state}
                    </Grid>
                  </Grid> 

                  <Grid container item xs={12} style={{marginTop:'2%'}}>
                    <Grid style={{marginTop:'-0.3%'}}>
                      <PhoneIcon />
                    </Grid>
                    <Grid item xs={8} className={classes.headingTitle} style={{marginLeft:'1%'}}>
                    Contact Number : 
                    </Grid>
                      <Grid item xs={3} style={{ cursor:'pointer'}}>
                        <Grid item xs={12} className={classes.linkBackToCase} 
                          onClick={() => handelAddNewContactOpen()}
                          style={{ cursor:'pointer'}}
                        >
                          + Add New Number
                        </Grid>
                      </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      ( RefreshMobileNumberTablePage == true )?
                      (
                        <ClientMobileNumbers />
                      )
                      :(<Grid></Grid>)
                    }
                  </Grid>

                  {/* <Grid item xs={12} container style={{marginTop:'1%'}}>
                    <Grid style={{marginTop:'-0.3%'}}>
                      <HomeIcon />
                    </Grid>
                    <Grid className={classes.headingTitle} style={{marginLeft:'1%'}}>
                      Address : 
                    </Grid>
                    <Grid style={{marginLeft:'2%', textAlign:'left'}}>
                      {ClientAddress && ClientAddress.map((e , i)=>(
                        <Grid className={classes.subheadingTitle}> {i + 1 }. &nbsp;{e.address}</Grid>
                      ))}

                      <Grid container style={{marginTop:'1%', cursor:'pointer'}} 
                        onClick={() => handleAddAddressOpen()}
                      > */}
                        {/* <Grid style={{marginTop:'-0.3%'}}>
                          +
                        </Grid> */}
                        {/* <Grid className={classes.AddAddress} style={{marginLeft:'1%'}}>
                          + Add Address
                        </Grid>
                      </Grid>

                    </Grid>

                  </Grid> */}

                  {/* Address */}
                  <Grid container item xs={12} style={{marginTop:'2%'}}>
                    <Grid style={{marginTop:'-0.3%'}}>
                      <HomeIcon />
                    </Grid>
                    <Grid item xs={8} className={classes.headingTitle} style={{marginLeft:'1%'}}>
                      Address : 
                    </Grid>
                    {/* {user_permissions && user_permissions.permissions.add_clients_to_groups && */}
                      <Grid item xs={3} style={{ cursor:'pointer'}}>
                        {/* <Grid item xs={6} className={classes.pageHeaderLabel}></Grid> */}
                        <Grid item xs={12} className={classes.linkBackToCase} 
                          onClick={() => handleAddAddressOpen()}
                          style={{ cursor:'pointer'}}
                        >
                          + Add Address
                        </Grid>
                      </Grid>
                    {/* } */}
                  </Grid>

                  <Grid item xs={12}>
                    {
                      ( RefreshAddressTablePage == true )?
                      (
                        <ClientAddresses />
                      )
                      :(<Grid></Grid>)
                    }
                  </Grid>
                  
                  

                  {/* <Grid container style={{marginTop:'1%'}}>
                    <Grid style={{marginTop:'-0.3%'}}>
                      <WorkIcon />
                    </Grid>
                    <Grid className={classes.headingTitle} style={{marginLeft:'1%'}}>
                      Recent Cases : 
                    </Grid>
                    <Grid style={{marginLeft:'2%', textAlign:'left'}}>
                      {ClientRecentCases && ClientRecentCases.map((e , i)=>(
                        <Grid className={classes.subheadingTitle}> {i + 1 }. &nbsp;{e.name}</Grid>
                      ))}
                    </Grid>
                  </Grid> */}

                  {/* ID PROOFS */}
                  <Grid container item xs={12} style={{marginTop:'2%'}}>
                    <Grid style={{marginTop:'-0.3%'}}>
                      <GroupIcon />
                    </Grid>
                    <Grid item xs={8} className={classes.headingTitle} style={{marginLeft:'1%'}}>
                      Uploaded ID Proofs : 
                    </Grid>
                    {/* {user_permissions && user_permissions.permissions.add_clients_to_groups && */}
                      <Grid item xs={3} style={{ cursor:'pointer'}}>
                        {/* <Grid item xs={6} className={classes.pageHeaderLabel}></Grid> */}
                        <Grid item xs={12} className={classes.linkBackToCase} 
                          onClick={() => handleUploadIdProofsOpen()}
                          style={{ cursor:'pointer'}}
                        >
                          + Upload Id Proof
                        </Grid>
                      </Grid>
                    {/* } */}
                  </Grid>
                  <Grid item xs={12}>
                    {
                      ( RefreshIdProofTablePage == true )?
                      (
                        <ClientsUploadedIdProofs />
                      )
                      :(<Grid></Grid>)
                    }
                  </Grid>

                  {/* {
                    ( ClientDetails && ClientDetails.client_state === "REGISTERED")?
                    ( */}
                      <Grid container item xs={12} style={{marginTop:'2%'}}>
                        <Grid container item xs={12} style={{marginTop:'2%'}}>
                          <Grid style={{marginTop:'-0.3%'}}>
                            <GroupIcon />
                          </Grid>
                          <Grid item xs={8} className={classes.headingTitle} style={{marginLeft:'1%'}}>
                            Assigned Group Details : 
                          </Grid>
                          {user_permissions && user_permissions.permissions.add_clients_to_groups &&
                            <Grid item xs={3} style={{ cursor:'pointer'}}>
                              {/* <Grid item xs={6} className={classes.pageHeaderLabel}></Grid> */}
                              <Grid item xs={12} className={classes.linkBackToCase} 
                                onClick={() => handleAssignGroupOpen()}
                                style={{ cursor:'pointer'}}
                              >
                                + Assign Groups
                              </Grid>
                            </Grid>
                          }
                        </Grid>
                        <Grid item xs={12}>
                          {
                            ( RefreshGrpTablePage == true )?
                            (
                              <ClientAssignedGroupsTable />
                            )
                            :(<Grid></Grid>)
                          }
                        </Grid>
                       </Grid>
                    {/* ):
                    (
                      <></>
                    )
                  } */}
                    
                  {/* GROUP DETAILS */}
                  

                  {/* <Grid item xs={12} style={{marginLeft:'2%', textAlign:'left'}}>
                      {
                        ClientGroupDetails && ClientGroupDetails.map((item, index)=>(
                          console.log("ClientGroupDetails item",item),
                          // <Grid className={classes.subheadingTitle}>{index + 1}. &nbsp;{item.group_name}</Grid>
                          <Grid>
                            <Grid container style={{marginTop:'2%'}}>
                              <Grid className={classes.headingTitle}>
                                { index + 1}. &nbsp; Group Name : 
                              </Grid>
                              <Grid className={classes.subheadingTitle} >
                                &nbsp; {item.group_name}
                              </Grid> 
                            </Grid>
                            <Grid container style={{marginTop:'1%'}}>
                              <Grid className={classes.headingTitle} style={{marginLeft:'2%'}}>
                                &nbsp; Group Shortcode : 
                              </Grid>
                              <Grid className={classes.subheadingTitle}>
                                &nbsp; {item.group_shortcode}
                              </Grid> 
                            </Grid>
                          </Grid>
                        ))
                      }
                    </Grid> */}

                  {/* <Grid container style={{marginTop:'2%'}}>
                    <Grid className={classes.headingTitle}>
                      Group Details From details: 
                    </Grid>
                    <Grid style={{marginLeft:'2%', textAlign:'left'}}>
                      {
                        ClientDetails && ClientDetails.groups.map((item, index)=>(
                          <Grid className={classes.subheadingTitle}>{index + 1}. &nbsp;{item.group_name}</Grid>
                        ))
                      }
                    </Grid>
                  </Grid> */}

                  {/* OTHER CONTACT */}
                  <Grid container item xs={12} style={{marginTop:'3%'}}>
                    <Grid style={{marginTop:'-0.3%'}}>
                      <RecentActorsIcon />
                    </Grid>
                    <Grid item xs={8} className={classes.headingTitle} style={{marginLeft:'1%'}}>
                      Other Contacts : 
                    </Grid>
                    {user_permissions && user_permissions.permissions.add_contacts &&
                      <Grid item xs={3} style={{ cursor:'pointer'}}>
                        {/* <Grid item xs={6} className={classes.pageHeaderLabel}></Grid> */}
                        <Grid item xs={12} className={classes.linkBackToCase} 
                          onClick={() => handleAddAnotherContactClick()}
                          style={{ cursor:'pointer'}}
                        >
                          + Add Another Contact
                        </Grid>
                      </Grid>
                    }

                  </Grid>

                  <Grid item xs={12}>
                  <TableContainer style={{width:'95%'}} component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                          <EnhancedTableHead
                            // classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={ClientOtherContacts && ClientOtherContacts.length}
                          />
                          <TableBody>
                              {stableSort(ClientOtherContacts && ClientOtherContacts, getComparator(order, orderBy))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((item, index) => {
                                // console.log("row in table map",item)
                                  return (
                                  <TableRow 
                                      key={item.state_id}
                                      hover={true} classes={{hover: classes.hover}}
                                      // hover
                                      tabIndex={-1}
                                      align="center"
                                  >
                                      {/* <TableCell  width="10%" align="center">{item.group_id}</TableCell> */}
                                      <TableCell  width="5%" align="center">{index + 1}</TableCell>
                                      <TableCell  width="10%" align="center">{item.name}</TableCell>
                                      <TableCell  width="10%" align="center">{item.email}</TableCell>
                                      <TableCell  width="10%" align="center">{item.pan}</TableCell>
                                      {/* <TableCell  width="10%" align="center">{item.phone}</TableCell> */}
                                      <TableCell  width="10%" align="center">{item.country_code + '-' + item.phone}</TableCell>
                                      { user_permissions && user_permissions.permissions.update_contact_details &&
                                        <TableCell width="10%" align="center">
                                          <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => handleEditContact(item)}
                                          >
                                            Edit
                                          </Button> 
                                        </TableCell> 
                                      }
                                      
                                  </TableRow>
                                  );
                              })}
                              </TableBody>
                              <TableFooter>
                                  <TableRow>
                                  <TablePagination
                                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: ClientOtherContacts && ClientOtherContacts.length }]}
                                      colSpan={8}
                                      count={ClientOtherContacts && ClientOtherContacts.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      SelectProps={{
                                          inputProps: { 'aria-label': 'rows per page' },
                                          native: true,
                                      }}
                                      onChangePage={handleChangePage}
                                      onChangeRowsPerPage={handleChangeRowsPerPage}
                                      ActionsComponent={TablePaginationActions}
                                  />
                                  </TableRow>
                              </TableFooter>
                      </Table>
                    </TableContainer>
                    {/* {
                      ClientOtherContacts && ClientOtherContacts.map((e, i)=> (
                        <Grid style={{marginLeft:'2%', textAlign:'left', marginTop:'2%'}}>
                          <Grid container>
                            <Grid className={classes.headingTitle}>
                              { i + 1}. &nbsp; Name : 
                            </Grid>
                            <Grid className={classes.subheadingTitle}>
                              &nbsp; {e.name}
                            </Grid> 
                          </Grid>

                          <Grid container style={{marginLeft: '2%', marginTop:'1%'}}>
                            <Grid className={classes.headingTitle}>
                              Email ID : 
                            </Grid>
                            <Grid className={classes.subheadingTitle}>
                              &nbsp; {e.email}
                            </Grid> 
                          </Grid>

                          <Grid container style={{marginLeft: '2%', marginTop:'1%'}}>
                            <Grid className={classes.headingTitle}>
                              PAN Number : 
                            </Grid>
                            <Grid className={classes.subheadingTitle}>
                              &nbsp; {e.pan}
                            </Grid> 
                          </Grid>

                          <Grid container style={{marginLeft: '2%', marginTop:'1%'}}>
                            <Grid className={classes.headingTitle}>
                              Mobile Number : 
                            </Grid>
                            <Grid className={classes.subheadingTitle}>
                              &nbsp; {e.phone}
                            </Grid> 
                          </Grid>
                        </Grid>
                    ))} */}
                  </Grid>
                </Grid>
              </Grid>
          </Paper>
          {/* </Typography> */}
        </div>
      </Slide>
      <Modal
        open={openEditContact}
        onClose={handleEditContactDetailsClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyEditContact}
      </Modal>

        <Modal
          open={OpenAddAddress}
          onClose={handleAddAddressClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyAddAddress}
        </Modal>

        <Modal
          open={OpenUploadIdProofs}
          onClose={handleUploadIdProofsClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyUploadIdProofs}
        </Modal>

        <Modal
          open={openAddNewContact}
          onClose={handelAddNewContactClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyAddNewContact}
        </Modal>

    </div>
  );
  }

}

export default injectIntl(TaxManagementClientDetailsUI);

import React, { useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector , useDispatch} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { userActions } from "../actions";
import Paper from "@material-ui/core/Paper";


import TermsAndConditionSystemAdmin from "./TermsAndConditionSystemAdmin";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    wrapper: {
        margin: "10%",
        marginRight: "5%",
        marginTop: "7%",
        marginBottom: "3%",
    },

}));

export default function TermsAndConditions() {
    const classes = useStyles();

    return (
        // console.log("AllTermsAndConditions in new page", AllTermsAndConditions),
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <TermsAndConditionSystemAdmin />
            </div>
        </div>
    );
}
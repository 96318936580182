import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Avatar from '@material-ui/core/Avatar';
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { useSelector , useDispatch} from 'react-redux';
// import useCheckSessionApi from "../SessionValidity";

import { userActions } from "../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  topPosition: {
    top: "5px !important"
  },
  noUnderlineStyle:{
    textDecoration: "none"
  }
}));

export default function ProfilePicture({users}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [flag, setflag] = React.useState(false);
  const [RedirectProfile, setRedirectProfile] = React.useState(false);
  const [ProfilePictureToShow, setProfilePictureToShow] = React.useState("");
  // const [role_of_user, setrole_of_user] = React.useState(JSON.parse(localStorage.getItem('role_of_user')));

    useEffect(() => {
      setflag(true);
      dispatch(userActions.checkSessionValidity());
      // let users = JSON.parse(localStorage.getItem('users'));
      // console.log("users details in users profile", users.data.user_details)
      // if(users.data.user_details.profile_picture){
      //   setProfilePictureToShow(users.data.user_details.profile_picture.url);
      // }

      let profile_picture_here = JSON.parse(localStorage.getItem('profile_picture_local'));
      // console.log("profile_picture_local details in header", profile_picture_here, profile_picture_here.url)
      if(profile_picture_here){
        setProfilePictureToShow(profile_picture_here);
      }
      else{
        setProfilePictureToShow("")
      }

    }, [flag]);
    

  const dispatch = useDispatch()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handelLogout = () => {
    console.log("in logout")
    dispatch(userActions.logout());
  }

  const handleProfileClick = () => {
    setRedirectProfile(true);
  }
  const handelSwitchBusinessLogout = () => {
    console.log("handelSwitchBusinessLogout is clicked")
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  // console.log("users in profile pictureis",users );

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

  // if( role_of_user == "client"){
  //   return(
  //     <div className={classes.root}>
  //     <Button
  //         ref={anchorRef}
  //         aria-controls={open ? 'menu-list-grow' : undefined}
  //         aria-haspopup="true"
  //         onClick={handleToggle}
  //       >
  //     {/* <Avatar alt={users.items ? users.items.user.name : ''} src="/static/images/avatar/1.jpg" /> */}
  //     <Avatar alt={users.items ? users.items.data.user_details.name : ''} src="/static/images/avatar/1.jpg" />
  //     </Button>
  //       <Popper open={open} anchorEl={anchorRef.current} transition disablePortal className={classes.topPosition}>
  //         {({ TransitionProps, placement }) => (
  //           <Grow
  //             {...TransitionProps}
  //             style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
  //           >
  //             <Paper>
  //               <ClickAwayListener onClickAway={handleClose}>
  //                 <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
  //                   <MenuItem onClick={handleClose}><Link className={classes.noUnderlineStyle} to="/UsersProfile"><FormattedMessage id="profileLabel" /></Link></MenuItem>

  //                   <MenuItem onClick={handleClose}><FormattedMessage id="myAccountLabel" /></MenuItem>
  //                   {/* <MenuItem onClick={handleClose}><Link className={classes.noUnderlineStyle} to="/login"><FormattedMessage id="logoutLabel" /></Link></MenuItem> */}
  //                   <MenuItem onClick={handelLogout}><FormattedMessage id="logoutLabel" /></MenuItem>
  //                   <MenuItem onClick={handelSwitchBusinessLogout}>Switch Business</MenuItem>
  //                 </MenuList>
  //               </ClickAwayListener>
  //             </Paper>
  //           </Grow>
  //         )}
  //       </Popper>
  //   </div>
  //   );
  // }

  // else {

  if(RedirectProfile == true){
    return <Redirect to={`/UsersProfile`} />
  }
  else{
  return (
    // console.log("ProfilePictureToShow in return users.items.data.user_details.first_name", ProfilePictureToShow, users.items.data.user_details.first_name),
    <div className={classes.root}>
      <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
      {/* <Avatar alt={users.items ? users.items.user.name : ''} src="/static/images/avatar/1.jpg" /> */}
      
      {/* <Avatar alt={users.items ? users.items.data.user_details.first_name : ''} src="/static/images/avatar/1.jpg" /> */}

      <Avatar alt={users.items ? users.items.data.user_details.first_name : ''} src="/static/images/avatar/1.jpg" />
      {/* {
        (ProfilePictureToShow === "")?
        (
          <Avatar alt={users.items ? users.items.data.user_details.first_name : ''} />
        ):
        (
          <Avatar src={ProfilePictureToShow} />
        )
      } */}
      
      </Button>
        <Popper open={open} anchorEl={anchorRef.current} transition disablePortal className={classes.topPosition}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {
                      ( role_of_user === "sysadmin")?
                      (
                        <></>
                      ):
                      (
                        <Link className={classes.noUnderlineStyle} to="/UsersProfile"><MenuItem onClick={handleClose}><FormattedMessage id="profileLabel" /></MenuItem></Link>
                      )
                    }
                  
                    {/* <MenuItem onClick={handleProfileClick}><FormattedMessage id="profileLabel" /></MenuItem> */}

                    {/* <MenuItem onClick={handleClose}><FormattedMessage id="myAccountLabel" /></MenuItem> */}
                    {/* <MenuItem onClick={handleClose}><Link className={classes.noUnderlineStyle} to="/login"><FormattedMessage id="logoutLabel" /></Link></MenuItem> */}
                    <MenuItem onClick={handelLogout}><FormattedMessage id="logoutLabel" /></MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </div>
  );
}
//  }
}

import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';
import Swal from 'sweetalert2';

import { addBusinessId, addLogedInRole, addDeviceToken, addSessionId, addLogedInUsersDetails, reset} from "../actions/logindetails.actions";

import { browserName , osName , osVersion, fullBrowserVersion, browserVersion, engineName, engineVersion,
getUA, deviceType, BrowserTypes } from 'react-device-detect';

const localIpUrl = require('local-ip-url');

export const userActions = {
    login,
    logout,
    getAll,
    createSession,
    checkSessionValidity,
    refreshSessionToken,
    checkSessionValidityWithValues,
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => {
                    dispatch(success({'username':username,'token':user.key}));
                    // dispatch(userActions.createSession(localIpUrl(('public')), {browserName,osName } ));
                    history.push('/');
                    // console.log("user after login in login===",user);
                    dispatch(alertActions.error(""));

                    // let device_info = { browserName,osName }
                    // console.log(" device_info in section to pass for create session", device_info )
                    // let browser_Name = device_info.browserName
                    // let device_Os = device_info.osName
                    // console.log("device_info browser_Name, device_Os:", browser_Name, device_Os )
                    // let device_info_final = browser_Name + ',' + device_Os
                    // console.log("device_info device_info_final:", device_info_final )
                    // dispatch(userActions.createSession( localIpUrl(('public')), device_info_final ));
                },
                error => {
                    console.log("error in login is",error)
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }

    // function success(user) { return { type: userConstants.LOGINKEY_BY_NANU, user } }
}

function createSession(device_ip, device_info) {
    return dispatch => {
        dispatch(request());
        userService.createSession(device_ip, device_info)
        //  let device_token = JSON.parse(localStorage.getItem('device_token'))
        //         let session_id = JSON.parse(localStorage.getItem('session_id'))
            .then(
                sessionInfo => {
                    dispatch(success(sessionInfo));
                    if(sessionInfo !== undefined){
                        console.log("device token in createSession is",sessionInfo.data.session_info.device_token)
                        dispatch(addDeviceToken(sessionInfo.data.session_info.device_token));
                        dispatch(addSessionId(sessionInfo.data.session_info.session_id));
                        dispatch(userActions.getAll(sessionInfo.data.session_info.device_token,sessionInfo.data.session_info.session_id));
                    }
                },
                error => dispatch(failure(error)),

            );
    };
    function request() { return { type: userConstants.SESSION_REQUEST } }
    function success(sessionInfo) { return { type: userConstants.SESSION_SUCCESS, sessionInfo } }
    function failure(error) { return { type: userConstants.SESSION_FAILURE, error } }
}

function checkSessionValidity() {
    return dispatch => {
        dispatch(request());
        userService.checkSessionValidity()
            .then(
                async(checkSessionInfo) => {
                    dispatch(success(checkSessionInfo));
                    if(checkSessionInfo && checkSessionInfo.data && checkSessionInfo.data.session_info && checkSessionInfo.data.session_info.session_id_refreshed == true ){
                        console.log("in checkSessionInfo.data.session_info.is_active === true && checkSessionInfo.data.session_info.session_id_refreshed === true");
                            await localStorage.setItem('device_token', JSON.stringify(checkSessionInfo.data.session_info.device_token));
                            await localStorage.setItem('session_id', JSON.stringify(checkSessionInfo.data.session_info.session_id));
                            await dispatch(addDeviceToken(checkSessionInfo.data.session_info.device_token));
                            await dispatch(addSessionId(checkSessionInfo.data.session_info.session_id));
                    }
                    if(checkSessionInfo.data.session_info.force_login == true){
                        console.log("checkSessionInfo.data.session_info.force_login === true");
                            Swal.fire({
                            icon: 'error',
                            html:'Safely logged out due to inactivity.<br/>Please login to continue.',
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'Login',
                            timer: 5000,
                            }).then(function (result) {
                            dispatch(userActions.logout());
                            })

                    }
                },
                error => dispatch(failure(error)),

            );
    };
    function request() { return { type: userConstants.SESSION_REQUEST } }
    function success(sessionInfo) { return { type: userConstants.SESSION_SUCCESS, sessionInfo } }
    function failure(error) { return { type: userConstants.SESSION_FAILURE, error } }
}

function checkSessionValidityWithValues(device_token,session_id) {
    return dispatch => {
        dispatch(request());
        userService.checkSessionValidityWithValues(device_token,session_id)
            .then(
                async(checkSessionInfo) => {
                    dispatch(success(checkSessionInfo));
                    if(checkSessionInfo.success == true){
                        // console.log("in checkSession.success === true");
                        if(checkSessionInfo.data.session_info.is_active == true && checkSessionInfo.data.session_info.session_id_refreshed == false ){
                            console.log("as it is session==1=1=1=1= in redux");
                            // Swal.fire({
                            //     icon: 'error',
                            //     text: "Session Is Expired.Nedd to logoutppppppp",
                            //     confirmButtonColor: '#d33',
                            //     confirmButtonText: 'OK',
                            //   }).then(function (result) {
                            //   dispatch(userActions.logout());
                            //   })
                        }
                        if(checkSessionInfo.data.session_info.is_active == true && checkSessionInfo.data.session_info.session_id_refreshed == true ){
                            console.log("in checkSessionInfo.data.session_info.is_active === true && checkSessionInfo.data.session_info.session_id_refreshed === true");
                             await localStorage.setItem('device_token', JSON.stringify(checkSessionInfo.data.session_info.device_token));
                             await localStorage.setItem('session_id', JSON.stringify(checkSessionInfo.data.session_info.session_id));
                             await dispatch(addDeviceToken(checkSessionInfo.data.session_info.device_token));
                             await dispatch(addSessionId(checkSessionInfo.data.session_info.session_id));
                            }
                        else if(checkSessionInfo.data.session_info.is_active == false){
                            console.log("checkSessionInfo.data.session_info.is_active === false");
                              Swal.fire({
                                icon: 'error',
                                html:'Safely logged out due to inactivity.<br/>Please login to continue.',
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'Login',
                                timer: 5000,
                              }).then(function (result) {
                              dispatch(userActions.logout());
                              })

                        }
                    }
                    else if(checkSessionInfo.success == false){
                        console.log("checkSessionInfo checkSessionInfo.success === false",checkSessionInfo);
                        Swal.fire({
                            icon: 'error',
                            html:'Safely logged out due to inactivity.<br/>Please login to continue.',
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'Login',
                            timer: 5000,
                        }).then(function (result) {
                        console.log("handleResponse of logout options");

                        dispatch(userActions.logout());
                        })

                    }
                },
                error => dispatch(failure(error)),

            );
    };
    function request() { return { type: userConstants.SESSION_REQUEST } }
    function success(sessionInfo) { return { type: userConstants.SESSION_SUCCESS, sessionInfo } }
    function failure(error) { return { type: userConstants.SESSION_FAILURE, error } }
}

function refreshSessionToken(device_token, session_id){
    console.log("in refresh token",)
    return dispatch => {
        dispatch(request());
        userService.refreshSessionToken(device_token, session_id)
            .then(
                sessionRefresh => dispatch(success(sessionRefresh)),
                error => dispatch(failure(error))
            );
    };
    function request() { return { type: userConstants.SESSION_REQUEST } }
    function success(sessionRefresh) { return { type: userConstants.SESSION_SUCCESS, sessionRefresh } }
    function failure(error) { return { type: userConstants.SESSION_FAILURE, error } }
}

function logout() {
    return dispatch => {
        dispatch(request());
        userService.logout()
            .then(
                user => {
                    // history.push('/login');
                    history.push('/');
                    dispatch(reset());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGOUT, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getAll(device_token, session_id) {
// function getAll() {
    return dispatch => {
        dispatch(request());
        userService.getAll(device_token, session_id)
    //     let device_token = JSON.parse(localStorage.getItem('device_token'));
    // let session_id = JSON.parse(localStorage.getItem('session_id'));
        // userService.getAll()
            .then(
                async (users) => {
                    console.log("users in getall func",users.data.user_details)
                    await localStorage.setItem('user_id', JSON.stringify(users.data.user_details.user_id));
                    // await localStorage.setItem('rowsPerPageOfTableCasesTableTrpAdmin', parseInt(5));
                    dispatch(success(users));
                    if(users !== undefined){
                        dispatch(addLogedInRole(users.data.user_details.role))
                        await dispatch(addLogedInUsersDetails(users.data.user_details));
                        if(users.data.user_details.role == "business-user"){
                            if(users.data.user_details.business !== null){
                                dispatch(addBusinessId(users.data.user_details.business.business_id));
                            }
                        }

                        if(users.data.user_details.role == "client"){
                            if(users.data.user_details.business_link !== null){
                                dispatch(addBusinessId(users.data.user_details.business_link.business_id));
                            }
                            // else if(users.data.user_details.business_link === null){
                            //     // dispatch(addBusinessId(users.data.user_details.business_link.business_id));
                            //     alert("There is no active business link to your account, Contact your business to get a business activation code")
                            //     // dispatch(failure("There is no active business link to your account, Contact your business to get a business activation code"))
                            //     logout();
                            // }
                        }

                    }
                },
                // error => dispatch(failure(error)),
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
                // users =>  dispatch(addLogedInRole(users))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

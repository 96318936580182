import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { userActions } from "../actions";
import { useThemeContext, useThemeUpdate } from '../ThemeContext'

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

import { Chart } from "react-google-charts";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import moment from 'moment';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(UsersLoggeInDetails, comparator) {
    const stabilizedThis = UsersLoggeInDetails && UsersLoggeInDetails.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const headCells = [
    { id: 'arrow', numeric: false, label: '', },
    { id: 'name', numeric: false, label: 'User Name', },
    { id: 'active_sessions', numeric: false, label: 'Last Login Time', },
    { id: 'employee_loggedin', numeric: false, label: 'Current Login Status', },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    (headCell.id === "actions") ?
                        (
                            <TableCell
                                key={headCell.id}
                                align="center"
                                style={{ fontWeight: 'bold', fontSize: 17, width: "1%" }}
                            >
                                {headCell.label}
                            </TableCell>
                        )
                        : (
                            <TableCell
                                key={headCell.id}
                                // align={headCell.numeric ? 'center' : 'center'}
                                padding={headCell.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === headCell.id ? order : false}
                                align="center"
                                style={{ fontWeight: 'bold', fontSize: 17,}}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )


                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    }
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: '5%',
        // overflowY: 'scroll',
    },
    root2: {
        flexGrow: 1,
        marginTop: '50%',
    },
    wrapper: {
        margin: "10%",
        marginRight: "5%",
        marginTop: "7%",
        marginBottom: "3%",
    },
    quickAccessLabel: {
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "20px",
        color: "rgba(0, 0, 0, 0.64)",
        textAlign: "left",
    },
    recentCaseLabelContainer: {
        marginTop: "5%",
        marginBottom: "1%",
    },
    pageHeaderLabel: {
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "20px",
        color: "rgba(0, 0, 0, 0.64)",
        textAlign: "left",
    },
    pageHeaderContainer: {
        marginBottom: "5%",
    },
    casesdisplay: {
        display: "block",
    },
    casesdisplayNone: {
        display: "none",
    },
    pageHeaderLabelClient: {
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "25px",
        lineHeight: "20px",
        color: "rgba(0, 0, 0, 0.64)",
        textAlign: "left",
        marginTop: "2%",
    },
    paperWrapper: {
        margin: "10%",
        marginRight: "10%",
        marginTop: "8%",
        marginBottom: "3%",
    },
    paper: {
        padding: theme.spacing(2),
        height: '100%',
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    businessHeading: {
        // display: 'flex',
        // justifyContent: 'center',
        // marginTop: '2%',
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "23px",
        color: 'black'
    },
    activeBusinessLabel: {
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "20px",
        color: "black",
        // textAlign: "left",
    },
    IconColor: {
        color: 'blue',
        size: '10'
    },
    showProcedureStyle: {
        position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function UsersLoginInfo(props) {
    const darkTheme = useThemeContext()
    const classes = useStyles();
    const [ShowProcedure, setShowProcedure] = React.useState(true);
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));

    const [UsersLoggeInDetails, setUsersLoggeInDetails] = React.useState(
        [
            // {
            //     user_name: "sanyukta",
            //     last_login: "10am"
            // },
            // {
            //     user_name: "vibha",
            //     last_login: "11am"
            // },
            // {
            //     user_name: "nanu",
            //     last_login: "12am"
            // }
        ]
    )
    const [BusinessTeamStatsTotalEmployee, setBusinessTeamStatsTotalEmployee] = React.useState()
    const [BusinessTeamStatsLoggedInEmployee, setBusinessTeamStatsLoggedInEmployee] = React.useState()

    const data = [
        ["Task", "Hours per Day"],
        ["Work", 11],
        ["Eat", 2],
        ["Commute", 2],
        ["Watch TV", 2],
        ["Sleep", 7],
    ];
    const [AllEmployeeLoggedInDetails, setAllEmployeeLoggedInDetails] = React.useState([])


    const dispatch = useDispatch();
    const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
    const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');


    React.useEffect(() => {
        dispatch(userActions.checkSessionValidity());
        setShowProcedure(true)
        const requestOptionsUsers = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'business_id': business_id,
            //   'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux 
            'device_token': device_token, 'session_id': session_id                      //after create_session change 
            })
          };
          fetch( process.env.REACT_APP_GET_BUSINESS_TEAM , requestOptionsUsers)
            .then((Response) => Response.json())
            .then( async(allUsersDetails) => {
              // setShowProcedure(false);
              console.log("allUsersDetails",allUsersDetails);
              if(allUsersDetails.success == true)
              {

                let sorteedArray = allUsersDetails.data.business_team.sort((a, b) => a.employee_loggedin < b.employee_loggedin ? 1 : -1);
                // console.log("sorteedArray",sorteedArray);
                // setUsersLoggeInDetails(allUsersDetails.data.business_team);
                setUsersLoggeInDetails(sorteedArray);
                setBusinessTeamStatsTotalEmployee(allUsersDetails.data.business_team_stats.total_emp)
                setBusinessTeamStatsLoggedInEmployee(allUsersDetails.data.business_team_stats.total_logged_in_emp)

                let lable = ["Active Users", "Inactive Users"]
                let totalEmployee  = ["Inactive Users", allUsersDetails.data.business_team_stats.total_emp];
                let totalLoggedInEmployee  = ["Active Users", allUsersDetails.data.business_team_stats.total_logged_in_emp];
                console.log("totalEmployee,totalLoggedInEmployee",totalEmployee,totalLoggedInEmployee)
                let finalArrayOfEmployeeLogin = [lable, totalEmployee, totalLoggedInEmployee ];
                console.log("finalArray",finalArrayOfEmployeeLogin)
                setAllEmployeeLoggedInDetails(finalArrayOfEmployeeLogin)
              }
              else if(allUsersDetails.success == false){
                setUsersLoggeInDetails([]);
                setAllEmployeeLoggedInDetails([])
              }
          })
        //   .catch(err => {
        //     Swal.fire({
        //       icon: 'error',
        //       // text: "Something went wrong",
        //       text: "Server Error. Please try again.",
        //       confirmButtonColor: '#d33',
        //       confirmButtonText: 'OK'
        //     })
        //   });

        setShowProcedure(false)

    }, [deviceTokenFromRedux, sessionIdFromRedux])

     // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, UsersLoggeInDetails && UsersLoggeInDetails.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createdDateFunc = (date) => {
        console.log("date in createdDateFunc is", date)
    
        let dateInString = JSON.stringify(date)
        console.log("date in straing is ", dateInString)
    
        var mydateIs = moment(dateInString, 'DD/MM/YYYY hh:mm A'); 
        console.log("formated date is ours",moment(mydateIs).format("MM/DD/YYYY hh:mm A"))
        var myDateWithFormatIs = moment(mydateIs).format("MM/DD/YYYY hh:mm A")
        console.log("myDateWithFormatIs",myDateWithFormatIs)
        console.log("date in format for  in table map is local", new Date(myDateWithFormatIs)) 
    
        var local_date= moment.utc(myDateWithFormatIs).local().format('MM/DD/YYYY hh:mm A');
        console.log("local_date using moments is",local_date)

        var local_date_DayFirst= moment.utc(myDateWithFormatIs).local().format('DD/MMM/YYYY hh:mm A');
        console.log("local_date day first",local_date_DayFirst)
    
        return(
          <div>
            {/* <div>{local_date}</div> */}
            <div>{local_date_DayFirst}</div>
          </div>  
        )
      }

    function Row(props: { row: ReturnType<typeof createData> }) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
    
        return (
            <React.Fragment>
                
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            // fontSize = "50px"
                            onClick={() => setOpen(!open)}
                        >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    </TableCell>
                    {/* <TableCell component="th" scope="row">
                        {row.user_id}
                    </TableCell> */}
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell  align="center">{
                        (row.active_sessions.length === 0 )?
                        (
                             <Grid style={{color:'grey'}}>No Data</Grid>
                        ):
                        (
                            row.active_sessions.map((itemi, indexi)=>(
                                (indexi === 0)?
                                (
                                    // itemi.logged_in_time
                                    createdDateFunc(itemi.logged_in_time)
                                ):
                                (<></>)

                            ))
                        )   
                                        
                    }
                    </TableCell>
                    <TableCell align="center">{(row.employee_loggedin === true ? 'Active' : 'Inactive')}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box >
                                <>
                                    <Table size="small" aria-label="purchases" style={{marginLeft:'1%'}}>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell width="20%"  align="center" style={{ fontWeight: 'bold' }} >Login Time</TableCell>
                                                <TableCell width="30%" align="center" style={{ fontWeight: 'bold' }}>Login Device Info</TableCell>
                                                <TableCell width="15%" align="center" style={{ fontWeight: 'bold' }}>Current Login Status</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        {row.active_sessions.map(item2 => (
                                            console.log("row for expand is in rrow.extracted_files.map", item2),
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell  align="center" >{createdDateFunc(item2.logged_in_time)}</TableCell>
                                                    <TableCell  align="center" >{item2.device_info}</TableCell>
                                                    <TableCell  align="center">{(item2.current_status === true ? 'Active' : 'Inactive')}</TableCell>
                                                </TableRow>
                                                
                                            </TableBody>
                                        ))}
                                    </Table>

                                        

                                    <Grid style={{ marginTop: '2%' }}></Grid>

                                </>
                            </Box>
                        </Collapse>
                    </TableCell>
                    </TableRow>

            </React.Fragment>
        );
    }
    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

    return (
        console.log("BusinessTeamStatsTotalEmployee",BusinessTeamStatsTotalEmployee),
        <div>
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
            </modal> : null}

            <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
                <Grid item xs={12} container>
                    <Grid item xs={8} className={classes.pageHeaderLabel}>
                        {/* User's Login Info */}
                        Users Activity
                    </Grid>
                    <Grid container item xs={4} justify="flex-end">
                        Active Users: {BusinessTeamStatsLoggedInEmployee && BusinessTeamStatsLoggedInEmployee} / Total Users: {BusinessTeamStatsTotalEmployee && BusinessTeamStatsTotalEmployee}
                    </Grid>
                </Grid>
                
                <Grid style={{marginTop:'1%'}}>
                <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={UsersLoggeInDetails && UsersLoggeInDetails.length}
                    />
                    {/* <TableBody> */}
                    {stableSort(UsersLoggeInDetails && UsersLoggeInDetails, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => {
                        return (
                            <TableBody>
                                <Row key={item.user_id} row={item} />
                                {/* <TableRow
                                    key={item.case_id}
                                    hover={true} classes={{ hover: classes.hover }}
                                    // hover
                                    tabIndex={-1}
                                    align="center"
                                >
                                    <TableCell width="10%" align="center">{item.name}</TableCell>
                                    <TableCell width="10%" align="center">{
                                        (item.active_sessions.length === 0 )?
                                        (
                                            <Grid style={{color:'grey'}}>No Data</Grid>
                                        ):
                                        (
                                            item.active_sessions.map((itemi, indexi)=>(
                                            (indexi === 0)?
                                            (
                                                itemi.logged_in_time
                                            ):
                                            (<></>)

                                            ))
                                        )   
                                        
                                    }
                                    </TableCell>
    
                                </TableRow> */}
                            </TableBody>
                        );
                        })}
                    {/* </TableBody> */}
                    <TableFooter>
                    <TableRow>
                        <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: UsersLoggeInDetails && UsersLoggeInDetails.length }]}
                        colSpan={8}
                        count={UsersLoggeInDetails && UsersLoggeInDetails.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                    </TableFooter>
                </Table>
                </TableContainer>
                </Grid>
            </Grid>
            

            {/* <Grid item xs={6}>
                <Grid className={classes.pageHeaderLabel}>
                    User's Login Stat:
                </Grid>
                <Grid style={{marginTop:'1%'}}>
                    <Chart
                        width={'99%'}
                        height={432}
                        chartType="PieChart"
                        data={AllEmployeeLoggedInDetails}
                        options={{
                            // title: 'Employee LoggedIn Details',
                            // chartArea: { width: '80%' },
                        }}
                    />
                </Grid>
            </Grid> */}
            </Grid>
        </div>
    )
}
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import MessageIconChat from '@material-ui/icons/Chat';
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TaxManagementCasesOverView from "./TaxManagementCasesOverView";
import TaxManagementCasesActivity from "./TaxManagementCasesActivity";
import TaxManagementCasesMessages from "./TaxManagementCasesMessages";
import TaxManagementCasesDocuments from "./TaxManagementCasesDocuments";
import TaxManagementCasesQandA from "./TaxManagementCasesQandA";
import TaxManagementTrpNotes from "./TaxManagementTrpNotes";
import Modal from '@material-ui/core/Modal';
import { useSelector , useDispatch} from 'react-redux';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import InputLabel from '@material-ui/core/InputLabel';
import { FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter, Table , Spinner,
            Popover, PopoverHeader, PopoverBody} from 'reactstrap';
import { SketchPicker } from 'react-color';
import { userActions } from "../actions";
import CloseIcon from '@material-ui/icons/Close';
import { Link, Redirect} from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { addcaseDetailsShow } from "../actions/casedetails.actions"
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from "@material-ui/icons/Check";
import { Multiselect } from 'react-widgets';

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "4%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },
  casesDetailInfo: {
    textAlign: "left",
  },
  casesNameInfo: {
    marginTop: "10%",
    padding: 0,
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.96)",
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  casesFilingYearInfo: {
    padding: 0,
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  setPriorityButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "#4D47DD",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  verticalAlignTop: {
    verticalAlign: "top",
  },
  casesetPriorityInfo: {
    marginTop: "5%",
  },
  caseClientNameInfo: {
    padding: 0,
    lineHeight: "24px",
    marginTop: "2%",
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.96)",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  caseClientNameInfoLowMarginTop: {
    padding: 0,
    lineHeight: "24px",
    marginTop: "1%",
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.96)",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  casePanCardInfo: {
    padding: 0,
    lineHeight: "24px",
    marginTop: "2%",
    marginBottom: "7%",
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.96)",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  casePhoneNumberInfo: {
    padding: 0,
    lineHeight: "24px",
    marginTop: "3%",
    marginBottom: "3%",
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.96)",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  phoneIcon: {
    display: "inline",
    verticalAlign: "top",
    marginRight: "2%",
  },
  caseMessageInfo: {
    padding: 0,
    lineHeight: "24px",
    marginTop: "1%",
    marginBottom: "3%",
    textTransform: "none",
    color: "#4D47DD",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  messageIcon: {
    display: "inline",
    verticalAlign: "top",
    marginRight: "2%",
  },
  caseAddTagInfo: {
    padding: 0,
    lineHeight: "24px",
    marginTop: "1%",
    marginBottom: "3%",
    textTransform: "none",
    color: "#4D47DD",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  addeIcon: {
    display: "inline",
    verticalAlign: "top",
    marginRight: "2%",
  },
  casesDetailSubMenu: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.32)",
  },
  tabCustomStyle: {
    textTransform: "none",
    minWidth: '16.5%',
    // width:'10%',
    // fontSize: 10,
  },
  customPaperStyle: {
    borderRadius: "10px",
    marginLeft: "1.5%",
  },
  // modal: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  // paperModal: {
  //   backgroundColor: "#FFFFFF",
  //   boxShadow: theme.shadows[5],
  //   borderRadius: "5px",
  //   width: "570px",
  //   maxHeight: "480px",
  //   padding: theme.spacing(2, 4, 3),
  // },
   modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 650,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    // overflowY:'scroll',
  },
  paperModalAssigncaseOwner: {
    position: 'absolute',
    width: 500,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'6%',
    display:'block',
    overflowY:'scroll',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    width:'100%',
    // overflowY:'scroll',
    
  },
  SwitchStepHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    backgroundColor: 'white',
    height:'12%',
    alignItems: 'flex-end', 
    justifyContent: 'flex-end',
  },

  paperModalSelectTag: {
    position: 'absolute',
    width: 500,
    // height: 630,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'flex',
    flexDirection: 'column',
    // overflowY:'scroll',
    overflow:'auto', 
  },
  addNewBusinessHeaderBackgroudSelectTag: {
    // display: 'flex',
    // alignItems: 'center', 
    // justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    backgroundColor: 'white',
    // height:'12%',
    alignItems: 'flex-start', 
    justifyContent: 'flex-start',
    alignContent : 'flex-start',
    // flex:1,
  },
  selectTagModalBody:{
    padding: theme.spacing(2, 4),  
    width:'100%',
    // position:'absolute',
    // overflow:'auto', 
    // height: "65vh",
    alignItems: 'center', 
    justifyContent: 'center',
    alignContent : 'center',
    // flex: 1,
    // marginTop:'10%'
  },
  selectTagModalFooter: {
    padding: theme.spacing(0, 4),  
    // marginTop: '2%',
    display: 'flex',
    alignItems: 'flex-end', 
    justifyContent: 'flex-end',
    alignContent : 'flex-end',
    // flex: 1,
  },

  addNewTagHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    // backgroundColor: 'white',
    height:'12%',
  },
  PendingInfoHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'25%',
  },  
  
  submit: {
    margin: theme.spacing(3, 0, 2),
    cursor: "pointer",
  },
  submitPaymentDetails: {
    // margin: theme.spacing(3, 0, 2),
    cursor: "pointer",
  },
  addButtonStyle: {
    textTransform: "none",
    lineHeight: "24px",
    fontSize: "16px",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  moreVertIcon:{
    textAlign: "right",
    cursor: "pointer",
    color: 'black',
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalSwitchStep: {
    position: 'absolute',
    width: 500,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'4%',
    display:'block',
    overflowY:'scroll',
  },
  paperModalPendingInfo: {
    position: 'absolute',
    width: 500,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'4%',
    display:'block',
    // overflowY:'scroll',
  },
  menuLableStyle : {
    color: 'rgba(0, 0, 0, 0.64)',
    fontSize: '16px',
    // fontFamily: BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: 600,
    lineHeight: 0,
    textTransform: 'none',
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    cursor: 'pointer',
  },
  buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
    cursor: 'pointer',
  },
  CaseRejectedLable: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "red",
    textAlign: "left",
  },
  CaseRejectedComment: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TaxManagementCaseDetails({ handleChange, intl, props }) {
  const classes = useStyles();
  const [flag, setflag] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id , setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [open, setOpen] = React.useState(false);
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [navigatedFrom, setNavigatedFrom] = React.useState(JSON.parse(localStorage.getItem('navigated_toshow_case_from')));
  const [value, setValue] = React.useState(0);
  const [modalTagOpen, setModalTagOpen] = React.useState(false);
  const [caseDetailsFromApi, setcaseDetailsFromApi] = React.useState([]);
  const [caseAlertMessageDetailsFromApi, setcaseAlertMessageDetailsFromApi] = React.useState();
  const [caseQuestionAnswers, setcaseQuestionAnswers] = React.useState([]);
  const [caseTagDetails, setcaseTagDetails] = React.useState([]);
  const [CaseTagDetailsLength, setCaseTagDetailsLength] = React.useState("");
  const [allTagDetailsFromRedux, setallTagDetailsFromRedux] = React.useState([]);
  const [casePriorityIs, setcasePriorityIs] = React.useState("");
  const [allTagDetails, setallTagDetails] = React.useState([]);
  const [TagSelected, setTagSelected] = React.useState([]);
  const [TagSelectedNames, setTagSelectedNames] = React.useState([]);
  const [TagSelectedOrUnselected, setTagSelectedOrUnselected] = React.useState(false);
  const [modalAddTagOpen, setmodalAddTagOpen] = React.useState(false);
  const [addNewTagDetails, setaddNewTagDetails] = React.useState({
    business_id: JSON.parse(localStorage.getItem('selected_business_id')),
    tag_id: "",
    name: "",
    shortcode: " ",
    color: "",
    is_deleted: false,
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [nameErr, setnameErr] = React.useState();
  // const [shortCodeErr, setshortCodeErr] = React.useState();
  // const [issDeleteErr, setissDeleteErr] = React.useState();
  const [colorErr, setcolorErr] = React.useState();
  const [ShwoTagsdropdown, setShwoTagsdropdown] = React.useState(false);

  const [AllTagsPaginationDetails, setAllTagsPaginationDetails] = React.useState({total_items:0})
  const [TagPage, setTagPage] = React.useState(1);
  const [TagTotalPageCount, setTagTotalPageCount] = React.useState();

  const [yesNo, setyesNo] = React.useState([
    { value: true , name: 'Yes'  },
    { value: false , name: 'No'  },
  ]);
  const [EditTagFlag, setEditTagFlag] = React.useState(false);

  const [ClientDetails, setClientDetails] = React.useState([]);
  const [RedirectToGetClientDetailsPage, setRedirectToGetClientDetailsPage] = React.useState(false);
  const [RedirectToEditClientDetails, setRedirectToEditClientDetails] = React.useState(false);
  const [caseForDetailsFromAsync, setcaseForDetailsFromAsync] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_details')));

  const [role_of_user_Global, setrole_of_user_Global] =  React.useState(JSON.parse(localStorage.getItem('role_of_user')))
  const [openSettings, setopenSettings] = React.useState(false);

  // settings case confirm
  const [OpenMenu, setOpenMenu] = React.useState(false);
  const [openSwitchStepConfirmed, setopenSwitchStepConfirmed] = React.useState(false);
  const [caseDetailsToSwitchStep, setcaseDetailsToSwitchStep] = React.useState([]);
  const [selectedStepNameToSwith, setselectedStepNameToSwith] = React.useState("");
  const [SelectedStepNameIdIs, setSelectedStepNameIdIs] = React.useState();
  const [switchStepErr, setswitchStepErr] = React.useState();

  // AssignUsers
  const [openAssignUserModal, setopenAssignUserModal] = React.useState(false);
  const [assignUserDetails, setassignUserDetails] = React.useState([]);
  const [assignNewUserDetails, setassignNewUserDetails] = React.useState({
    case_id: "",
    owner_id: "",
    assigned_by_id: "",
    comments: "",
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [selectedUserToAssignId, setselectedUserToAssignId] = React.useState();
  const [selectedUserToAssignName, setselectedUserToAssignName] = React.useState("");
  const [allUsersList, setallUsersList] = React.useState([]);
  const [switchRadio, setswitchRadio] = React.useState(true);
  const [assignUserErr, setassignUserErr] = React.useState("");

  // settings case created
  const [OpenMenuCreated, setOpenMenuCreated] = React.useState(false);
  const [slectedCaseForDetailsHere, setslectedCaseForDetailsHere] = React.useState([]);
  const [processDetailsByService, setprocessDetailsByService] = React.useState([]);
  const [openSwitchProcess, setopenSwitchProcess] = React.useState(false);
  const [currentProcessName, setCurrentProcessName] = React.useState("");
  const [selectedProcessNameToSwitch, setselectedProcessNameToSwitch] = React.useState();
  const [selectedProcessIdToSwitch, setselectedProcessIdToSwitch] = React.useState();
  const [switchProcessErr, setswitchProcessErr] = React.useState();
  const [RedirectToCaseTable, setRedirectToCaseTable] = React.useState(false);

  // complete case
  const [OpenMenuCompleted , setOpenMenuCompleted] = React.useState(false);

  // reject case
  const [OpenRejectCase, setOpenRejectCase] = React.useState(false);
  const [RejectCaseComment, setRejectCaseComment] = React.useState("");
  const [RejectCaseCommentErr, setRejectCaseCommentErr] = React.useState("");

  // Pending Info
  const [OpenPendingInfo, setOpenPendingInfo] = React.useState(false);
  const [PendingInfoComment, setPendingInfoComment] = React.useState("");
  const [pendingInfoCommentErr, setpendingInfoCommentErr] = React.useState("");

  // Close Case
  const [OpenCloseCase, setOpenCloseCase] = React.useState(false);
  const [CloseCaseComment, setCloseCaseComment] = React.useState("");
  const [CloseCaseCommentErr, setCloseCaseCommentErr] = React.useState("");

  // Payment Info
  const [RedirectTrpPaymentInfo, setRedirectTrpPaymentInfo] = React.useState(false);

  // redux get
  const dispatch = useDispatch();
  const caseDetailsShowFromRedux = useSelector(state => state.caseDetails.caseDetailsShow);

  // redux set
  const addcaseDetailsShowRedux = (caseDetailsShow) => dispatch(addcaseDetailsShow(caseDetailsShow));

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)

  
  React.useEffect(() => {
    // readItemFromStorage();
    dispatch(userActions.checkSessionValidity());
    setflag(true);
    setShowProcedure(true); 

    console.log("page newPage on case details from localstorage", localStorage.getItem('pageNumberOfTableCasesTableTrpAdmin'))
    console.log("rows per page on case details from localstorage",localStorage.getItem('rowsPerPageOfTableCasesTableTrpAdmin'))

    addcaseDetailsShowRedux(JSON.parse(localStorage.getItem('selected_case_for_details')));
  
    // to fetch case details of selected case
    const requestOptionsCaseDetails = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id': caseForDetailsFromAsync.case_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_CASE_DETAILS , requestOptionsCaseDetails)
      .then((Response) => Response.json())
      .then( async(caseDetails) => {
        setShowProcedure(false);
        console.log("caseDetailson on show case details",caseDetails);
        if(caseDetails.success == true)
        {
          console.log("caseDetailson on show case details true",caseDetails.data.case_details);
          let case_details = caseDetails.data.case_details ;
          setcaseDetailsFromApi(case_details);
          setcaseAlertMessageDetailsFromApi(case_details.case_alert_message);
          setcasePriorityIs(caseDetails.data.case_details.priority)
          let case_question_answers = caseDetails.data.case_details.question_answers ;
          setcaseQuestionAnswers(case_question_answers);
          setCurrentProcessName(caseDetails.data.case_details.process_name);
          // for switch process
          setselectedProcessNameToSwitch(caseDetails.data.case_details.process_name)
          setselectedProcessIdToSwitch(caseDetails.data.case_details.process_id)
        }
        else if(caseDetails.success == false){
          setcaseDetailsFromApi([])
          setcaseAlertMessageDetailsFromApi()
          setcaseQuestionAnswers([])
          Swal.fire({
            icon: 'error',
            text: caseDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

    // to get case tags
    fetch( process.env.REACT_APP_GET_CASE_TAGS , requestOptionsCaseDetails)
      .then((Response) => Response.json())
      .then( async(caseTagDetails) => {
        console.log("caseTagDetails on show case details",caseTagDetails);
        if(caseTagDetails.success == true)
        {
          console.log("caseTagDetails on show case details ",caseTagDetails.data.case_tags);
          await setcaseTagDetails(caseTagDetails.data.case_tags);
          console.log("caseTagDetails  length in useeffect",caseTagDetails.data.case_tags.length)
          let CaseTagDetailsLengthHere = caseTagDetails.data.case_tags.length;
          await setCaseTagDetailsLength(CaseTagDetailsLengthHere)

          // await  setallTagDetails(
          //   allTagsFromAPI.map((f) => {
          //     for (let index = 0; index < caseTags.length; index++) {
          //       // f.tag_name = allTagsFromAPI.name
          //       if(f.tag_id === caseTags[index].tag_id){
          //         f.selected = true;
          //       break;
          //       }
          //       else{
          //         f.selected = false;
          //       }
          //     }
          //     return f ;
          //   })
          // )

          // await setTagSelectedNames(caseTagDetails.data.case_tags)

          await setTagSelectedNames(
            caseTagDetails.data.case_tags.map( (f) => {
              console.log("in caseTagDetails.data.case_tags.map", f)
              for (let index = 0; index < caseTagDetails.data.case_tags.length; index++) {
                f.name = f.tag_name ;
              }
              return f ;
            })
          )
          
        }
        else if(caseTagDetails.success == false){
          await setcaseTagDetails([]);
          await setCaseTagDetailsLength("");
        }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

    // // to get all tags with pagination
    // const requestOptionsAllTags = {
    //   method: 'POST',
    //   headers: authHeader(),
    //   body: JSON.stringify({'business_id': business_id,
    //     // 'page': 1,'per_page': 10,
    //     "page_number": 1, "page_size": 10,
    //     'device_token': device_token, 'session_id': session_id })
    // };
    // fetch( process.env.REACT_APP_GET_BUSINESS_TAGS , requestOptionsAllTags)
    //   .then((Response) => Response.json())
    //   .then( async(AllTagDetails) => {
    //     console.log("AllTagDetails on show case table",AllTagDetails);
    //     if(AllTagDetails.success == true)
    //     {
    //       console.log("AllTagDetails on show case table ",AllTagDetails.data.business_tags_info);
    //       // setallTagDetails(AllTagDetails.data.business_tags_info);
    //       await localStorage.setItem('all_tags_async', JSON.stringify(AllTagDetails.data.business_tags_info));
    //       // caseTagDetails
    //       let caseTags = caseTagDetails
    //       let allTagsFromAPI = AllTagDetails.data.business_tags_info
    //       console.log("allTagsFromAPI",allTagsFromAPI)
    //       await  setallTagDetails(
    //         allTagsFromAPI.map((f) => {
    //           for (let index = 0; index < caseTags.length; index++) {
    //             // f.tag_name = allTagsFromAPI.name
    //             if(f.tag_id === caseTags[index].tag_id){
    //               f.selected = true;
    //             break;
    //             }
    //             else{
    //               f.selected = false;
    //             }
    //           }
    //           return f ;
    //         })
    //       )

    //       if(AllTagDetails.data.pagination){
    //         setAllTagsPaginationDetails(AllTagDetails.data.pagination)
    //         setTagTotalPageCount(AllTagDetails.data.pagination.total_pages)
    //       }
    //       else{
    //         setAllTagsPaginationDetails({...AllTagsPaginationDetails, total_items:0 })
    //         setTagTotalPageCount(0)
    //       }
    //     }
    //     else if(AllTagDetails.success == false){
    //       await localStorage.setItem('all_tags_async', JSON.stringify([]));
    //       // setallTagDetails([]);
    //       setAllTagsPaginationDetails({...AllTagsPaginationDetails, total_items:0 })
    //       setTagTotalPageCount(0)
    //     }
    //   })
    //   .catch(err => {
    //     Swal.fire({
    //       icon: 'error',
    //       // text: "Something went wrong",
    //       text: "Server Error. Please try again.",
    //       confirmButtonColor: '#d33',
    //       confirmButtonText: 'OK'
    //     })
    //   });

    // to get all tags without pagination
    const requestOptionsAllBusinessTags = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        // 'page': 1,'per_page': 10,
        "page_number": 1, "page_size": 10,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_ALL_BUSINESS_TAGS , requestOptionsAllBusinessTags)
      .then((Response) => Response.json())
      .then( async(AllBusinessTagDetails) => {
        console.log("AllBusinessTagDetails on show case table",AllBusinessTagDetails);
        if(AllBusinessTagDetails.success == true)
        {
          console.log("AllBusinessTagDetails on show case table ",AllBusinessTagDetails.data.business_tags_info);
          // setallTagDetails(AllBusinessTagDetails.data.business_tags_info);
          await localStorage.setItem('all_tags_async', JSON.stringify(AllBusinessTagDetails.data.business_tags_info));
          // caseTagDetails
          let caseTags = caseTagDetails
          let allTagsFromAPI = AllBusinessTagDetails.data.business_tags_info
          console.log("allTagsFromAPI",allTagsFromAPI)
          await  setallTagDetails(
            allTagsFromAPI.map((f) => {
              for (let index = 0; index < caseTags.length; index++) {
                // f.tag_name = allTagsFromAPI.name
                if(f.tag_id === caseTags[index].tag_id){
                  f.selected = true;
                break;
                }
                else{
                  f.selected = false;
                }
              }
              return f ;
            })
          )
        }
        else if(AllBusinessTagDetails.success == false){
          await localStorage.setItem('all_tags_async', JSON.stringify([]));
          setallTagDetails([]);
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // to get all users
    const requestOptionsUsers = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_BUSINESS_TEAM , requestOptionsUsers)
      .then((Response) => Response.json())
      .then( async(allUsersDetails) => {
        // setShowProcedure(false);
        console.log("allUsersDetails on case details",allUsersDetails);
        if(allUsersDetails.success == true)
        {
          setallUsersList(allUsersDetails.data.business_team);
        }
        else if(allUsersDetails.success == false){
          setallUsersList([]);
        }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

    // To get Processess
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'service_id': caseForDetailsFromAsync.service_id, 'process_type': 'live' ,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_SERVICE_PROCESS_URL , requestOptions)
    .then((Response) => Response.json())
    .then( async(processDetailsByService) => {
      console.log("processDetailsBy Service on case details",processDetailsByService);
      if(processDetailsByService.success == true)
      {
        if(processDetailsByService.data.processes){
          setprocessDetailsByService(processDetailsByService.data.processes);
        }
        else{
          setprocessDetailsByService([]);
        }
      }
      else if(processDetailsByService.success == false){
        setprocessDetailsByService([]);
      }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

    // to get all clients
    const requestOptionsClients = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,'device_token': device_token, 
          'session_id': session_id })
    };

    fetch( process.env.REACT_APP_GET_CLIENTS , requestOptionsClients)
    .then((Response) => Response.json())
    .then( clientDetails => {
        console.log("clientDetails on same page",clientDetails.data.clients);
        if(clientDetails.data.clients)
        {
            setClientDetails(clientDetails.data.clients)
        }
        if(clientDetails.success == false){
          setClientDetails([])
            Swal.fire({
            icon: 'error',
            text: clientDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
        })
        }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

  // }, [open, flag]);
  }, [ flag, open, modalTagOpen, modalAddTagOpen]);

  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //   } catch (error) {
  //     console.log("ERROR:",error);
  //   }
  // };

  const caseForDetailsFromAsyncRefresh = () => {
    const requestOptionsCaseDetails = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id': caseForDetailsFromAsync.case_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_CASE_DETAILS , requestOptionsCaseDetails)
      .then((Response) => Response.json())
      .then( async(caseDetails) => {
        console.log("caseDetailson on confirm case details",caseDetails);
        if(caseDetails.success == true)
        {
          console.log("caseDetailson on confirm case details true",caseDetails.data.case_details);
          
          await localStorage.setItem('selected_case_for_details', JSON.stringify(caseDetails.data.case_details));
          await setcaseForDetailsFromAsync(caseDetails.data.case_details)
          setValue(0);
          setflag(true);
        }
        else if(caseDetails.success == false){
          // await localStorage.setItem('selected_case_for_details', []);
          // await setcaseForDetailsFromAsync([])
          setValue(0);
          setflag(true);
        }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    console.log("event of priority",event.target);
    // console.log("event of priority",event.target.li);
    // console.log("event of priority",event.target.value);
    setOpen(false);
  };

  // const handlePrioritySeleted = (e) => {
  //   console.log("event of priority",e.target.value);
  // }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleChangePriority = async(priorityHere) => {
    setOpen(false);
    console.log("handleChangePriority",priorityHere)
    // await setcaseDetailsFromApi({ ...caseDetailsFromApi,
    //   priority: priorityHere,
    //   device_token: JSON.parse(localStorage.getItem('device_token')),
    //   session_id: JSON.parse(localStorage.getItem('session_id')),
    // })
    // await addcaseDetailsShowRedux({...caseDetailsFromApi,
    //   priority: priorityHere,
    //   device_token: JSON.parse(localStorage.getItem('device_token')),
    //   session_id: JSON.parse(localStorage.getItem('session_id')),
    // })
    let casePriorityDetailsHere = ({
      case_id: caseDetailsFromApi.case_id,
      name: caseDetailsFromApi.name,
      description: caseDetailsFromApi.description,
      service_id: caseDetailsFromApi.service_id,
      priority: priorityHere,
      // due_date: caseDetailsFromApi.due_date,
      self_assessment: caseDetailsFromApi.self_assessment,
      assesse_name: caseDetailsFromApi.for_assesse_name,
      assesse_email: caseDetailsFromApi.for_assesse_email,
      assesse_pan: caseDetailsFromApi.for_assesse_pan,
      assesse_phone: caseDetailsFromApi.for_assesse_phone,
      assesse_id: caseDetailsFromApi.for_assesse_id,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    console.log("casePriorityDetailsHere in change priority",casePriorityDetailsHere)
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(casePriorityDetailsHere)
    };
    return fetch( process.env.REACT_APP_UPDATE_CASE , requestOptions)
      .then((Response) => Response.json())
      .then( async(casePriorityChanged) => {
        console.log("casePriorityChanged",casePriorityChanged)
        if(casePriorityChanged.success == true){
          setcasePriorityIs(casePriorityChanged.data.case_info.priority)
          // Swal.fire({
          //   icon: 'success',
          //   text: "Priority Changed Successfully!",
          //   confirmButtonColor: 'primary',
          //   confirmButtonText: 'OK',
          //   timer: 9000,
          // })
        }
        else if(casePriorityChanged.success == false){
          Swal.fire({
            icon: 'error',
            text: casePriorityChanged.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  // select tag all functions
  const handleModalOpen = () => {
    setModalTagOpen(true);
    // to get all tags
    let business_id = JSON.parse(localStorage.getItem('selected_business_id'));

    const requestOptionsAllTags = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        // 'page': 1,'per_page': 10,
        "page_number": 1, "page_size": 10,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_BUSINESS_TAGS , requestOptionsAllTags)
      .then((Response) => Response.json())
      .then( async(AllTagDetails) => {
        console.log("AllTagDetails on show case table",AllTagDetails);
        if(AllTagDetails.success == true)
        {
          console.log("AllTagDetails on show case table ",AllTagDetails.data.business_tags_info);
          // setallTagDetails(AllTagDetails.data.business_tags_info);
          await localStorage.setItem('all_tags_async', JSON.stringify(AllTagDetails.data.business_tags_info));
          // caseTagDetails
          let caseTags = caseTagDetails
          let allTagsFromAPI = AllTagDetails.data.business_tags_info
          console.log("allTagsFromAPI",allTagsFromAPI)
          await  setallTagDetails(
            allTagsFromAPI.map((f) => {
              for (let index = 0; index < caseTags.length; index++) {
                // f.tag_name = allTagsFromAPI.name
                if(f.tag_id === caseTags[index].tag_id){
                  f.selected = true;
                break;
                }
                else{
                  f.selected = false;
                }
              }
              return f ;
            })
          )

          if(AllTagDetails.data.pagination){
            setAllTagsPaginationDetails(AllTagDetails.data.pagination)
            setTagTotalPageCount(AllTagDetails.data.pagination.total_pages)
          }
          else{
            setAllTagsPaginationDetails({...AllTagsPaginationDetails, total_items:0 })
            setTagTotalPageCount(0)
          }
        }
        else if(AllTagDetails.success == false){
          await localStorage.setItem('all_tags_async', JSON.stringify([]));
          // setallTagDetails([]);
          setAllTagsPaginationDetails({...AllTagsPaginationDetails, total_items:0 })
          setTagTotalPageCount(0)
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    setflag(false);

  };

  const refreshCaseTagSelection = () =>{
    // console.log("in refreshCaseTag Selection")
    // to get all tags
    let business_id = JSON.parse(localStorage.getItem('selected_business_id'));

    const requestOptionsAllTags = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_BUSINESS_TAGS , requestOptionsAllTags)
      .then((Response) => Response.json())
      .then( async(AllTagDetails) => {
        console.log("AllTagDetails on show case table",AllTagDetails);
        if(AllTagDetails.success == true)
        {
          console.log("AllTagDetails on show case table ",AllTagDetails.data.business_tags_info);
          // setallTagDetails(AllTagDetails.data.business_tags_info);
          await localStorage.setItem('all_tags_async', JSON.stringify(AllTagDetails.data.business_tags_info));
          // caseTagDetails
          let caseTags = caseTagDetails
          let allTagsFromAPI = AllTagDetails.data.business_tags_info
          console.log("allTagsFromAPI",allTagsFromAPI)
          await  setallTagDetails(
            allTagsFromAPI.map((f) => {
              for (let index = 0; index < caseTags.length; index++) {
                if(f.tag_id === caseTags[index].tag_id){
                  f.selected = true;
                break;
                }
                else{
                  f.selected = false;
                }
              }
              return f ;
            })
          )

          if(AllTagDetails.data.pagination){
            setAllTagsPaginationDetails(AllTagDetails.data.pagination)
            setTagTotalPageCount(AllTagDetails.data.pagination.total_pages)
          }
          else{
            setAllTagsPaginationDetails({...AllTagsPaginationDetails, total_items:0 })
            setTagTotalPageCount(0)
          }
        }
        else if(AllTagDetails.success == false){
          await localStorage.setItem('all_tags_async', JSON.stringify([]));
          // setallTagDetails([]);
          setAllTagsPaginationDetails({...AllTagsPaginationDetails, total_items:0 })
          setTagTotalPageCount(0)
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    setflag(false);
  }

  const handleModalClose = async() => {
    setModalTagOpen(false);
    setTagPage(1)

    // setTagSelectedNames();
    setTagSelected([])

    // to clear selected values of tags for multiple select
    // await setTagSelectedNames(
    //   caseTagDetails.map( (f) => {
    //     console.log("in caseTagDetails.data.case_tags.map", f)
    //     for (let index = 0; index < caseTagDetails.length; index++) {
    //       f.name = f.tag_name ;
    //     }
    //     return f ;
    //   })
    // )
  };

  // tag selection functions of new dropdown ui
  const handleTagsDropdownOpen = async() => {
    await setTagSelectedNames(
      caseTagDetails.map( (f) => {
        console.log("in caseTagDetails.data.case_tags.map", f)
        for (let index = 0; index < caseTagDetails.length; index++) {
          f.name = f.tag_name ;
        }
        return f ;
      })
    )
    setShwoTagsdropdown(true)
  }

  const handleTagsDropdownClose = () => {
    setTagSelected([])
    setTagSelectedNames([])
    setShwoTagsdropdown(false)

  }

  const handelSubmitSelectTags = () => {
    console.log("TagSelectedOrUnselected in submt",TagSelectedOrUnselected, )

    // let valueHere = TagSelected.length
    console.log("TagSelected in submt",TagSelected, TagSelected.length )

    // if( TagSelectedOrUnselected == false ){
    if( TagSelected.length <= 0 ){
      Swal.fire({
        // icon: 'error',
        // text: "Something went wrong",
        text: "No tags selected. Please click on the tag to select or unselect and apply",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }
    else{
      setShowProcedure(true)
      console.log("handelSubmitSelectTags clicked")
      // setModalTagOpen(false);
      const tags = [];
      allTagDetails.map((f)=> {
        if(f.selected == true){
          console.log("true f is",f)
          tags.push(f.tag_id);
        }
      })
      console.log("tag is",tags)

      const requestOptionsCaseNewTags = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'case_id': caseForDetailsFromAsync.case_id, 
          // 'tags':tags,
          'tags': TagSelected,
          'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_UPDATE_CASE_TAGS , requestOptionsCaseNewTags)
        .then((Response) => Response.json())
        .then( async(caseTagUpdated) => {
          setShowProcedure(false)
          console.log("caseTagUpdated on show case details",caseTagUpdated);
          if(caseTagUpdated.success == true)
          {
            console.log("caseTagUpdated on show case details ",caseTagUpdated.data.case_tags);
            refreshCaseTagSelection();
            setShwoTagsdropdown(false);
            Swal.fire({
              icon: 'success',
              text: "Tags Assigned Successfully",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
          else if(caseTagUpdated.success == false){
            console.log("caseTagUpdated on show case details ",caseTagUpdated.data.case_tags);
          }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
      setflag(false);
      setTagPage(1)
    }
  }

  const TagSelectedFunction = async(item) => {
    console.log("in TagSelectedFunction",item)
    await setTagSelectedOrUnselected(true);
    await setallTagDetails(
      allTagDetails.map((f)=> {
        if(f.tag_id === item.tag_id){
          f.selected = !f.selected;
        }
        return f ;
      })
    )
  }

  const ShowALLTagsClicked = () => {
    console.log("in ShowALLTagsClicked", AllTagsPaginationDetails.total_items)

    const requestOptionsAllTags = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        // 'page': 1,
        // 'per_page': AllTagsPaginationDetails.total_items,
        "page_number": 1, "page_size": AllTagsPaginationDetails.total_items,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_BUSINESS_TAGS , requestOptionsAllTags)
      .then((Response) => Response.json())
      .then( async(AllTagDetails) => {
        console.log("AllTagDetails on show case table",AllTagDetails);
        if(AllTagDetails.success == true)
        {
          console.log("AllTagDetails on show case table ",AllTagDetails.data.business_tags_info);
          // setallTagDetails(AllTagDetails.data.business_tags_info);
          await localStorage.setItem('all_tags_async', JSON.stringify(AllTagDetails.data.business_tags_info));
          // caseTagDetails
          let caseTags = caseTagDetails
          let allTagsFromAPI = AllTagDetails.data.business_tags_info
          console.log("allTagsFromAPI",allTagsFromAPI)
          await  setallTagDetails(
            allTagsFromAPI.map((f) => {
              for (let index = 0; index < caseTags.length; index++) {
                if(f.tag_id === caseTags[index].tag_id){
                  f.selected = true;
                break;
                }
                else{
                  f.selected = false;
                }
              }
              return f ;
            })
          )

          if(AllTagDetails.data.pagination){
            setAllTagsPaginationDetails(AllTagDetails.data.pagination)
          }
          else{
            setAllTagsPaginationDetails({...AllTagsPaginationDetails, total_items:0 })
          }
        }
        else if(AllTagDetails.success == false){
          await localStorage.setItem('all_tags_async', JSON.stringify([]));
          // setallTagDetails([]);
          setAllTagsPaginationDetails({...AllTagsPaginationDetails, total_items:0 })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleChangeTagPagination = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log("value in handleChangeTagPagination", value)
    setTagPage(value);

    const requestOptionsAllTags = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        // 'page': value,
        // 'per_page': 10,
        "page_number": value, "page_size": 10,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_BUSINESS_TAGS , requestOptionsAllTags)
      .then((Response) => Response.json())
      .then( async(AllTagDetails) => {
        console.log("AllTagDetails on show case table",AllTagDetails);
        if(AllTagDetails.success == true)
        {
          console.log("AllTagDetails on show case table ",AllTagDetails.data.business_tags_info);

          await localStorage.setItem('all_tags_async', JSON.stringify(AllTagDetails.data.business_tags_info));
          // caseTagDetails
          let caseTags = caseTagDetails
          let allTagsFromAPI = AllTagDetails.data.business_tags_info
          console.log("allTagsFromAPI",allTagsFromAPI)
          await  setallTagDetails(
            allTagsFromAPI.map((f) => {
              for (let index = 0; index < caseTags.length; index++) {
                if(f.tag_id === caseTags[index].tag_id){
                  f.selected = true;
                break;
                }
                else{
                  f.selected = false;
                }
              }
              return f ;
            })
          )

          if(AllTagDetails.data.pagination){
            setAllTagsPaginationDetails(AllTagDetails.data.pagination)
            setTagTotalPageCount(AllTagDetails.data.pagination.total_pages)
          }
          else{
            setAllTagsPaginationDetails({...AllTagsPaginationDetails, total_items:0 })
            setTagTotalPageCount(0)
          }
        }
        else if(AllTagDetails.success == false){
          await localStorage.setItem('all_tags_async', JSON.stringify([]));
          // setallTagDetails([]);
          setAllTagsPaginationDetails({...AllTagsPaginationDetails, total_items:0 })
          setTagTotalPageCount(0)
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  };

  const body = (
    <div className={classes.paperModalSelectTag}>
      {/* {console.log("TagPage",TagPage)}  */}
      <div className={classes.addNewBusinessHeaderBackgroudSelectTag}> 
        {/* <h2>Select Tags</h2> */}
        <Grid container >
          <Grid container item xs={11} style={{justifyContent: 'center',}}>
            <h2>Select Tags</h2>               
          </Grid>
          <Grid item xs={1} style={{marginTop: '4%',}}>
            <Grid item xs={1}  className={classes.moreVertIcon}>
              <CloseIcon onClick={handleModalClose} style={{color:'black', cursor: "pointer", }}/>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.selectTagModalBody} >
        {/* height: "60vh" */}
        <Grid item xs={12}>

          {/* New with multiple select dropdown */}
          <Multiselect
            data={allTagDetails}
            defaultValue={TagSelectedNames}
            placeholder="Select Tags"
            valueField='tag_id'
            textField='name'
            onChange={async (event) => {
              // console.log("event of multiselect",event);
              var joined = [];
              var joinedNames = [];
              // console.log("length : "+event.length);
              if (event.length === 0) {
                await setTagSelected([])
              }
              event.map(async (data) => {
                // console.log("data in eve map : "+JSON.stringify(data));
                joined = joined.concat(data.tag_id);
                joinedNames = joinedNames.concat(data);
                // console.log("data join : ",joined);
                await setTagSelected(joined)
                await setTagSelectedNames(joinedNames)
                await setTagSelectedOrUnselected(true)
                // console.log("check this TagFilter",TagFilter)
              })
            }}
          />
        {/* New with multiple select dropdown */}

        {/* <Stack spacing={2}>
          <ul style={{listStyle: "none", marginLeft: "-8%", cursor: "pointer"}}>
            {allTagDetails && allTagDetails.map((item, index) =>
              <li key={item.tag_id} onClick={() => TagSelectedFunction(item)}>
                <Grid container style={{marginTop:"2%"}}>
                  <Grid container item xs={11} style={{ backgroundColor: item.color }}>
                    <Grid item xs={11} style={{padding: 12,}}>
                      <text style={{ color:'black', padding: 1, backgroundColor:'white' }}>{item.name}</text>
                    </Grid>
                    <Grid item xs={1} style={{ padding: 9, marginLeft:"-2%" }}>
                      {
                        ( item.selected == true)?
                        (
                          <Grid item xs={1} className={classes.moreVertIcon}>
                            <CheckIcon style={{color:'black', padding: 1, backgroundColor:'white'}}/>
                          </Grid>
                        ):
                        (<div></div>)
                      }
                    </Grid>
                    
                  </Grid>

                  
                  { role_of_user_Global && role_of_user_Global !== "client" && user_permissions && user_permissions.permissions.create_tags &&
                    <Grid item xs={1} className={classes.moreVertIcon}>
                      <EditIcon onClick={() => TagEditFunction(item)}/>
                    </Grid>
                  }
                </Grid>             
              </li>
            )}
          </ul>
          <Pagination 
            color="primary"
            count={TagTotalPageCount} 
            page={TagPage && TagPage}  
            onChange={handleChangeTagPagination}
          />
        </Stack> */}
          

          {/* Number of tags for pagination */}
          {/* <Grid container item xs={12} style={{ marginTop:'4%', marginLeft:'1%' }}>
            <Grid item xs={10}>
              Showing : { AllTagsPaginationDetails && AllTagsPaginationDetails.per_page}/{AllTagsPaginationDetails && AllTagsPaginationDetails.total_items} Tags
            </Grid>
            <Grid item xs={2} >
              <text style={{ color:'blue', cursor:'pointer' }}
                onClick={ () => ShowALLTagsClicked()}
              >
                Show All 
              </text>
            </Grid>
             
          </Grid> */}
          {/* Number of tags */}
          
          
          {/* To create new tag */}
          <Grid style={{marginTop:'2%'}}>
          {role_of_user_Global && role_of_user_Global !== "client" && user_permissions && user_permissions.permissions.create_tags &&
            <Button
              className={classes.addButtonStyle}
              startIcon={<AddIcon />}
              onClick={() => handelAddNewTagModalOpen()}
            >
              <FormattedMessage id="createNewTagLabel" />
            </Button>
          }
          </Grid>
        </Grid>
      </div>

        <div className={classes.selectTagModalFooter}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitSelectTags}
          >
            Submit
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
        </div>
    
    </div>
  )

  // Edit tag Functions
  const TagEditFunction = async(item) => {
    console.log("in TagEditFunction",item)
    await setaddNewTagDetails({...addNewTagDetails, 
      tag_id: item.tag_id,
      name: item.name,
      shortcode: item.shortcode,
      color: item.color,
      // is_deleted: item.is_deleted,
    })
    await setEditTagFlag(true);
    handelAddNewTagModalOpen();
   
  }

  // add new tag functions
  const handelAddNewTagModalOpen = () => {
    setmodalAddTagOpen(true);
    setModalTagOpen(false);
    setTagPage(1)
  }

  const handelAddNewTagModalClose = async() => {
    setmodalAddTagOpen(false);
    await setaddNewTagDetails({...addNewTagDetails, 
      tag_id: "",
      name: "",
      shortcode: " ",
      color: "",
      // is_deleted: "",
    })
    setnameErr();
    // setshortCodeErr();
    // setissDeleteErr();
    setcolorErr();
    setEditTagFlag(false);
    setTagPage(1)
  }

  const validate = () => {
    let nameErr = '';
    // let shortCodeErr = '';
    // let issDeleteErr = '';
    let colorErr = '';
    
    if(!addNewTagDetails.name)  {
      nameErr = 'Please Enter Name';
    }
    else{
      if(EditTagFlag == false){
        allTagDetails.map((e,i)=>{
          if(e.name === addNewTagDetails.name){
            nameErr = 'Please enter another name, this name is already in use.'
          }
        })
      }
      else if(EditTagFlag == true){
        allTagDetails.map((e,i)=>{
          if(e.name === addNewTagDetails.name){
            if(e.tag_id === addNewTagDetails.tag_id){
              console.log("same tag leave it")
            }
            else{
              nameErr = 'Please enter another name, this name is already in use.'
            }
          }
        })
      }
    }
    // if(!addNewTagDetails.shortcode)  {
    //   shortCodeErr = 'Please Enter Shortcode';
    // }
    // if(addNewTagDetails.is_deleted == "" && addNewTagDetails.is_deleted !== false)  {
    //   issDeleteErr = 'Please Select Is Tag is Deleted';
    // }
    if(!addNewTagDetails.color)  {
      colorErr = 'Please Select Color';
    }
    else{
      if(EditTagFlag == false){
        allTagDetails.map((e,i)=>{
          // console.log("element in validation tag list map is",e)
          if(e.color === addNewTagDetails.color){
            colorErr = 'Please select another color, this color is already in use.'
          }
        })
      }
      else if(EditTagFlag == true){
        allTagDetails.map((e,i)=>{
          // console.log(" if it is edit then compare ",tagDetails,e)
          // console.log("element in validation tag list map is",e)
          if(e.color === addNewTagDetails.color){
            if(e.tag_id === addNewTagDetails.tag_id){
              console.log("same tag leave it")
            }
            else{
              colorErr = 'Please select another color, this color is already in use.'
            }
          }
        })
      }
    }

    if(nameErr || colorErr){
        setnameErr(nameErr);
        // setshortCodeErr(shortCodeErr);
        // setissDeleteErr(issDeleteErr);
        setcolorErr(colorErr);

        return false;
      }
      return true;
  }

  const handelSubmitCreateNewTags = () => {
    console.log("addNewTagDetails",addNewTagDetails)
    const isValid = validate();
    if(isValid){
      setmodalAddTagOpen(false);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(addNewTagDetails)
      };
      return fetch( process.env.REACT_APP_CREATE_TAG , requestOptions)
        .then((Response) => Response.json())
        .then(newTagCreated => {
          console.log("newTagCreated ",newTagCreated);
          if(newTagCreated.success == true){
            if(EditTagFlag == true){
              Swal.fire({
                icon: 'success',
                text: "Tag Updated Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              }).then(function (result) {
                // setModalTagOpen(true);
              })
            }
            else{
              Swal.fire({
                icon: 'success',
                text: "Tag Created Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              }).then(function (result) {
                // setModalTagOpen(true);
              })
            }
            refreshCaseTagSelection();
            setmodalAddTagOpen(false);
            // setModalTagOpen(true);
            setaddNewTagDetails({...addNewTagDetails, 
                tag_id: "",
                name: "",
                shortcode: " ",
                color: "",
                // is_deleted: "",
              })
              setnameErr();
              // setshortCodeErr();
              // setissDeleteErr();
              setcolorErr();
            // let allTagsFromAsync = JSON.parse(localStorage.getItem('all_tags_async'));
            // let newTagIs = newTagCreated.data.tag_info
            // let NewTagListIs = allTagsFromAsync.push(newTagIs)
            // console.log("NewTagListIs",JSON.parse(NewTagListIs))
            // tags.push(f.tag_id);
            
          }
          if(newTagCreated.success == false){
            Swal.fire({
              icon: 'error',
              text: newTagCreated.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            }).then(function (result) {
              // setModalTagOpen(false);
              setmodalAddTagOpen(true);
            })
          } 
          setflag(false)
          setTagPage(1)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const handleChangeComplete = (color, event) => {
    console.log("color, event",color, event)
    setaddNewTagDetails({...addNewTagDetails, color:color.hex  })
  }

  const bodyAddTag = (
    <div className={classes.paperModal}>
      <div className={classes.addNewTagHeaderBackgroud}> 
        {
          (EditTagFlag == true)?
          (
            <h2>Edit Tag</h2>
          ):
          (<h2>Create New Tag</h2>)
        }
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>
        <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            // autoComplete="name"
            value={addNewTagDetails.name}
            onChange={(e) => setaddNewTagDetails({...addNewTagDetails, name:e.target.value }) }
          />
          {/* <div className={classes.validation}>{(addNewTagDetails.name)?(<div></div>):(nameErr)}</div> */}
          <div className={classes.validation}>{(nameErr)?(nameErr):(<div></div>)}</div>

          {/* <TextField
            margin="normal"
            required
            fullWidth
            id="shortcode"
            label="Short Code for Tag"
            name="shortcode"
            // autoComplete="shortcode"
            value={addNewTagDetails.shortcode}
            onChange={(e) => setaddNewTagDetails({...addNewTagDetails, shortcode:e.target.value }) }
          />
          <div className={classes.validation}>{(addNewTagDetails.shortcode)?(<div></div>):(shortCodeErr)}</div> */}

          {/* <FormControl className={classes.formControl}>
            <InputLabel id="is_deleted">Is Deleted *</InputLabel>
            <Select
              labelId="is_deleted"
              id="is_deleted"
              value={addNewTagDetails.is_deleted}
              onChange={(e) => setaddNewTagDetails({...addNewTagDetails, is_deleted:e.target.value }) }
            >
              {yesNo.map(item =>
                <MenuItem value={item.value}>{item.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          {/* <div className={classes.validation}>{(addNewTagDetails.is_deleted)?(<div></div>):(issDeleteErr)}</div> */}
          {/* <div className={classes.validation}>{(addNewTagDetails.is_deleted == "" && addNewTagDetails.is_deleted !== false)?(issDeleteErr):(<div></div>)}</div> */}

          <FormControl className={classes.formControl}>
            <InputLabel id="color">Color *</InputLabel>
            <div style={{marginTop: "10%"}}>
            <SketchPicker 
              color={ addNewTagDetails.color }
              onChangeComplete={ (color, event) => handleChangeComplete(color, event) }
            />
            </div>
          </FormControl>
          {/* <div className={classes.validation}>{(addNewTagDetails.color)?(<div></div>):(colorErr)}</div> */}
          <div className={classes.validation}>{(colorErr)?(colorErr):(<div></div>)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitCreateNewTags}
          >
            Submit
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelAddNewTagModalClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  // settings
  const handleToggleSettings = () => {
    setopenSettings(true)
  }

  const handleCloseClickAway = () => {
    setopenSettings(false);
  };

  const handleCloseAfterClick = (event) => {
    setopenSettings(false);
  };

  //Menu Icon Confirm case
  const handleToggleMenuIcon = () => {
    setOpenMenu((prevOpenMenu) => !prevOpenMenu);
  }

  const switchStepConfirmedFunc = () => {
    setOpenMenu(false);
    setShowProcedure(true);
        // to get case steps to switch step
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'case_id': caseForDetailsFromAsync.case_id,
            'device_token': device_token, 'session_id': session_id })
        };
        fetch( process.env.REACT_APP_GET_CASE_STEPS , requestOptions)
        .then((Response) => Response.json())
        .then( async(caseDetailsToSwitchSteps) => {
          console.log("caseDetailsToSwitchStep response",caseDetailsToSwitchSteps);
          setShowProcedure(false);
          if(caseDetailsToSwitchSteps.success == true)
          {
            if(caseDetailsToSwitchSteps.data.case_step_info){
              var joined=[];
              caseDetailsToSwitchSteps.data.case_step_info.map((data) => {
                if (data.is_completed === true || data.is_current == true) 
                {
                  console.log("data is",data)
                  joined = joined.concat(data);
                  console.log("data join : ",joined);
                  setcaseDetailsToSwitchStep( joined )
                }
              })
              
              caseDetailsToSwitchSteps.data.case_step_info.map((f)=>{
                if(f.is_current == true){
                  console.log("this is is current step",f.step_info.name)
                  setselectedStepNameToSwith(f.step_info.name)
                  setSelectedStepNameIdIs(f.step_info.step_id)
                }
              })
            }
            else{
              setcaseDetailsToSwitchStep([]);
            }
            handleSwitchStepConfirmCaseOpen();
          }
          else if(caseDetailsToSwitchStep.success == false){
            setcaseDetailsToSwitchStep([]);
            Swal.fire({
              icon: 'error',
              text: "No Steps To Switch Yet",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
  }

  const handleSwitchStepConfirmCaseOpen = () => {
    setopenSwitchStepConfirmed(true);
  }

  const handleSwitchStepConfirmedModalClose = () => {
    setopenSwitchStepConfirmed(false);
    setswitchStepErr();
  }

  const handleChangeSwichStepRadio = async(e, step_name, step_id) => {
    console.log("e, step_name, step_id is",e, step_name,step_id)
    setselectedStepNameToSwith(step_name)
    setSelectedStepNameIdIs(step_id)

    await setcaseDetailsToSwitchStep(
      caseDetailsToSwitchStep.map((f) => {
          if (f.step_info.step_id === step_id) 
          {
              f.is_current = true
          }
          return f;
      })
    );
  }

  const validateSwitchStepConfirmed = () => {
    let switchStepErr = '';
    if(!selectedStepNameToSwith)  {
      switchStepErr = 'Please Select Step To Switch';
    }
    if(switchStepErr){
      setswitchStepErr(switchStepErr);
      return false;
    }

    return true;
  }

  const handelSubmitSwitchStepConfirmed = () => {
      const isValid = validateSwitchStepConfirmed();
      // if(isValid){
        setShowProcedure(true);
        setValue(1);
        let switchStepObj = ({
          case_id: caseForDetailsFromAsync.case_id,
          step_id: SelectedStepNameIdIs,
          device_token: device_token,
          session_id: session_id,
        });
        console.log("switchStepObj",switchStepObj);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(switchStepObj)
        };
        fetch( process.env.REACT_APP_SWITCH_CASE_STEP , requestOptions)
          .then((Response) => Response.json())
          .then( async(StepSwitechedDetails) => {
            setopenSwitchStepConfirmed(false);
            setShowProcedure(false);
            setValue(0);
            console.log("StepSwitechedDetails",StepSwitechedDetails);
            setflag(false);
            if(StepSwitechedDetails.success == true)
            {
              Swal.fire({
                icon: 'success',
                text: "Step Switched Succesfully!!",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setselectedStepNameToSwith();
              setSelectedStepNameIdIs();
              setswitchStepErr();
              setflag(true);
            }
            else if(StepSwitechedDetails.success == false){
              Swal.fire({
                icon: 'error',
                text: StepSwitechedDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              }).then(function (result) {
                console.log("result of swal",result)
                setopenSwitchStepConfirmed(true);
              })
            }
          })  
          .catch(err => {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
  }

  const bodySwitchStepConfirmed = (
    <div className={classes.paperModalSwitchStep}>
    <div className={classes.SwitchStepHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={11} >
          <h2 style={{marginLeft:'43%'}}>Switch Step</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'4%',}}>
            <CloseIcon onClick={()=>handleSwitchStepConfirmedModalClose()} 
              style={{fontSize: 20, cursor:'pointer'}}
            />
          </Grid>
        </Grid>
    </div>
    <div className={classes.modalBody}>
      {                                
        caseDetailsToSwitchStep && caseDetailsToSwitchStep.map(( item, index ) => (
          <RadioGroup
            aria-label="position"
            name="position"
            value={selectedStepNameToSwith && selectedStepNameToSwith }
            onChange={ (e) => handleChangeSwichStepRadio(e, item.step_info.name, item.step_info.step_id)}
          >   
          <div className={classes.customRadioButton}>
            <FormControlLabel
              value={item.step_info.name}     
              classes={{ label: classes.label }}
              control={<Radio size="small" color="primary" />}
              label={item.step_info.name}    
            />
          </div>
          </RadioGroup>
        ))
      }
      <div className={classes.validation}>{(selectedStepNameToSwith)?(<div></div>):(switchStepErr)}</div>

      <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'2%'}}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handelSubmitSwitchStepConfirmed}
        >
          Switch Step
        </Button>

        <div style={{marginLeft:'2%'}}></div>

        <Button
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handleSwitchStepConfirmedModalClose}
        >
          Cancel
        </Button>
      </div>
    </div>
    </div>
  )

  // Menu icon completed case
  const handleToggleMenuIconCompleted = () => {
    setOpenMenuCompleted((prevOpenMenuCompleted) => !prevOpenMenuCompleted);
  }


  // Menu Icon Confirm Case
  const AssignUserConfirmedFunc = () =>{
    setOpenMenu(false);
    setOpenMenuCreated(false);
    let LogedInUser = JSON.parse(localStorage.getItem('users'));
    let LogedInUserId = LogedInUser.data.user_details.user_id
    console.log("LogedInUserId,LogedInUser",LogedInUserId,LogedInUser);
    // // to get case assigned user 
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id': caseForDetailsFromAsync.case_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_CASE_ASSIGNMENT , requestOptions)
    .then((Response) => Response.json())
    .then( async(assignUserDetails) => {
      console.log("assignUserDetails====",assignUserDetails);
      if(assignUserDetails.success == true)
      {
        if(assignUserDetails.data.case_assignment){
          console.log("assignUserDetails in if",assignUserDetails.data.case_assignment);
          
          await setassignUserDetails(assignUserDetails.data);
          await setselectedUserToAssignId(assignUserDetails.data.case_assignment.owner_id);
          await setselectedUserToAssignName(assignUserDetails.data.case_assignment.owner);
          setassignNewUserDetails({ ...assignNewUserDetails ,
            case_id: caseForDetailsFromAsync.case_id,
            assigned_by_id: LogedInUserId,
            owner_id: assignUserDetails.data.case_assignment.owner_id,
            // comments: assignUserDetails.data.case_assignment.comments,
            comments: "",
          })
          // setswitchRadio(true)

          await setallUsersList(
            allUsersList.map((f)=>{
              if( f.user_id == assignUserDetails.data.case_assignment.owner_id ){
                console.log("user and owner id matched",f.user_id,assignUserDetails.data.case_assignment.owner_id)
                f.userSelected = true
              }
              else{
                console.log("user and owner id not matched",f.user_id,assignUserDetails.data.case_assignment.owner_id)
                f.userSelected = false
              }
              return f;
            })
          )

        }
        else{
          setassignUserDetails([]);
          setselectedUserToAssignId();
          setselectedUserToAssignName("");
          // setswitchRadio(true)
        }
      }
      else if(assignUserDetails.success == false){
        setassignUserDetails([]);
        setselectedUserToAssignId();
        setselectedUserToAssignName("");
        // setswitchRadio(true)
      }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
    handleAssignUserConfirmdeModalOpen();
  }

  const handleAssignUserConfirmdeModalOpen = () => {
    setopenAssignUserModal(true);
  }

  const handleAssignUserConfirmedModalClose = () => {
    setopenAssignUserModal(false);
    setassignNewUserDetails({...assignNewUserDetails, comments:"" })
    setassignUserErr("")
    // setflag(false)
  }

  const handleChangeAssignUserConfirmedRadio = async(e, assign_by_id, assign_by_name) => {

    let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
    let selectedCasecase_id = caseForDetailsFromAsync.case_id;

    let LogedInUser = JSON.parse(localStorage.getItem('users'));
    let LogedInUserId = LogedInUser.data.user_details.user_id
    console.log("LogedInUserId,LogedInUser",LogedInUserId,LogedInUser);
      
    console.log("handleChangeAssignUserRadio",e,assign_by_id,assign_by_name )
    await setselectedUserToAssignId(assign_by_id);
    await setselectedUserToAssignName(assign_by_name);
    setassignNewUserDetails({ ...assignNewUserDetails ,
      case_id: selectedCasecase_id,
      assigned_by_id: LogedInUserId,
      owner_id: assign_by_id,
    })

    await setallUsersList(
      allUsersList.map((f)=>{
        if( f.user_id == assign_by_id ){
          f.userSelected = true
        }
        else{
          f.userSelected = false
        }
        return f;
      })
    )
  }

  const validateAssignUser = () => {
    // let commentErr = '';
    let assignUserErr = '';

    console.log("in validate Assign User",assignNewUserDetails)

    // if( !assignNewUserDetails.comments ){
    //   commentErr= "Please Enter Comment"
    // }
    if( !assignNewUserDetails.owner_id ){
      assignUserErr= "Please Select Owner"
    }

    if( assignUserErr ){
      // setcommentErr(commentErr);
      setassignUserErr(assignUserErr);

      return false;
    }
    return true;
  }

  const handelSubmitAssignUserConfirmed = () => {
    console.log("in handelSubmitAssignUser assignNewUserDetails,selectedUserToAssignId",
        assignNewUserDetails,selectedUserToAssignId);
      
      const isValid = validateAssignUser();
      if(isValid){
        setValue(1);
        setShowProcedure(true);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(assignNewUserDetails)
        };
        return fetch( process.env.REACT_APP_ASSIGN_CASE , requestOptions)
          .then((Response) => Response.json())
          .then(userAssignDetails => {
            setValue(2);
            setShowProcedure(false);
            console.log("userAssignDetails on same page",userAssignDetails);
            if(userAssignDetails.success == true){
              Swal.fire({
                icon: 'success',
                text: "User Assign Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK'
              })
              setopenAssignUserModal(false);
              // setcommentErr("");
              setassignUserErr("");
              setassignNewUserDetails({
                case_id: "",
                owner_id: "",
                assigned_by_id: "",
                comments: "",
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')),
              })
              setallUsersList(
                allUsersList.map((f)=>{
                    f.userSelected = false
                  return f;
                })
              )
              // setselectedUserToAssignId();
              // setselectedUserToAssignName("");
              setflag(false);
            }
            if(userAssignDetails.success == false || userAssignDetails.success == null ){
              setopenAssignUserModal(true);
              Swal.fire({
                icon: 'error',
                text: userAssignDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
      }
  }

  const bodyAssignUserConfirmed  = (
    <div className={classes.paperModalAssigncaseOwner}>
    <div className={classes.addNewBusinessHeaderBackgroud}> 
        {/* <h2>Assign User</h2> */}
        <Grid container item xs={12}>
          <Grid item xs={11} >
          <h2 style={{marginLeft:'33%'}}>Assign Case Owner</h2>
          </Grid>
          {/* <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handelAssignUserMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid> */}
          <Grid item xs={1} style={{marginTop:'4%',}}>
            <CloseIcon onClick={()=>handleAssignUserConfirmedModalClose()} 
            style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid>
    </div>
    <div className={classes.modalBody} style={{position:'absolute'}}>
      <Grid style={{marginTop: '4%'}}>
        <TextField
          id="comments"
          name="comments"
          variant="outlined"
          autoFocus
          fullWidth
          // required
          label="Comments"
          value={assignNewUserDetails.comments}
          onChange={(e) => setassignNewUserDetails({...assignNewUserDetails, comments:e.target.value }) }
        />
      </Grid>
      {/* <div className={classes.validation}>{(assignNewUserDetails.comments)?(<div></div>):(commentErr)}</div> */}

      <Grid style={{marginTop: '6%'}}>
        <Grid className={classes.subHeadingLabelSelectUser} >
          Select Owner
        </Grid>
        {    
          ( switchRadio == true )? 
          (
            allUsersList && allUsersList.map(( item, index ) => (
              console.log("selectedUserToAssignId, selectedUserToAssignName in modal",
              selectedUserToAssignId, selectedUserToAssignName),
              console.log("allUsersList item",item),
              <RadioGroup
                aria-label="position"
                name="position"
                value={item.userSelected == true ? item.name : null}
                onChange={ (e) => handleChangeAssignUserConfirmedRadio(e, item.user_id , item.name)}
              >   
              <div className={classes.customRadioButton}>
                <FormControlLabel
                  value={item.name}     
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label={item.name}    
                />
              </div>
              </RadioGroup>
            ))
          )  
          :(<Grid></Grid>)
        }
        <div className={classes.validation}>{(assignNewUserDetails.owner_id)?(<div></div>):(assignUserErr)}</div>

      </Grid>

      <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'2%'}}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handelSubmitAssignUserConfirmed}
        >
          Assign Owner
        </Button>
        <div style={{marginLeft:'2%'}}></div>
        <Button
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handleAssignUserConfirmedModalClose}
        >
          Cancel
        </Button>
      </div>
    </div>
    </div>
  )

  // Away from pending info
  const AwayFromPendingInfoConfirmFunc = () => {
    let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
    let selectedCasecase_id = caseForDetailsFromAsync.case_id;
        console.log("selectedCasecase_id",selectedCasecase_id);
        let awayFromPendingCaseObj = ({
          case_id: selectedCasecase_id,
          case_status: 'away-from-pending',
          device_token: device_token,
          session_id: session_id,
        })
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: classes.buttonSwalDelete,
            cancelButton: classes.buttonSwalCancel,
          },
          buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          // text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Yes, confirm it',
          cancelButtonText: 'No, cancel',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            setShowProcedure(true);
            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(awayFromPendingCaseObj)
            };
            fetch( process.env.REACT_APP_UPDATE_CASE_STATUS , requestOptions)
              .then((Response) => Response.json())
              .then(casesDetailsUpdated => {
                setShowProcedure(false);
                console.log("casesDetailsUpdated on same page",casesDetailsUpdated);
                if(casesDetailsUpdated.success == true)
                {
                  Swal.fire({
                    icon: 'success',
                    text: "Case Status Updated Succesfully!!",
                    confirmButtonColor: 'primary',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                  setflag(false);
                  caseForDetailsFromAsyncRefresh();
                }
                if(casesDetailsUpdated.success == false){
                  Swal.fire({
                    icon: 'error',
                    text: casesDetailsUpdated.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
                  
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) 
          {
            // swalWithBootstrapButtons.fire(
            //   'Cancelled',
            //   'Your imaginary file is safe :)',
            //   'error'
            // )
          }
        })
  }

  // Payment Info
  const PaymentInfoConfirmFunc = () => {
    setRedirectTrpPaymentInfo(true);
  }

   // close case functions

   const CloseCaseConfirmFunc = () => {
    setOpenCloseCase(true);
    }

   const handleCloseCaseModalClose = () => {
    setOpenCloseCase(false);
    setCloseCaseComment("");
    setCloseCaseCommentErr("");
  }

  const validateClosecCase = () => {
    let CloseCaseCommentErr = '';

    console.log("in validateClosecCase",CloseCaseComment)

    if( CloseCaseComment == "" ){
      CloseCaseCommentErr= "Please Add Comment"
    }

    if( CloseCaseCommentErr ){
      setCloseCaseCommentErr(CloseCaseCommentErr);

      return false;
    }
    return true;
  }

  const handelSubmitCloseCase = () => {
    const isValid = validateClosecCase();
    if(isValid){
      console.log("CloseCaseComment",CloseCaseComment)
      // let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCasecase_id = caseForDetailsFromAsync.case_id;
      console.log("selectedCaseId in submit close case",selectedCasecase_id);
      let rejectCaseObj = ({
        case_id: caseForDetailsFromAsync.case_id,
        case_status: 'close',
        case_alert_message: CloseCaseComment,
        device_token: device_token,
        session_id: session_id,
    })
    setShowProcedure(true);
          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(rejectCaseObj)
          };
          fetch( process.env.REACT_APP_UPDATE_CASE_STATUS , requestOptions)
            .then((Response) => Response.json())
            .then(casesCloseUpdated => {
              setShowProcedure(false);
              console.log("casesCloseUpdated on same page",casesCloseUpdated);
              if(casesCloseUpdated.success == true)
              {
                Swal.fire({
                  icon: 'success',
                  text: "Case is closed",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
                setOpenCloseCase(false);
                setCloseCaseComment("");
                setCloseCaseCommentErr("");
                setflag(false);
                setRedirectToCaseTable(true);
              }
              if(casesCloseUpdated.success == false){
                Swal.fire({
                  icon: 'error',
                  text: casesCloseUpdated.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
          })
          .catch(err => {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
    }
  }

  const bodyCloseCase = (
    <div className={classes.paperModalPendingInfo}>
    <div className={classes.PendingInfoHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={11} >
          <h2 style={{marginLeft:'43%'}}>Close Case</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handleCloseCaseModalClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid>
    </div>
    <div className={classes.modalBody}>
      <Grid style={{ marginTop: '7%' }}>
        <TextField
          id="comment"
          name="comment"
          variant="outlined"
          autoFocus
          fullWidth
          required
          label="Comments"
          value={CloseCaseComment}
          onChange={(e) => setCloseCaseComment(e.target.value) }
        />
      </Grid>
      <div className={classes.validation}>{(CloseCaseComment)?(<div></div>):(CloseCaseCommentErr)}</div>

      <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'3%'}}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handelSubmitCloseCase}
        >
          Submit
        </Button>
        <div style={{marginLeft:'2%'}}></div>
        <Button
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handleCloseCaseModalClose}
        >
          Cancel
        </Button>
      </div>
    </div>
    </div>
)

  // Pending info
  const PendingInfoConfirmFunc = () => {
    setOpenMenu(false);
    console.log("Pending info clicked")
    setOpenPendingInfo(true);
  }

  const handlePendingInfoModalClose = () =>{
    setOpenPendingInfo(false);
    setPendingInfoComment("");
    setpendingInfoCommentErr("");
  }

  const validatePendingInfo = () => {
    let pendingInfoCommentErr = '';

    console.log("in validate Assign User",assignNewUserDetails)

    if( PendingInfoComment == "" ){
      pendingInfoCommentErr= "Please Add Comment"
    }

    if( pendingInfoCommentErr ){
      setpendingInfoCommentErr(pendingInfoCommentErr);

      return false;
    }
    return true;
  }

  const handelSubmitPendingInfo = () => {
    const isValid = validatePendingInfo();
    if(isValid){
      console.log("PendingInfoComment",PendingInfoComment)
      setShowProcedure(true);
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCasecase_id = caseForDetailsFromAsync.case_id;

      let LogedInUser = JSON.parse(localStorage.getItem('users'));
      let LogedInUserId = LogedInUser.data.user_details.user_id
      console.log("LogedInUserId,LogedInUser,selectedCaseIs",LogedInUserId,LogedInUser,selectedCaseIs);
      let rejectCaseObj = ({
        case_id: caseForDetailsFromAsync.case_id,
        case_status: 'pending',
        case_alert_message: PendingInfoComment,
        device_token: device_token,
        session_id: session_id,
      })
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(rejectCaseObj)
      };

      fetch( process.env.REACT_APP_UPDATE_CASE_STATUS , requestOptions)
          .then((Response) => Response.json())
          .then(casesPendingDetailsUpdated => {
            setShowProcedure(false);
            console.log("casesPendingDetailsUpdated on same page",casesPendingDetailsUpdated);
            if(casesPendingDetailsUpdated.success == true)
            {
              Swal.fire({
                icon: 'success',
                text: "Case is in pending state",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setOpenPendingInfo(false);
              setPendingInfoComment("");
              setpendingInfoCommentErr("");
              setflag(false);
              caseForDetailsFromAsyncRefresh();
            }
            if(casesPendingDetailsUpdated.success == false){
              Swal.fire({
                icon: 'error',
                text: casesPendingDetailsUpdated.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
     
    }
  }

  const bodyPendingInfo = (
    <div className={classes.paperModalPendingInfo}>
    <div className={classes.PendingInfoHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={11} >
          <h2 style={{marginLeft:'43%'}}>Pending Info</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handlePendingInfoModalClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid>
    </div>
    <div className={classes.modalBody}>
      <Grid style={{ marginTop: '7%' }}>
        <TextField
          id="comment"
          name="comment"
          variant="outlined"
          autoFocus
          fullWidth
          required
          label="Comments"
          value={PendingInfoComment}
          onChange={(e) => setPendingInfoComment(e.target.value) }
        />
      </Grid>
      <div className={classes.validation}>{(PendingInfoComment)?(<div></div>):(pendingInfoCommentErr)}</div>

      <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'3%'}}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handelSubmitPendingInfo}
        >
          Submit
        </Button>
        <div style={{marginLeft:'2%'}}></div>
        <Button
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handlePendingInfoModalClose}
        >
          Cancel
        </Button>
      </div>
    </div>
    </div>
)

  // Menu Icon Created Case
  const handleToggleMenuIconCreated = () => {
    setOpenMenuCreated((prevOpenMenuCreated) => !prevOpenMenuCreated);
  }

  // switch process
  const handleSwitchProcessModalOpen = () => {
    console.log("in handleSwitchProcessModalOpen");
    setopenSwitchProcess(true);
    setOpenMenuCreated(false);
  }

  const handleSwitchProcessModalClose = () => {
    setopenSwitchProcess(false);
    setswitchProcessErr();
  }

  const handleChangeSwichProcessRadio = async(e, processId, processName) => {
    console.log("handleChangeSwichProcessRadio e, processId,process",e, processId,process);
    await setselectedProcessNameToSwitch(processName);
    await setselectedProcessIdToSwitch(processId)
  }

  const validateSwitchProcess = () => {
    let switchProcessErr = '';
    if(!selectedProcessIdToSwitch)  {
      switchProcessErr = 'Please Select Process To Switch';
    }
    if(switchProcessErr){
      setswitchProcessErr(switchProcessErr);
      return false;
    }

    return true;
  }

  const handelSubmitSwitchProcess = () =>{
    // console.log("selectedProcessIdToSwitch",selectedProcessIdToSwitch);
    // console.log("slectedCaseForDetailsHere",slectedCaseForDetailsHere);
    const isValid = validateSwitchProcess();
    if(isValid){
      setValue(1);
      setShowProcedure(true);
      setopenSwitchProcess(false);
      let switchProcessObj = ({
        case_id: caseForDetailsFromAsync.case_id,
        process_id:selectedProcessIdToSwitch,
        device_token: device_token,
        session_id: session_id,
      });
      // console.log("switchProcessObj",switchProcessObj);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(switchProcessObj)
      };
      fetch( process.env.REACT_APP_SWITCH_PROCESS , requestOptions)
        .then((Response) => Response.json())
        .then( async(processSwitechedDetails) => {
          setValue(0);
          setShowProcedure(false);
          console.log("processSwitechedDetails",processSwitechedDetails);
          if(processSwitechedDetails.success == true)
          {
            Swal.fire({
              icon: 'success',
              text: "Process Switched Succesfully!!",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setCurrentProcessName(selectedProcessNameToSwitch);
            setswitchProcessErr();
            setflag(true);
          }
          else if(processSwitechedDetails.success == false){
            Swal.fire({
              icon: 'error',
              text: processSwitechedDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            }).then(function (result) {
              console.log("result of swal",result)
              setopenSwitchProcess(true);
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }); 
    }
  }

  const bodySwitchProcess = (
    <div className={classes.paperModal}>
    <div className={classes.addNewBusinessHeaderBackgroud}> 
      {/* <h2>Switch Process</h2> */}
      <Grid container item xs={12}>
          <Grid item xs={11} >
          <h2 style={{marginLeft:'38%'}}>Switch Process</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handleSwitchProcessModalClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid>
    </div>
    <div className={classes.modalBody}>
      {                                
        processDetailsByService && processDetailsByService.map(( item, index ) => (
          <RadioGroup
            aria-label="position"
            name="position"
            // value={selectedProcessNameToSwitch}
            value ={ selectedProcessIdToSwitch }
            onChange={ (e) => handleChangeSwichProcessRadio(e, item.process_id , item.name)}
          >   
          <div className={classes.customRadioButton}>
            <FormControlLabel
              value={item.process_id}
              // value={item.name}     
              classes={{ label: classes.label }}
              control={<Radio size="small" color="primary" />}
              label={item.name}    
            />
          </div>
          </RadioGroup>
        ))
      }
      <div className={classes.validation}>{(selectedProcessIdToSwitch)?(<div></div>):(switchProcessErr)}</div>

      <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'2%'}}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handelSubmitSwitchProcess}
        >
          Switch Process
        </Button>
        <div style={{marginLeft:'2%'}}></div>
        <Button
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handleSwitchProcessModalClose}
        >
          Cancel
        </Button>
      </div>
    </div>
    </div>
)

// confirm case
const handleClickConfirmCase = () => {
  setOpenMenuCreated(false);
  let confiremCaseObj = ({
    case_id: caseForDetailsFromAsync.case_id,
    case_status: 'confirm-case',
    device_token: device_token,
    session_id: session_id,
  })
  // console.log("confiremCaseObj",confiremCaseObj)

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: classes.buttonSwalDelete,
      cancelButton: classes.buttonSwalCancel,
    },
    buttonsStyling: false
  })
  swalWithBootstrapButtons.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    confirmButtonText: 'Yes, confirm it',
    cancelButtonText: 'No, cancel',
    reverseButtons: true
  }).then((result) => {

    if (result.value) {
      setValue(1);
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(confiremCaseObj)
      };
      fetch( process.env.REACT_APP_UPDATE_CASE_STATUS , requestOptions)
        .then((Response) => Response.json())
        .then( async(casesDetailsUpdated) => {
          setShowProcedure(false);
          console.log("casesDetailsUpdated on same page",casesDetailsUpdated);
          if(casesDetailsUpdated.success == true)
          {
            
            setShowProcedure(false);
            // setRedirectToCaseTable(true);
              // to fetch case details of confimed case
              const requestOptionsCaseDetails = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({'case_id': caseForDetailsFromAsync.case_id,
                  'device_token': device_token, 'session_id': session_id })
              };
              fetch( process.env.REACT_APP_GET_CASE_DETAILS , requestOptionsCaseDetails)
                .then((Response) => Response.json())
                .then( async(caseDetails) => {
                  console.log("caseDetailson on confirm case details",caseDetails);
                  if(caseDetails.success == true)
                  {
                    console.log("caseDetailson on confirm case details true",caseDetails.data.case_details);
                    
                    await localStorage.setItem('selected_case_for_details', JSON.stringify(caseDetails.data.case_details));
                    await setcaseForDetailsFromAsync(caseDetails.data.case_details)
                    setValue(0);
                    setflag(true);
                  }
                  else if(caseDetails.success == false){
                    await localStorage.setItem('selected_case_for_details', []);
                    await setcaseForDetailsFromAsync([])
                    setValue(0);
                    setflag(true);
                  }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });

            Swal.fire({
              icon: 'success',
              text: "Case Status Updated Succesfully!!",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setflag(false);
          }
          if(casesDetailsUpdated.success == false){
            Swal.fire({
              icon: 'error',
              text: casesDetailsUpdated.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
            
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) 
    {
      // swalWithBootstrapButtons.fire(
      //   'Cancelled',
      //   'Your imaginary file is safe :)',
      //   'error'
      // )
    }
  })
}

  // reject case 
  const handleClickRejectCase = () => {
    console.log("in handleClickRejectCase");
    // let rejectCaseObj = ({
    //   case_id: caseForDetailsFromAsync.case_id,
    //   case_status: 'reject-case',
    //   device_token: device_token,
    //   session_id: session_id,
    // })
    // // console.log("rejectCaseObj",rejectCaseObj)

    // const swalWithBootstrapButtons = Swal.mixin({
    //   customClass: {
    //     confirmButton: classes.buttonSwalDelete,
    //     cancelButton: classes.buttonSwalCancel,
    //   },
    //   buttonsStyling: false
    // })
    // swalWithBootstrapButtons.fire({
    //   title: 'Are you sure?',
    //   text: "The case will be move to draft state, additional information will be provided",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#d33',
    //   confirmButtonText: 'Yes, confirm it',
    //   cancelButtonText: 'No, cancel',
    //   reverseButtons: true
    // }).then((result) => {

    //   if (result.value) {
    //     setShowProcedure(true);
    //     const requestOptions = {
    //       method: 'POST',
    //       headers: authHeader(),
    //       body: JSON.stringify(rejectCaseObj)
    //     };
    //     fetch( process.env.REACT_APP_UPDATE_CASE_STATUS , requestOptions)
    //       .then((Response) => Response.json())
    //       .then(casesRejectDetailsUpdated => {
    //         setShowProcedure(false);
    //         console.log("casesRejectDetailsUpdated on same page",casesRejectDetailsUpdated);
    //         if(casesRejectDetailsUpdated.success == true)
    //         {
    //           Swal.fire({
    //             icon: 'success',
    //             text: "Case reverted to draft",
    //             confirmButtonColor: 'primary',
    //             confirmButtonText: 'OK',
    //             timer: 5000,
    //           })
    //           setflag(false);
    //           setRedirectToCaseTable(true);
    //         }
    //         if(casesRejectDetailsUpdated.success == false){
    //           Swal.fire({
    //             icon: 'error',
    //             text: casesRejectDetailsUpdated.errors,
    //             confirmButtonColor: '#d33',
    //             confirmButtonText: 'OK',
    //             timer: 5000,
    //           })
    //         }
    //     })
    //     .catch(err => {
    //       setShowProcedure(false);
    //       Swal.fire({
    //         icon: 'error',
    //         // text: "Something went wrong",
    //         text: "Server Error. Please try again.",
    //         confirmButtonColor: '#d33',
    //         confirmButtonText: 'OK'
    //       })
    //     });
              
    //   } else if (
    //     /* Read more about handling dismissals below */
    //     result.dismiss === Swal.DismissReason.cancel
    //   ) 
    //   {
    //     // swalWithBootstrapButtons.fire(
    //     //   'Cancelled',
    //     //   'Your imaginary file is safe :)',
    //     //   'error'
    //     // )
    //   }
    // })
    setOpenRejectCase(true);
    setOpenMenuCreated(false);
  }

  const handleRejectCaseModalClose = () => {
    setOpenRejectCase(false);
    setRejectCaseCommentErr("");
    setRejectCaseComment("");
  }

  const validateRejectCase = () => {
    let RejectCaseCommentErr = '';

    console.log("in validate RejectCaseCommentErr",RejectCaseCommentErr)

    if( RejectCaseComment == "" ){
      RejectCaseCommentErr= "Please Add Comment"
    }

    if( RejectCaseCommentErr ){
      setRejectCaseCommentErr(RejectCaseCommentErr);

      return false;
    }
    return true;
  }

  const handelSubmitRejectCase = () => {
    const isValid = validateRejectCase();
    if(isValid){
      console.log("RejectCaseComment",RejectCaseComment)

      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCasecase_id = caseForDetailsFromAsync.case_id;

      let LogedInUser = JSON.parse(localStorage.getItem('users'));
      let LogedInUserId = LogedInUser.data.user_details.user_id
      console.log("LogedInUserId,LogedInUser,selectedCaseIs",LogedInUserId,LogedInUser,selectedCaseIs);
      let rejectCaseObj = ({
        case_id: caseForDetailsFromAsync.case_id,
        case_status: 'reject-case',
        case_alert_message: RejectCaseComment,
        device_token: device_token,
        session_id: session_id,
      })
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(rejectCaseObj)
      };

      fetch( process.env.REACT_APP_UPDATE_CASE_STATUS , requestOptions)
          .then((Response) => Response.json())
          .then(casesRejectDetailsUpdated => {
            setShowProcedure(false);
            console.log("casesRejectDetailsUpdated on same page",casesRejectDetailsUpdated);
            if(casesRejectDetailsUpdated.success == true)
            {
              Swal.fire({
                icon: 'success',
                text: "Case reverted to draft",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setflag(false);
              setOpenRejectCase(false);
              setRedirectToCaseTable(true);
            }
            if(casesRejectDetailsUpdated.success == false){
              Swal.fire({
                icon: 'error',
                text: casesRejectDetailsUpdated.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

      // setOpenRejectCase(false);
      // Swal.fire({
      //   icon: 'success',
      //   text: "Api integration needs to done",
      //   confirmButtonColor: 'primary',
      //   confirmButtonText: 'OK'
      // })
      // setRejectCaseComment("");
      // setRejectCaseCommentErr("");
      // setflag(false);
    }
  }

  const bodyRejectCase = (
    <div className={classes.paperModalPendingInfo}>
    <div className={classes.PendingInfoHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={11} >
          <h2 style={{marginLeft:'43%'}}>Revert to Draft</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handleRejectCaseModalClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid>
    </div>
    <div className={classes.modalBody}>
      <Grid style={{ marginTop: '7%' }}>
        <TextField
          id="comment"
          name="comment"
          variant="outlined"
          autoFocus
          fullWidth
          required
          label="Comments"
          value={RejectCaseComment}
          onChange={(e) => setRejectCaseComment(e.target.value) }
        />
      </Grid>
      <div className={classes.validation}>{(PendingInfoComment)?(<div></div>):(RejectCaseCommentErr)}</div>

      <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'3%'}}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handelSubmitRejectCase}
        >
          Submit
        </Button>
        <div style={{marginLeft:'2%'}}></div>
        <Button
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handleRejectCaseModalClose}
        >
          Cancel
        </Button>
      </div>
    </div>
    </div>
)



  // 
  const handleNavigateToClientDetails = async() => {
    // console.log("handleNavigateToClientDetails is clicked",caseDetailsFromApi)
    // await localStorage.setItem('selected_Client_Showin_Ui', JSON.stringify(caseDetailsFromApi));
    // await localStorage.setItem('navigated_toshow_client_details_from', JSON.stringify("TaxManagemantCaseDetails"));
    // await setRedirectToGetClientDetailsPage(true);
    console.log("handleCellClickOfClientName is clicked",caseDetailsFromApi.client_id)
      // ClientDetails.map(async(itemc,index)=>{
        // console.log("in clientDetails map ", item)
        // if( itemc.client_id == caseDetailsFromApi.client_id ){
          // console.log("item is matched ", itemc, caseDetailsFromApi.client_id)
          // if(itemc.client_state == "REGISTERED" ){
            // await localStorage.setItem('selected_Client_Showin_Ui', JSON.stringify(itemc));
            await localStorage.setItem('selected_Client_Showin_Ui', JSON.stringify(caseDetailsFromApi.client_id));
            await localStorage.setItem('navigated_toshow_client_details_from', JSON.stringify("TaxManagemantCaseDetails"));
            await setRedirectToGetClientDetailsPage(true);
          // }
          // if(itemc.client_state == "INVITED" || itemc.client_state == "CREATED" ){
          //   await localStorage.setItem('selected_Client_To_Edit_Self_Details', JSON.stringify(itemc));
          //     await localStorage.setItem('navigated_to_edit_self_client_details', JSON.stringify("TaxManagemantCaseDetails"));
          //     await setRedirectToEditClientDetails(true);
          // }
          // else{
          //   Swal.fire({
          //   // icon: 'error',
          //     text: "Client is not Registered yet",
          //     confirmButtonColor: '#d33',
          //     confirmButtonText: 'OK'
          //   }) 
          // }
        // }
      // })
  }

  const handlefromCaseOverviewCompleteCase = () => {
    console.log("handlefromCaseOverviewCompleteCase /7/&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
    const requestOptionsCaseDetails = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id': caseForDetailsFromAsync.case_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_CASE_DETAILS , requestOptionsCaseDetails)
      .then((Response) => Response.json())
      .then( async(caseDetails) => {
        console.log("caseDetailson on confirm case details",caseDetails);
        if(caseDetails.success == true)
        {
          console.log("caseDetailson on confirm case details true",caseDetails.data.case_details);
          
          await localStorage.setItem('selected_case_for_details', JSON.stringify(caseDetails.data.case_details));
          await setcaseForDetailsFromAsync(caseDetails.data.case_details)
          setValue(0);
          setflag(false);
        }
        else if(caseDetails.success == false){
          await localStorage.setItem('selected_case_for_details', []);
          await setcaseForDetailsFromAsync([])
          setflag(false);
        }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }

  const createdDateFunc = (date) => {
    console.log("date in created DateFunc of case details page is", date)

    let dateInString = JSON.stringify(date)
    console.log("date in straing is ", dateInString)
    var mydateIs = moment(dateInString, 'DD/MM/YYYY hh:mm A');
    console.log("formated date is ours",moment(mydateIs).format("DD/MMM/YYYY hh:mm A"))

    var Created_DateOnly_in_format= moment(mydateIs).format("DD/MMM/YYYY");
    console.log("Created_DateOnly_in_format",Created_DateOnly_in_format)

    return(
      <>
        {Created_DateOnly_in_format}
      </>
    )
  }

  const handleChangeBackButton = () => {
    console.log("its handleChangeBack Button on case details")
  }

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  console.log("role_of_user, caseForDetailsFromAsync.case_curr_state on case details",role_of_user,caseForDetailsFromAsync.case_curr_state)

  if( RedirectTrpPaymentInfo == true ){
    return <Redirect to={`/TrpsPaymentInfo`} />
  }
  if( RedirectToCaseTable == true ){
    return <Redirect to={`/cases`} />
  }
  if(RedirectToGetClientDetailsPage == true){
    return <Redirect to={`/TaxManagementClientDetailsUI`} />
  }
  if(RedirectToEditClientDetails == true){
    return <Redirect to={`/EditClientSelf`} />
  }
  if(role_of_user == "client" || role_of_user== "trp"){
    return (
      console.log("caseForDetailsFromAsync,caseDetailsShowFromRedux",caseForDetailsFromAsync,caseDetailsShowFromRedux),
      console.log(",caseDetailsFromApi,casePriorityIs,",
        caseDetailsFromApi,casePriorityIs),
      //   console.log(" allTagDetails", allTagDetails),
      <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
         <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </modal>: null}
      <div className={classes.wrapper}>
        <Grid container>
          <Grid item xs={3} className={classes.casesDetailInfo}>
            <Grid container>
              <Grid item xs={12}>
                {
                  (navigatedFrom && navigatedFrom == "Cases")?
                  (
                    <Button href="/cases" onClick={handleChangeBackButton()} className={classes.backButton}>
                      <span className={classes.backArrow}>
                        <ArrowBackIcon fontSize="small" />
                      </span>
                      <FormattedMessage id="backLabel" />
                    </Button>
                  ):
                  (
                    ( navigatedFrom && navigatedFrom == "SearchResult" )? 
                    (
                      <Button href="/SearchResult" onClick={handleChange} className={classes.backButton}>
                        <span className={classes.backArrow}>
                          <ArrowBackIcon fontSize="small" />
                        </span>
                        <FormattedMessage id="backLabel" />
                      </Button>
                    ):
                    (<div></div>)
                  )
                }             
              </Grid>
              <Grid item xs={12} className={classes.casesNameInfo}>
                {/* TF123 */}
                Case Name : {caseDetailsFromApi && caseDetailsFromApi.name}
              </Grid>
              <Grid container item xs={12}>
                  {/* Description - {caseDetailsFromApi && caseDetailsFromApi.description} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Description : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.description}
                  </text>
                </Grid>
                <Grid container>
                  {/* Service - {caseDetailsFromApi && caseDetailsFromApi.service} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Service : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.service}
                  </text>
                </Grid>
                <Grid container>
                  {/* Process - {selec} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Process : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{currentProcessName}
                  </text>
                </Grid>
                <Grid container>
                  {/* Assesment Year - {caseDetailsFromApi && caseDetailsFromApi.assessment_year} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Assesment Year : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.assessment_year}
                  </text>
                </Grid>
                <Grid container>
                  {/* Created Date - {caseDetailsFromApi && caseDetailsFromApi.created_date} */}
                  {/* Created Date - {caseForDetailsFromAsync && caseForDetailsFromAsync.created_date} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Created Date : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    {/* &nbsp;{caseDetailsFromApi && caseDetailsFromApi.created_date} */}
                    &nbsp;{createdDateFunc(caseDetailsFromApi && caseDetailsFromApi.created_date)}
                  </text>
                </Grid>
              <Grid container >
                <text style={{fontSize: 15, fontWeight: 'bold'}}>Case Status : </text>
                <text style={{fontSize: 15, fontWeight: 400, color:'green'}}>&nbsp;{caseForDetailsFromAsync && caseForDetailsFromAsync.case_curr_state}</text>
              </Grid>
              <Grid container >
                <text style={{fontSize: 15, fontWeight: 'bold'}}>Assigned : </text>
                <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>&nbsp;{caseDetailsFromApi && caseDetailsFromApi.owner ? caseDetailsFromApi.owner.owner : null}</text>
              </Grid>

                {
                  ( caseAlertMessageDetailsFromApi &&  caseAlertMessageDetailsFromApi !== undefined )?
                  (
                    <Grid item xs={12}>
                      {
                        ( caseAlertMessageDetailsFromApi.curr_state == "PENDING" )?
                        (
                          <Grid>
                            <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                              <Divider />
                            </Grid>
                            <Grid container item xs={12} >
                              <Grid className={classes.CaseRejectedLable}>
                                Case Pending:&nbsp;
                              </Grid>
                              <Grid className={classes.CaseRejectedComment}>
                                {caseAlertMessageDetailsFromApi && caseAlertMessageDetailsFromApi.message}
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                        :(<Grid></Grid>)
                      }                    
                    </Grid>
                  ):
                  (<Grid></Grid>)
                } 

              {
                ( caseDetailsFromApi && caseDetailsFromApi.case_payment == null ) ?
                (
                  <Grid></Grid>
                ):
                (
                  
                  <Grid>
                    {/* it's client  */}
                    {/* {user_permissions && user_permissions.permissions.add_payment_details && */}
                      <Grid>
                        <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                          <Divider />
                        </Grid>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.submitPaymentDetails}
                          href="/ClientsPaymentInfo"
                        >
                          Payment Details
                        </Button>
                      </Grid>
                  {/* } */}
                  </Grid>

                )
              }
              
              <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                <Divider />
              </Grid>
             
              {/* <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                <Divider />
              </Grid> */}

              {/* <Grid container item xs={12} className={classes.casesetPriorityInfo}>
                {
                    ( casePriorityIs == "High")?(
                      <Grid>
                        <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                        <text style={{fontSize: 15, fontWeight: 'bold', color:'red'}}>{casePriorityIs && casePriorityIs}</text>
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  }
                  {
                    ( casePriorityIs == "Medium")?(
                      <Grid>
                        <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                        <text style={{fontSize: 15, fontWeight: 'bold', color:'blue'}}>{casePriorityIs && casePriorityIs}</text>
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  }
                  {
                    ( casePriorityIs == "Low")?(
                      <Grid>
                        <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                        <text style={{fontSize: 15, fontWeight: 'bold', color:'green'}}>{casePriorityIs && casePriorityIs}</text>
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  }
                {/* <Grid style={{fontSize: 15, fontWeight: 'bold'}}>Priority: {casePriorityIs && casePriorityIs} </Grid>
                &nbsp;&nbsp;&nbsp;&nbsp; */}
                 {/* &nbsp;&nbsp;&nbsp;&nbsp;
              </Grid> */}
              {/* <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                <Divider />
              </Grid> */}
              {/* <Grid item xs={12} className={classes.caseClientNameInfo} style={{fontWeight:'bold'}}>
                Client Name and PAN:
              </Grid>
              <Grid item xs={12} className={classes.caseClientNameInfoLowMarginTop}>
                {caseDetailsFromApi && caseDetailsFromApi.client_name}
              </Grid>
              <Grid item xs={12} className={classes.casePanCardInfo}>
                {caseDetailsFromApi && caseDetailsFromApi.client_pan}
              </Grid> */}
              <Grid item xs={12} container className={classes.caseClientNameInfo} >
                {/* SSE Name : {caseDetailsFromApi && caseDetailsFromApi.client_name} */}
                <Grid style={{fontWeight:'bold'}}> Client Name : </Grid> 
                <Grid style={{color:'blue', cursor:'pointer'}}
                  onClick={() => handleNavigateToClientDetails()}
                > 
                  &nbsp; {caseDetailsFromApi && caseDetailsFromApi.client_name} 
                </Grid>
              </Grid>

              <Grid item xs={12} container className={classes.caseClientNameInfoLowMarginTop}>
                {/* SSE PAN : {caseDetailsFromApi && caseDetailsFromApi.client_pan} */}
                <Grid style={{fontWeight:'bold'}}> Client PAN : </Grid> 
                <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.client_pan} </Grid>
              </Grid>
              <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                <Divider />
              </Grid>
              {
                ( caseDetailsFromApi.self_assessment == false)?
                (
                  // <Grid>
                  //   <Grid item xs={12} className={classes.caseClientNameInfo} style={{fontWeight:'bold'}}>
                  //     {/* ASSESSE Name and PAN: */}
                  //     Tax Payer Name and PAN:
                  //   </Grid>
                  //   <Grid item xs={12} className={classes.caseClientNameInfoLowMarginTop}>
                  //     {/* John Kumar */}
                  //     {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name}
                  //   </Grid>
                  //   <Grid item xs={12} className={classes.casePanCardInfo}>
                  //     {/* ABCDE1234G */}
                  //     {caseDetailsFromApi && caseDetailsFromApi.for_assesse_pan}
                  //   </Grid>
                  // </Grid>
                  <Grid>
                    <Grid item xs={12} container className={classes.caseClientNameInfo}>
                      <Grid style={{fontWeight:'bold'}}> Tax Payer Name : </Grid> 
                      {/* <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name} </Grid> */}
                      <Grid style={{color:'blue', cursor:'pointer'}}
                        onClick={() => handleNavigateToClientDetails()}
                      > 
                        &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name} 
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container className={classes.caseClientNameInfoLowMarginTop}>
                      <Grid style={{fontWeight:'bold'}}> Tax Payer PAN : </Grid> 
                      <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_pan} </Grid>
                    </Grid>
                    <Grid style={{paddingTop:8, paddingBottom: 8, width:'119%'}}>
                      <Divider />
                    </Grid>
                  </Grid>
                  
                )
                :(<div></div>)
              }
              
              {/* <Grid item xs={12} className={classes.casePhoneNumberInfo}>
                <span className={classes.phoneIcon}>
                  <LocalPhoneIcon />
                </span>{" "}
                Call
              </Grid>
              <Grid item xs={12} className={classes.caseMessageInfo}>
                <span className={classes.messageIcon}>
                  <MessageIcon />
                </span>{" "}
                Messages
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid> */}
              
              {/* tags */}
              <Grid item xs={12} style={{fontWeight:'bold'}}> Tags : </Grid>
              <Grid container>
                {
                  caseTagDetails && caseTagDetails.map((e,i) => (
                    // <Grid style={{ color: e.tag_color}}>{e.tag_name}</Grid>
                    // <Grid style={{marginTop:"1%"}}>
                    //   <Grid style={{ backgroundColor: e.tag_color, padding: 5, fontWeight:'bold'}}>{e.tag_name}
                    //       { CaseTagDetailsLength-1 == i ? null : "," }
                    //   </Grid>
                    // </Grid>
                    <Grid style={{marginTop:"0.5%"}}>
                      <Grid  style={{ color: e.tag_color, padding: 3, fontWeight:'bold'}}>{e.tag_name}
                        { CaseTagDetailsLength-1 == i ? null : "," }
                      </Grid>
                    </Grid>
                  ))
                }
              </Grid>
  
              {/* <Grid item xs={12} className={classes.caseAddTagInfo}>
                <Button
                  className={classes.addButtonStyle}
                  onClick={handleModalOpen}
                  startIcon={<AddIcon />}
                >
                  <FormattedMessage id="addMoreLabel" />
                </Button>
              </Grid> */}
              <Grid item xs={12} className={classes.caseAddTagInfo}></Grid>
              {/* <Modal
                open={modalTagOpen}
                onClose={handleModalClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {body}
              </Modal>
  
              <Modal
                open={modalAddTagOpen}
                onClose={handelAddNewTagModalClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {bodyAddTag}
              </Modal> */}
  
  
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Paper elevation={0} className={classes.customPaperStyle}>
              <div className={classes.casesDetailSubMenu}>
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    className={classes.tabCustomStyle}
                    label={intl.formatMessage({ id: "overviewLink" })}
                  />                
                  <Tab
                    className={classes.tabCustomStyle}
                    label={intl.formatMessage({ id: "activityLink" })}
                  />
                  <Tab
                    className={classes.tabCustomStyle}
                    label={intl.formatMessage({ id: "messagesLink" })}
                  />
                  <Tab
                    className={classes.tabCustomStyle}
                    label={intl.formatMessage({ id: "documentsLink" })}
                  />
                  <Tab
                    className={classes.tabCustomStyle}
                    label="Q and A"
                  />
                </Tabs>
              </div>
              <TabPanel value={value} index={0}>
                <TaxManagementCasesOverView fromCaseOverviewCompleteCase={handlefromCaseOverviewCompleteCase}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TaxManagementCasesActivity />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TaxManagementCasesMessages />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <TaxManagementCasesDocuments />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <TaxManagementCasesQandA caseQuestionAnswers={caseQuestionAnswers}/>
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
        
      </div>
      </div>
    );
  }

  // else if(role_of_user == "trpadmin" || role_of_user== "trpdoc"){
  else {
    console.log("navigatedFrom, caseForDetailsFromAsync 0000000000000000",navigatedFrom,caseForDetailsFromAsync)
    if(caseForDetailsFromAsync.case_curr_state == "CREATED" ){
      console.log("case status is CREATED")
      return (
        console.log("caseForDetailsFromAsync,caseDetailsShowFromRedux",caseForDetailsFromAsync,caseDetailsShowFromRedux),
        // console.log(",caseDetailsFromApi,casePriorityIs,",
        //   caseDetailsFromApi,casePriorityIs),
        //   console.log(" allTagDetails", allTagDetails),
        <div className={classes.root}>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
           <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        <div className={classes.wrapper}>
          <Grid container>
            <Grid item xs={3} className={classes.casesDetailInfo}>
              <Grid container>
                <Grid container>
                  <Grid item xs={9}>
                    {
                      (navigatedFrom && navigatedFrom == "Cases")?
                      (
                        <Button href="/cases" onClick={handleChange} className={classes.backButton}>
                          <span className={classes.backArrow}>
                            <ArrowBackIcon fontSize="small" />
                          </span>
                          <FormattedMessage id="backLabel" />
                        </Button>
                      ):
                      (
                        ( navigatedFrom && navigatedFrom == "SearchResult" )? 
                        (
                          <Button href="/SearchResult" onClick={handleChange} className={classes.backButton}>
                            <span className={classes.backArrow}>
                              <ArrowBackIcon fontSize="small" />
                            </span>
                            <FormattedMessage id="backLabel" />
                          </Button>
                        ):
                        (<div></div>)
                      )
                    }
                  </Grid>
                  {/* <Grid item xs={1} style={{cursor:'pointer'}} onClick={handleToggleMenuIconCreated}  
                    id="MenuSettingsCreated">
                    <MenuIcon />  
                  </Grid> */}
                  <Grid container item xs={3}> 
                    <Button id="MenuSettingsCreated" onClick={handleToggleMenuIconCreated} className={classes.backButton}>
                      <FormattedMessage id="menuLabel" />
                      <span className={classes.backArrow}>
                        <MenuIcon />
                      </span>
                    </Button>
                  </Grid>
                  <div>
                    <Popover placement="bottom" trigger="legacy" target="MenuSettingsCreated"
                      isOpen={OpenMenuCreated} 
                      toggle={handleToggleMenuIconCreated}
                      style={{ backgroundColor: "white", color: "white", width: "110%", marginLeft: "-69%",
                               padding: 10, marginTop:'-2%' }}
                    >
                      <PopoverBody>
                      {user_permissions && user_permissions.permissions.switch_process &&
                        <div>
                          <text style={{cursor: 'pointer', color: "black", padding: 10, marginLeft: "17%"}}  
                            onClick={() => handleSwitchProcessModalOpen()}
                          >
                            Switch Process
                          </text>
                          <hr/>
                        </div>
                      }

                        {user_permissions && user_permissions.permissions.assign_user_to_case &&
                          <div>
                            <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "8%"}}  
                              onClick={() => AssignUserConfirmedFunc()}
                            >
                              {/* Assign User */}
                              Assign Case Owner
                            </text> 
                            <hr/>
                          </div>
                        }

                        {user_permissions && user_permissions.permissions.confirm_case &&
                          <div>
                            <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "18%"}}  
                              onClick={() => handleClickConfirmCase()}
                            >
                              Confirm Case
                            </text> 
                            <hr/>
                          </div>
                        }

                        <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "18%"}}  
                          onClick={() => handleClickRejectCase()}
                        >
                          {/* Reject Case */}
                          Revert to draft
                        </text>
                      </PopoverBody>
                    </Popover>
                  </div>
                </Grid>
                <Grid item xs={12} className={classes.casesNameInfo}>
                  {/* TF123 */}
                  Case Name : {caseDetailsFromApi && caseDetailsFromApi.name}
                </Grid>
                <Grid container item xs={12}>
                  {/* Description - {caseDetailsFromApi && caseDetailsFromApi.description} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Description : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.description}
                  </text>
                </Grid>
                <Grid container>
                  {/* Service - {caseDetailsFromApi && caseDetailsFromApi.service} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Service : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.service}
                  </text>
                </Grid>
                <Grid container>
                  {/* Process - {selec} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Process : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{currentProcessName}
                  </text>
                </Grid>
                <Grid container>
                  {/* Assesment Year - {caseDetailsFromApi && caseDetailsFromApi.assessment_year} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Assesment Year : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.assessment_year}
                  </text>
                </Grid>
                <Grid container>
                  {/* Created Date - {caseDetailsFromApi && caseDetailsFromApi.created_date} */}
                  {/* Created Date - {caseForDetailsFromAsync && caseForDetailsFromAsync.created_date} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Created Date : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    {/* &nbsp;{caseDetailsFromApi && caseDetailsFromApi.created_date} */}
                    &nbsp;{createdDateFunc(caseDetailsFromApi && caseDetailsFromApi.created_date)}
                  </text>
                </Grid>
                <Grid container >
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Case Status : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'green'}}>&nbsp;{caseForDetailsFromAsync && caseForDetailsFromAsync.case_curr_state}</text>
                </Grid>
                <Grid container >
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Assigned : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>&nbsp;{caseDetailsFromApi && caseDetailsFromApi.owner ? caseDetailsFromApi.owner.owner : null}</text>
                </Grid>
                

                <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid>
               
                {/* <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid> */}

                <Grid container item xs={12} className={classes.casesetPriorityInfo}>
                  {/* {
                    ( casePriorityIs == "High")?(
                      <Grid>
                        <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                        <text style={{fontSize: 15, fontWeight: 'bold', color:'red'}}>{casePriorityIs && casePriorityIs}</text>
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  }
                  {
                    ( casePriorityIs == "Medium")?(
                      <Grid>
                        <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                        <text style={{fontSize: 15, fontWeight: 'bold', color:'blue'}}>{casePriorityIs && casePriorityIs}</text>
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  }
                  {
                    ( casePriorityIs == "Low")?(
                      <Grid>
                        <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                        <text style={{fontSize: 15, fontWeight: 'bold', color:'green'}}>{casePriorityIs && casePriorityIs}</text>
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  } */}
                   <Grid>
                    <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                    <text style={{fontSize: 15, fontWeight: 'bold', color:'black'}}>{casePriorityIs && casePriorityIs}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</text>
                  </Grid>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {/* <Button
                    className={classes.setPriorityButton}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    id="setPriorityLabel"
                    // onClick={handleToggle}
                  >
                    Change Priority
                    <span className={classes.verticalAlignTop}>
                      <ExpandMoreIcon />
                    </span>
                  </Button> */}
                  {/* <div>
                    <Popover placement="bottom" trigger="legacy" target="setPriorityLabel"
                      isOpen={open} 
                      toggle={handleToggle}
                      style={{ backgroundColor: "white", color: "white", width: "140%", marginLeft: "-20%",
                               padding: 10 }}
                    >
                      <PopoverBody>
                        {
                          (casePriorityIs == "High")?
                          (
                            <text style={{cursor: 'pointer', color: "blue", padding: 10, marginLeft: "25%"}}  
                              onClick={(e) => handleChangePriority("High")}
                            >
                              High
                            </text>
                          )
                          :(
                            <text style={{cursor: 'pointer', color: "black", padding: 10, marginLeft: "25%"}}  
                              onClick={(e) => handleChangePriority("High")}
                            >
                              High
                            </text>
                          )
                        }                  
                        <hr/>
                        {
                          (casePriorityIs == "Low")?
                          (
                             <text style={{cursor: 'pointer',color: "blue", padding: 10, marginLeft: "25%"}}  
                                onClick={(e) => handleChangePriority("Low")}
                              >
                                Low
                              </text>
                          )
                          :(
                             <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "25%"}}  
                                onClick={(e) => handleChangePriority("Low")}
                              >
                                Low
                              </text>
                          )
                        }
                        <hr/>
                        {
                          (casePriorityIs == "Medium")?
                          (
                             <text style={{cursor: 'pointer',color: "blue", padding: 10, marginLeft: "17%" }}  
                                onClick={(e) => handleChangePriority("Medium")}
                              >
                                Medium
                              </text>
                          )
                          :(
                             <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "17%" }}  
                                onClick={(e) => handleChangePriority("Medium")}
                              >
                                Medium
                              </text>
                          )
                        }
                        
                      </PopoverBody>
                    </Popover>
                  </div> */}
                </Grid>
                {/* <Grid item xs={12} className={classes.caseClientNameInfo} style={{fontWeight:'bold'}}>
                  Client Name and PAN:
                </Grid>
                <Grid item xs={12} className={classes.caseClientNameInfoLowMarginTop}>
                  {caseDetailsFromApi && caseDetailsFromApi.client_name}
                </Grid>
                <Grid item xs={12} className={classes.casePanCardInfo}>
                  {caseDetailsFromApi && caseDetailsFromApi.client_pan}
                </Grid> */}
                <Grid item xs={12} container className={classes.caseClientNameInfo} >
                  {/* SSE Name : {caseDetailsFromApi && caseDetailsFromApi.client_name} */}
                  <Grid style={{fontWeight:'bold'}}> Client Name : </Grid> 
                  <Grid style={{color:'blue', cursor:'pointer'}}
                    onClick={() => handleNavigateToClientDetails()}
                  > 
                    &nbsp; {caseDetailsFromApi && caseDetailsFromApi.client_name} 
                  </Grid>
                  
                </Grid>

                <Grid item xs={12} container className={classes.caseClientNameInfoLowMarginTop}>
                  {/* SSE PAN : {caseDetailsFromApi && caseDetailsFromApi.client_pan} */}
                  <Grid style={{fontWeight:'bold'}}> Client PAN : </Grid> 
                  <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.client_pan} </Grid>
                </Grid>

                <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid>
                {
                  ( caseDetailsFromApi.self_assessment == false)?
                  (
                    // <Grid>
                    //   <Grid item xs={12} className={classes.caseClientNameInfo} style={{fontWeight:'bold'}}>
                    //     {/* ASSESSE Name and PAN: */}
                    //     Tax Payer Name and PAN:
                    //   </Grid>
                    //   <Grid item xs={12} className={classes.caseClientNameInfoLowMarginTop}>
                    //     {/* John Kumar */}
                    //     {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name}
                    //   </Grid>
                    //   <Grid item xs={12} className={classes.casePanCardInfo}>
                    //     {/* ABCDE1234G */}
                    //     {caseDetailsFromApi && caseDetailsFromApi.for_assesse_pan}
                    //   </Grid>
                    // </Grid>
                    <Grid>
                      <Grid item xs={12} container className={classes.caseClientNameInfo}>
                        <Grid style={{fontWeight:'bold'}}> Tax Payer Name : </Grid> 
                        {/* <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name} </Grid> */}
                        <Grid style={{color:'blue', cursor:'pointer'}}
                          onClick={() => handleNavigateToClientDetails()}
                        > 
                          &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name} 
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container className={classes.caseClientNameInfoLowMarginTop}>
                        <Grid style={{fontWeight:'bold'}}> Tax Payer PAN : </Grid> 
                        <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_pan} </Grid>
                      </Grid>
                      <Grid style={{paddingTop:8, paddingBottom: 8, width:'119%'}}>
                        <Divider />
                      </Grid>
                    </Grid>
                  )
                  :(<div></div>)
                }
                
                {/* <Grid item xs={12} className={classes.casePhoneNumberInfo}>
                  <span className={classes.phoneIcon}>
                    <LocalPhoneIcon />
                  </span>{" "}
                  Call
                </Grid>
                <Grid item xs={12} className={classes.caseMessageInfo}>
                  <span className={classes.messageIcon}>
                    <MessageIcon />
                  </span>{" "}
                  Messages
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid> */}
                
                {/* tags */}
                <Grid item xs={12} style={{fontWeight:'bold'}}> Tags : </Grid>
                <Grid container>
                  {
                    caseTagDetails && caseTagDetails.map((e,i) => (
                      // <Grid style={{ color: e.tag_color}}>{e.tag_name}</Grid>
                      // <Grid style={{marginTop:"1%"}}>
                      //   <Grid style={{ backgroundColor: e.tag_color, padding: 5, fontWeight:'bold'}}>{e.tag_name}
                      //     { CaseTagDetailsLength-1 == i ? null : "," }
                      //   </Grid>
                      // </Grid>
                      <Grid style={{marginTop:"0.5%"}}>
                        <Grid  style={{ color: e.tag_color, padding: 3, fontWeight:'bold'}}>{e.tag_name}
                          { CaseTagDetailsLength-1 == i ? null : "," }
                        </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
    
                {/* <Grid item xs={12} className={classes.caseAddTagInfo}>
                  <Button
                    className={classes.addButtonStyle}
                    onClick={handleModalOpen}
                    startIcon={<AddIcon />}
                  >
                    <FormattedMessage id="addMoreLabel" />
                  </Button>
                </Grid> */}
                <Grid item xs={12} className={classes.caseAddTagInfo}></Grid>
                {/* <Modal
                  open={modalTagOpen}
                  onClose={handleModalClose}
                  className={classes.modal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {body}
                </Modal>
    
                <Modal
                  open={modalAddTagOpen}
                  onClose={handelAddNewTagModalClose}
                  className={classes.modal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {bodyAddTag}
                </Modal> */}
    
    
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Paper elevation={0} className={classes.customPaperStyle}>
                <div className={classes.casesDetailSubMenu}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "overviewLink" })}
                    /> 
                    <Tab
                      className={classes.tabCustomStyle}
                      label="Notes"
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "activityLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "messagesLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "documentsLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label="Q and A"
                    />
                    
                  </Tabs>
                </div>
                <TabPanel value={value} index={0}>
                  <TaxManagementCasesOverView fromCaseOverviewCompleteCase={handlefromCaseOverviewCompleteCase}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <TaxManagementTrpNotes/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <TaxManagementCasesActivity />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <TaxManagementCasesMessages />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <TaxManagementCasesDocuments />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <TaxManagementCasesQandA caseQuestionAnswers={caseQuestionAnswers}/>
                </TabPanel>
                
              </Paper>
            </Grid>
          </Grid>
          
        </div>
          {/* Assign User same for both created and confirm/In progress case status */}
          <Modal
            open={openAssignUserModal}
            onClose={handleAssignUserConfirmedModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAssignUserConfirmed}
          </Modal>
          {/* switch process */}
          <Modal
            open={openSwitchProcess}
            onClose={handleSwitchProcessModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodySwitchProcess}
          </Modal> 
          {/* Reject case */}
          <Modal
            open={OpenRejectCase}
            onClose={handleRejectCaseModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyRejectCase}
          </Modal> 
        </div>
      );
    }
    else if(caseForDetailsFromAsync.case_curr_state == "COMPLETED" ){
      console.log("case status is COMPLETED")
      return (
        console.log("caseForDetailsFromAsync,caseDetailsShowFromRedux",caseForDetailsFromAsync,caseDetailsShowFromRedux),
        console.log(",caseDetailsFromApi,casePriorityIs,",
          caseDetailsFromApi,casePriorityIs),
        //   console.log(" allTagDetails", allTagDetails),
        <div className={classes.root}>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
           <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        <div className={classes.wrapper}>
          <Grid container>
            <Grid item xs={3} className={classes.casesDetailInfo}>
              <Grid container>
                
               <Grid container>
                  <Grid item xs={9}>
                    {
                      (navigatedFrom && navigatedFrom == "Cases")?
                      (
                        <Button href="/cases" onClick={handleChange} className={classes.backButton}>
                          <span className={classes.backArrow}>
                            <ArrowBackIcon fontSize="small" />
                          </span>
                          <FormattedMessage id="backLabel" />
                        </Button>
                      ):
                      (
                        ( navigatedFrom && navigatedFrom == "SearchResult" )? 
                        (
                          <Button href="/SearchResult" onClick={handleChange} className={classes.backButton}>
                            <span className={classes.backArrow}>
                              <ArrowBackIcon fontSize="small" />
                            </span>
                            <FormattedMessage id="backLabel" />
                          </Button>
                        ):
                        (<div></div>)
                      )
                    }             
                  </Grid>

                  {user_permissions && user_permissions.permissions.close_case &&
                    <Grid>
                      <Grid container item xs={3}> 
                        <Button id="MenuSettingsCompleted" onClick={handleToggleMenuIconCompleted} className={classes.backButton}>
                          <FormattedMessage id="menuLabel" />
                          <span className={classes.backArrow}>
                            <MenuIcon />
                          </span>
                        </Button>
                      </Grid>
                      <div>
                        <Popover placement="bottom" trigger="legacy" target="MenuSettingsCompleted"
                          isOpen={OpenMenuCompleted} 
                          toggle={handleToggleMenuIconCompleted}
                          style={{ backgroundColor: "white", color: "white", width: "110%", marginLeft: "-69%",
                                  padding: 10, marginTop:'-2%' }}
                        >
                          <PopoverBody>
                            <text style={{cursor: 'pointer', color: "black", padding: 10, marginLeft: "1%"}}  
                              onClick={() => CloseCaseConfirmFunc()}
                            >
                              Close Case
                            </text>
                          </PopoverBody>
                        </Popover>
                      </div>
                    </Grid>
                  }


                </Grid>

                <Grid item xs={12} className={classes.casesNameInfo}>
                  {/* TF123 */}
                  Case Name : {caseDetailsFromApi && caseDetailsFromApi.name}
                </Grid>
                <Grid container item xs={12}>
                  {/* Description - {caseDetailsFromApi && caseDetailsFromApi.description} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Description : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.description}
                  </text>
                </Grid>
                <Grid container>
                  {/* Service - {caseDetailsFromApi && caseDetailsFromApi.service} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Service : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.service}
                  </text>
                </Grid>
                <Grid container>
                  {/* Process - {selec} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Process : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{currentProcessName}
                  </text>
                </Grid>
                <Grid container>
                  {/* Assesment Year - {caseDetailsFromApi && caseDetailsFromApi.assessment_year} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Assesment Year : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.assessment_year}
                  </text>
                </Grid>
                <Grid container>
                  {/* Created Date - {caseDetailsFromApi && caseDetailsFromApi.created_date} */}
                  {/* Created Date - {caseForDetailsFromAsync && caseForDetailsFromAsync.created_date} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Created Date : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    {/* &nbsp;{caseDetailsFromApi && caseDetailsFromApi.created_date} */}
                    &nbsp;{createdDateFunc(caseDetailsFromApi && caseDetailsFromApi.created_date)}
                  </text>
                </Grid>
                <Grid container >
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Case Status : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'green'}}>&nbsp;{caseForDetailsFromAsync && caseForDetailsFromAsync.case_curr_state}</text>
                </Grid>
                <Grid container >
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Assigned : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>&nbsp;{caseDetailsFromApi && caseDetailsFromApi.owner ? caseDetailsFromApi.owner.owner : null}</text>
                </Grid>
  
                  {
                    ( caseAlertMessageDetailsFromApi &&  caseAlertMessageDetailsFromApi !== undefined )?
                    (
                      <Grid item xs={12}>
                        {
                          ( caseAlertMessageDetailsFromApi.curr_state == "PENDING" )?
                          (
                            <Grid>
                              <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                                <Divider />
                              </Grid>
                              <Grid container item xs={12} >
                                <Grid className={classes.CaseRejectedLable}>
                                  Case Pending:&nbsp;
                                </Grid>
                                <Grid className={classes.CaseRejectedComment}>
                                  {caseAlertMessageDetailsFromApi && caseAlertMessageDetailsFromApi.message}
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                          :(<Grid></Grid>)
                        }                    
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  } 
  
                {
                  ( caseDetailsFromApi && caseDetailsFromApi.case_payment == null ) ?
                  (
                    <Grid></Grid>
                  ):
                  (
                    <Grid>
                      {user_permissions && user_permissions.permissions.add_payment_details && 
                        <Grid>
                          <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                            <Divider />
                          </Grid>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submitPaymentDetails}
                            href="/ClientsPaymentInfo"
                          >
                            Payment Details
                          </Button>
                        </Grid>
                      }
                    </Grid>
                  )
                }
                
                <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid>
                
                {/* <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid> */}
  
                {/* <Grid container item xs={12} className={classes.casesetPriorityInfo}>
                  {
                      ( casePriorityIs == "High")?(
                        <Grid>
                          <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                          <text style={{fontSize: 15, fontWeight: 'bold', color:'red'}}>{casePriorityIs && casePriorityIs}</text>
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                    {
                      ( casePriorityIs == "Medium")?(
                        <Grid>
                          <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                          <text style={{fontSize: 15, fontWeight: 'bold', color:'blue'}}>{casePriorityIs && casePriorityIs}</text>
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                    {
                      ( casePriorityIs == "Low")?(
                        <Grid>
                          <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                          <text style={{fontSize: 15, fontWeight: 'bold', color:'green'}}>{casePriorityIs && casePriorityIs}</text>
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                  {/* <Grid style={{fontSize: 15, fontWeight: 'bold'}}>Priority: {casePriorityIs && casePriorityIs} </Grid>
                  &nbsp;&nbsp;&nbsp;&nbsp; */}
                   {/* &nbsp;&nbsp;&nbsp;&nbsp;
                </Grid> */}
                {/* <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid> */}
                {/* <Grid item xs={12} className={classes.caseClientNameInfo} style={{fontWeight:'bold'}}>
                  Client Name and PAN:
                </Grid>
                <Grid item xs={12} className={classes.caseClientNameInfoLowMarginTop}>
                  {caseDetailsFromApi && caseDetailsFromApi.client_name}
                </Grid>
                <Grid item xs={12} className={classes.casePanCardInfo}>
                  {caseDetailsFromApi && caseDetailsFromApi.client_pan}
                </Grid> */}
                <Grid item xs={12} container className={classes.caseClientNameInfo} >
                  {/* SSE Name : {caseDetailsFromApi && caseDetailsFromApi.client_name} */}
                  <Grid style={{fontWeight:'bold'}}> Client Name : </Grid> 
                  <Grid style={{color:'blue', cursor:'pointer'}}
                    onClick={() => handleNavigateToClientDetails()}
                  > 
                    &nbsp; {caseDetailsFromApi && caseDetailsFromApi.client_name} 
                  </Grid>
                </Grid>
                
  
                <Grid item xs={12} container className={classes.caseClientNameInfoLowMarginTop}>
                  {/* SSE PAN : {caseDetailsFromApi && caseDetailsFromApi.client_pan} */}
                  <Grid style={{fontWeight:'bold'}}> Client PAN : </Grid> 
                  <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.client_pan} </Grid>
                </Grid>
                <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid>
                {
                  ( caseDetailsFromApi.self_assessment == false)?
                  (
                    // <Grid>
                    //   <Grid item xs={12} className={classes.caseClientNameInfo} style={{fontWeight:'bold'}}>
                    //     {/* ASSESSE Name and PAN: */}
                    //     Tax Payer Name and PAN:
                    //   </Grid>
                    //   <Grid item xs={12} className={classes.caseClientNameInfoLowMarginTop}>
                    //     {/* John Kumar */}
                    //     {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name}
                    //   </Grid>
                    //   <Grid item xs={12} className={classes.casePanCardInfo}>
                    //     {/* ABCDE1234G */}
                    //     {caseDetailsFromApi && caseDetailsFromApi.for_assesse_pan}
                    //   </Grid>
                    // </Grid>
                    <Grid>
                      <Grid item xs={12} container className={classes.caseClientNameInfo}>
                        <Grid style={{fontWeight:'bold'}}> Tax Payer Name : </Grid> 
                        {/* <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name} </Grid> */}
                        <Grid style={{color:'blue', cursor:'pointer'}}
                          onClick={() => handleNavigateToClientDetails()}
                        > 
                          &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name} 
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container className={classes.caseClientNameInfoLowMarginTop}>
                        <Grid style={{fontWeight:'bold'}}> Tax Payer PAN : </Grid> 
                        <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_pan} </Grid>
                      </Grid>
                      <Grid style={{paddingTop:8, paddingBottom: 8, width:'119%'}}>
                        <Divider />
                      </Grid>
                    </Grid>
                    
                  )
                  :(<div></div>)
                }
                
                {/* <Grid item xs={12} className={classes.casePhoneNumberInfo}>
                  <span className={classes.phoneIcon}>
                    <LocalPhoneIcon />
                  </span>{" "}
                  Call
                </Grid>
                <Grid item xs={12} className={classes.caseMessageInfo}>
                  <span className={classes.messageIcon}>
                    <MessageIcon />
                  </span>{" "}
                  Messages
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid> */}
                
                {/* tags */}
                <Grid item xs={12} style={{fontWeight:'bold'}}> Tags : </Grid>
                <Grid container>
                  {
                    caseTagDetails && caseTagDetails.map((e,i) => (
                      // <Grid style={{ color: e.tag_color}}>{e.tag_name}</Grid>
                      // <Grid style={{marginTop:"1%"}}>
                      //   <Grid style={{ backgroundColor: e.tag_color, padding: 5, fontWeight:'bold'}}>{e.tag_name}
                      //       { CaseTagDetailsLength-1 == i ? null : "," }
                      //   </Grid>
                      // </Grid>
                      <Grid style={{marginTop:"0.5%"}}>
                        <Grid  style={{ color: e.tag_color, padding: 3, fontWeight:'bold'}}>{e.tag_name}
                          { CaseTagDetailsLength-1 == i ? null : "," }
                        </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
    
                {/* <Grid item xs={12} className={classes.caseAddTagInfo}>
                  <Button
                    className={classes.addButtonStyle}
                    onClick={handleModalOpen}
                    startIcon={<AddIcon />}
                  >
                    <FormattedMessage id="addMoreLabel" />
                  </Button>
                </Grid> */}
                <Grid item xs={12} className={classes.caseAddTagInfo}></Grid>
                {/* <Modal
                  open={modalTagOpen}
                  onClose={handleModalClose}
                  className={classes.modal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {body}
                </Modal>
    
                <Modal
                  open={modalAddTagOpen}
                  onClose={handelAddNewTagModalClose}
                  className={classes.modal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {bodyAddTag}
                </Modal> */}
    
    
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Paper elevation={0} className={classes.customPaperStyle}>
                <div className={classes.casesDetailSubMenu}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "overviewLink" })}
                    />       
                    <Tab
                      className={classes.tabCustomStyle}
                      label="Notes"
                    />          
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "activityLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "messagesLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "documentsLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label="Q and A"
                    />
                  </Tabs>
                </div>
                <TabPanel value={value} index={0}>
                  <TaxManagementCasesOverView fromCaseOverviewCompleteCase={handlefromCaseOverviewCompleteCase}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <TaxManagementTrpNotes/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <TaxManagementCasesActivity />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <TaxManagementCasesMessages />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <TaxManagementCasesDocuments />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <TaxManagementCasesQandA caseQuestionAnswers={caseQuestionAnswers}/>
                </TabPanel>
              </Paper>
            </Grid>
          </Grid>
          
        </div>
        {/* close case */}
          <Modal
            open={OpenCloseCase}
            onClose={handleCloseCaseModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyCloseCase}
          </Modal> 
        </div>
      );
    }
    else if(caseForDetailsFromAsync.case_curr_state == "CLOSED"){
      console.log("case status is CLOSED")
      return (
        console.log("caseForDetailsFromAsync,caseDetailsShowFromRedux",caseForDetailsFromAsync,caseDetailsShowFromRedux),
        console.log(",caseDetailsFromApi,casePriorityIs,",
          caseDetailsFromApi,casePriorityIs),
        //   console.log(" allTagDetails", allTagDetails),
        <div className={classes.root}>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
           <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        <div className={classes.wrapper}>
          <Grid container>
            <Grid item xs={3} className={classes.casesDetailInfo}>
              <Grid container>
                <Grid item xs={12}>
                  {
                    (navigatedFrom && navigatedFrom == "Cases")?
                    (
                      <Button href="/cases" onClick={handleChange} className={classes.backButton}>
                        <span className={classes.backArrow}>
                          <ArrowBackIcon fontSize="small" />
                        </span>
                        <FormattedMessage id="backLabel" />
                      </Button>
                    ):
                    (
                      ( navigatedFrom && navigatedFrom == "SearchResult" )? 
                      (
                        <Button href="/SearchResult" onClick={handleChange} className={classes.backButton}>
                          <span className={classes.backArrow}>
                            <ArrowBackIcon fontSize="small" />
                          </span>
                          <FormattedMessage id="backLabel" />
                        </Button>
                      ):
                      (<div></div>)
                    )
                  }             
                </Grid>
                <Grid item xs={12} className={classes.casesNameInfo}>
                  {/* TF123 */}
                  Case Name : {caseDetailsFromApi && caseDetailsFromApi.name}
                </Grid>
                <Grid container item xs={12}>
                  {/* Description - {caseDetailsFromApi && caseDetailsFromApi.description} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Description : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.description}
                  </text>
                </Grid>
                <Grid container>
                  {/* Service - {caseDetailsFromApi && caseDetailsFromApi.service} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Service : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.service}
                  </text>
                </Grid>
                <Grid container>
                  {/* Process - {selec} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Process : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{currentProcessName}
                  </text>
                </Grid>
                <Grid container>
                  {/* Assesment Year - {caseDetailsFromApi && caseDetailsFromApi.assessment_year} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Assesment Year : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.assessment_year}
                  </text>
                </Grid>
                <Grid container>
                  {/* Created Date - {caseDetailsFromApi && caseDetailsFromApi.created_date} */}
                  {/* Created Date - {caseForDetailsFromAsync && caseForDetailsFromAsync.created_date} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Created Date : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    {/* &nbsp;{caseDetailsFromApi && caseDetailsFromApi.created_date} */}
                    &nbsp;{createdDateFunc(caseDetailsFromApi && caseDetailsFromApi.created_date)}
                  </text>
                </Grid>
                <Grid container >
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Case Status : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'green'}}>&nbsp;{caseForDetailsFromAsync && caseForDetailsFromAsync.case_curr_state}</text>
                </Grid>
                <Grid container >
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Assigned : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>&nbsp;{caseDetailsFromApi && caseDetailsFromApi.owner ? caseDetailsFromApi.owner.owner : null}</text>
                </Grid>
  
                  {
                    ( caseAlertMessageDetailsFromApi &&  caseAlertMessageDetailsFromApi !== undefined )?
                    (
                      <Grid item xs={12}>
                        {
                          ( caseAlertMessageDetailsFromApi.curr_state == "PENDING" )?
                          (
                            <Grid>
                              <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                                <Divider />
                              </Grid>
                              <Grid container item xs={12} >
                                <Grid className={classes.CaseRejectedLable}>
                                  Case Pending:&nbsp;
                                </Grid>
                                <Grid className={classes.CaseRejectedComment}>
                                  {caseAlertMessageDetailsFromApi && caseAlertMessageDetailsFromApi.message}
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                          :(<Grid></Grid>)
                        }                    
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  } 
  
                {
                  ( caseDetailsFromApi && caseDetailsFromApi.case_payment == null ) ?
                  (
                    <Grid></Grid>
                  ):
                  (
                    <Grid>
                      {user_permissions && user_permissions.permissions.add_payment_details &&
                        <Grid>
                          <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                            <Divider />
                          </Grid>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submitPaymentDetails}
                            href="/ClientsPaymentInfo"
                          >
                            Payment Details
                          </Button>
                        </Grid>
                      } 
                    </Grid>
                  )
                }
                
                <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid>
                
                {/* <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid> */}
  
                {/* <Grid container item xs={12} className={classes.casesetPriorityInfo}>
                  {
                      ( casePriorityIs == "High")?(
                        <Grid>
                          <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                          <text style={{fontSize: 15, fontWeight: 'bold', color:'red'}}>{casePriorityIs && casePriorityIs}</text>
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                    {
                      ( casePriorityIs == "Medium")?(
                        <Grid>
                          <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                          <text style={{fontSize: 15, fontWeight: 'bold', color:'blue'}}>{casePriorityIs && casePriorityIs}</text>
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                    {
                      ( casePriorityIs == "Low")?(
                        <Grid>
                          <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                          <text style={{fontSize: 15, fontWeight: 'bold', color:'green'}}>{casePriorityIs && casePriorityIs}</text>
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                  {/* <Grid style={{fontSize: 15, fontWeight: 'bold'}}>Priority: {casePriorityIs && casePriorityIs} </Grid>
                  &nbsp;&nbsp;&nbsp;&nbsp; */}
                   {/* &nbsp;&nbsp;&nbsp;&nbsp;
                </Grid> */}
                {/* <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid> */}
                {/* <Grid item xs={12} className={classes.caseClientNameInfo} style={{fontWeight:'bold'}}>
                  Client Name and PAN:
                </Grid>
                <Grid item xs={12} className={classes.caseClientNameInfoLowMarginTop}>
                  {caseDetailsFromApi && caseDetailsFromApi.client_name}
                </Grid>
                <Grid item xs={12} className={classes.casePanCardInfo}>
                  {caseDetailsFromApi && caseDetailsFromApi.client_pan}
                </Grid> */}
                <Grid item xs={12} container className={classes.caseClientNameInfo} >
                  {/* SSE Name : {caseDetailsFromApi && caseDetailsFromApi.client_name} */}
                  <Grid style={{fontWeight:'bold'}}> Client Name : </Grid> 
                  <Grid style={{color:'blue', cursor:'pointer'}}
                    onClick={() => handleNavigateToClientDetails()}
                  > 
                    &nbsp; {caseDetailsFromApi && caseDetailsFromApi.client_name} 
                  </Grid>
                </Grid>
                
  
                <Grid item xs={12} container className={classes.caseClientNameInfoLowMarginTop}>
                  {/* SSE PAN : {caseDetailsFromApi && caseDetailsFromApi.client_pan} */}
                  <Grid style={{fontWeight:'bold'}}> Client PAN : </Grid> 
                  <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.client_pan} </Grid>
                </Grid>
                <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid>
                {
                  ( caseDetailsFromApi.self_assessment == false)?
                  (
                    // <Grid>
                    //   <Grid item xs={12} className={classes.caseClientNameInfo} style={{fontWeight:'bold'}}>
                    //     {/* ASSESSE Name and PAN: */}
                    //     Tax Payer Name and PAN:
                    //   </Grid>
                    //   <Grid item xs={12} className={classes.caseClientNameInfoLowMarginTop}>
                    //     {/* John Kumar */}
                    //     {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name}
                    //   </Grid>
                    //   <Grid item xs={12} className={classes.casePanCardInfo}>
                    //     {/* ABCDE1234G */}
                    //     {caseDetailsFromApi && caseDetailsFromApi.for_assesse_pan}
                    //   </Grid>
                    // </Grid>
                    <Grid>
                      <Grid item xs={12} container className={classes.caseClientNameInfo}>
                        <Grid style={{fontWeight:'bold'}}> Tax Payer Name : </Grid> 
                        {/* <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name} </Grid> */}
                        <Grid style={{color:'blue', cursor:'pointer'}}
                          onClick={() => handleNavigateToClientDetails()}
                        > 
                          &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name} 
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container className={classes.caseClientNameInfoLowMarginTop}>
                        <Grid style={{fontWeight:'bold'}}> Tax Payer PAN : </Grid> 
                        <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_pan} </Grid>
                      </Grid>
                      <Grid style={{paddingTop:8, paddingBottom: 8, width:'119%'}}>
                        <Divider />
                      </Grid>
                    </Grid>
                    
                  )
                  :(<div></div>)
                }
                
                {/* <Grid item xs={12} className={classes.casePhoneNumberInfo}>
                  <span className={classes.phoneIcon}>
                    <LocalPhoneIcon />
                  </span>{" "}
                  Call
                </Grid>
                <Grid item xs={12} className={classes.caseMessageInfo}>
                  <span className={classes.messageIcon}>
                    <MessageIcon />
                  </span>{" "}
                  Messages
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid> */}
                
                {/* tags */}
                <Grid item xs={12} style={{fontWeight:'bold'}}> Tags : </Grid>
                <Grid container>
                  {
                    caseTagDetails && caseTagDetails.map((e,i) => (
                      // <Grid style={{ color: e.tag_color}}>{e.tag_name}</Grid>
                      // <Grid style={{marginTop:"1%"}}>
                      //   <Grid style={{ backgroundColor: e.tag_color, padding: 5, fontWeight:'bold'}}>{e.tag_name}
                      //       { CaseTagDetailsLength-1 == i ? null : "," }
                      //   </Grid>
                      // </Grid>
                      <Grid style={{marginTop:"0.5%"}}>
                        <Grid  style={{ color: e.tag_color, padding: 3, fontWeight:'bold'}}>{e.tag_name}
                          { CaseTagDetailsLength-1 == i ? null : "," }
                        </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
    
                {/* <Grid item xs={12} className={classes.caseAddTagInfo}>
                  <Button
                    className={classes.addButtonStyle}
                    onClick={handleModalOpen}
                    startIcon={<AddIcon />}
                  >
                    <FormattedMessage id="addMoreLabel" />
                  </Button>
                </Grid> */}
                <Grid item xs={12} className={classes.caseAddTagInfo}></Grid>
                {/* <Modal
                  open={modalTagOpen}
                  onClose={handleModalClose}
                  className={classes.modal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {body}
                </Modal>
    
                <Modal
                  open={modalAddTagOpen}
                  onClose={handelAddNewTagModalClose}
                  className={classes.modal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {bodyAddTag}
                </Modal> */}
    
    
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Paper elevation={0} className={classes.customPaperStyle}>
                <div className={classes.casesDetailSubMenu}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "overviewLink" })}
                    />       
                    <Tab
                      className={classes.tabCustomStyle}
                      label="Notes"
                    />         
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "activityLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "messagesLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "documentsLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label="Q and A"
                    />
                  </Tabs>
                </div>
                <TabPanel value={value} index={0}>
                  <TaxManagementCasesOverView fromCaseOverviewCompleteCase={handlefromCaseOverviewCompleteCase}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <TaxManagementTrpNotes/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <TaxManagementCasesActivity />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <TaxManagementCasesMessages />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <TaxManagementCasesDocuments />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <TaxManagementCasesQandA caseQuestionAnswers={caseQuestionAnswers}/>
                </TabPanel>
              </Paper>
            </Grid>
          </Grid>
          
        </div>
        </div>
      );
    }
    // else if(caseForDetailsFromAsync.case_curr_state == "CONFIRMED" || caseForDetailsFromAsync.case_curr_state == "PENDING"
    // || caseForDetailsFromAsync.case_curr_state == "PAYMENT-PENDING" || caseForDetailsFromAsync.case_curr_state == "COMPLETED" ){
      else if(caseForDetailsFromAsync.case_curr_state == "IN-PROGRESS" || caseForDetailsFromAsync.case_curr_state == "PENDING"
      || caseForDetailsFromAsync.case_curr_state == "PAYMENT-PENDING" ){  
    console.log("case status is CONFIRMED")
      return (
        // console.log("caseForDetailsFromAsync,caseDetailsShowFromRedux",caseForDetailsFromAsync,caseDetailsShowFromRedux),
        // console.log(",caseDetailsFromApi,casePriorityIs,",
        //   caseDetailsFromApi,casePriorityIs),
        //   console.log(" allTagDetails", allTagDetails),
        console.log("caseAlertMessageDetailsFromApi======",caseAlertMessageDetailsFromApi),
        <div className={classes.root}>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
           <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        <div className={classes.wrapper}>
          <Grid container>
            <Grid item xs={3} className={classes.casesDetailInfo}>
              <Grid container>
                <Grid  container>
                  <Grid item xs={9}>
                    {
                      (navigatedFrom && navigatedFrom == "Cases")?
                      (
                        <Button href="/cases" onClick={handleChange} className={classes.backButton}>
                          <span className={classes.backArrow}>
                            <ArrowBackIcon fontSize="small" />
                          </span>
                          <FormattedMessage id="backLabel" />
                        </Button>
                      ):
                      (
                        ( navigatedFrom && navigatedFrom == "SearchResult" )? 
                        (
                          <Button href="/SearchResult" onClick={handleChange} className={classes.backButton}>
                            <span className={classes.backArrow}>
                              <ArrowBackIcon fontSize="small" />
                            </span>
                            <FormattedMessage id="backLabel" />
                          </Button>
                        ):
                        (<div></div>)
                      )
                    }  
                  </Grid> 
                  <Grid container item xs={3}> 
                  {/* <Grid container item xs={3} style={{cursor:'pointer'}} onClick={handleToggleMenuIcon}  id="MenuSettings"> */}
                    {/* <Grid className={classes.menuLableStyle} style={{marginTop:'12%'}}>Menu</Grid>
                    <MenuIcon/>   */}
                    <Button id="MenuSettings" onClick={handleToggleMenuIcon} className={classes.backButton}>
                      <FormattedMessage id="menuLabel" />
                      <span className={classes.backArrow}>
                        <MenuIcon />
                      </span>
                    </Button>
                  </Grid> 
                  <div>
                    {
                      ( caseDetailsFromApi.case_curr_state == "PENDING" ) ? 
                      (
                        <Popover placement="bottom" trigger="legacy" target="MenuSettings"
                          isOpen={OpenMenu} 
                          toggle={handleToggleMenuIcon}
                          style={{ backgroundColor: "white", color: "white", width: "115%", marginLeft: "-65%",
                                  padding: 10, marginTop:'-2%' }}
                            >
                              <PopoverBody>
                                  {user_permissions && user_permissions.permissions.switch_step &&
                                    <div>
                                      <text style={{cursor: 'pointer', color: "black",  padding : 1,marginLeft: "26%"}}  
                                        onClick={() => switchStepConfirmedFunc()}
                                      >
                                        Switch Step
                                      </text>
                                      <hr/>
                                    </div>
                                  }

                                {user_permissions && user_permissions.permissions.assign_user_to_case &&
                                    <div>
                                      <text style={{cursor: 'pointer',color: "black", padding : 1, marginLeft: "15%"}}  
                                        onClick={() => AssignUserConfirmedFunc()}
                                      >
                                        {/* Assign User */}
                                        Assign Case Owner
                                      </text> 
                                    <hr/>
                                  </div>
                                }

                                <text style={{cursor: 'pointer',color: "black", padding : 1, marginLeft: "10%"}}  
                                  onClick={() => AwayFromPendingInfoConfirmFunc()}
                                >
                                  Away from Pending Info
                                </text> 

                                {/* {user_permissions && user_permissions.permissions.close_case &&
                                  <div>
                                    <hr/>
                                    <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "16%"}}  
                                      onClick={() => CloseCaseConfirmFunc()}
                                    >
                                      Close Case
                                    </text> 
                                  </div>
                                } */}
                                
                          </PopoverBody>
                        </Popover>
                      ):
                      (
                        <Popover placement="bottom" trigger="legacy" target="MenuSettings"
                          isOpen={OpenMenu} 
                          toggle={handleToggleMenuIcon}
                          style={{ backgroundColor: "white", color: "white", width: "110%", marginLeft: "-65%",
                                  padding: 10, marginTop:'-2%' }}
                        >
                          <PopoverBody>

                              {user_permissions && user_permissions.permissions.switch_step &&
                                <div>
                                  <text style={{cursor: 'pointer', color: "black", padding: 10, marginLeft: "20%"}}  
                                    onClick={() => switchStepConfirmedFunc()}
                                  >
                                    Switch Step
                                  </text>
                                  <hr/>
                                </div>
                              }
                              {user_permissions && user_permissions.permissions.assign_user_to_case &&
                                <div>
                                  <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "5%"}}  
                                    onClick={() => AssignUserConfirmedFunc()}
                                  >
                                    {/* Assign User */}
                                    Assign Case Owner
                                  </text> 
                                    <hr/>
                                </div>
                              }
                              {/* <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "16%"}}  
                                onClick={() => PendingInfoConfirmFunc()}
                              >
                                Pending Info
                              </text>    */}
                            <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "20%"}}  
                              onClick={() => PendingInfoConfirmFunc()}
                            >
                              Pending Info
                            </text> 

                            {/* {user_permissions && user_permissions.permissions.close_case &&
                              <div>
                                <hr/>
                                <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "20%"}}  
                                  onClick={() => CloseCaseConfirmFunc()}
                                >
                                  Close Case
                                </text> 
                              </div>
                            } */}
                                
                          </PopoverBody>
                        </Popover>
                      )
                    }
                    
                  </div>

                </Grid>
                <Grid item xs={12} className={classes.casesNameInfo}>
                  {/* TF123 */}
                  Case Name : {caseDetailsFromApi && caseDetailsFromApi.name}
                </Grid>
                <Grid container item xs={12}>
                  {/* Description - {caseDetailsFromApi && caseDetailsFromApi.description} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Description : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.description}
                  </text>
                </Grid>
                <Grid container>
                  {/* Service - {caseDetailsFromApi && caseDetailsFromApi.service} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Service : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.service}
                  </text>
                </Grid>
                <Grid container>
                  {/* Process - {selec} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Process : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{currentProcessName}
                  </text>
                </Grid>
                <Grid container>
                  {/* Assesment Year - {caseDetailsFromApi && caseDetailsFromApi.assessment_year} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Assesment Year : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    &nbsp;{caseDetailsFromApi && caseDetailsFromApi.assessment_year}
                  </text>
                </Grid>
                <Grid container>
                  {/* Created Date - {caseDetailsFromApi && caseDetailsFromApi.created_date} */}
                  {/* Created Date - {caseForDetailsFromAsync && caseForDetailsFromAsync.created_date} */}
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Created Date : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>
                    {/* &nbsp;{caseDetailsFromApi && caseDetailsFromApi.created_date} */}
                    &nbsp;{createdDateFunc(caseDetailsFromApi && caseDetailsFromApi.created_date)}
                  </text>
                </Grid>
                <Grid container >
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Case Status : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'green'}}>&nbsp;{caseForDetailsFromAsync && caseForDetailsFromAsync.case_curr_state}</text>
                </Grid>
                <Grid container >
                  <text style={{fontSize: 15, fontWeight: 'bold'}}>Assigned : </text>
                  <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>&nbsp;{caseDetailsFromApi && caseDetailsFromApi.owner ? caseDetailsFromApi.owner.owner : null}</text>
                </Grid>

                {
                  ( caseAlertMessageDetailsFromApi &&  caseAlertMessageDetailsFromApi !== undefined )?
                  (
                    <Grid item xs={12}>
                      {
                        ( caseAlertMessageDetailsFromApi.curr_state == "PENDING" )?
                        (
                          <Grid>
                            <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                              <Divider />
                            </Grid>
                            <Grid container item xs={12} >
                              <Grid className={classes.CaseRejectedLable}>
                                Case Pending:&nbsp;
                              </Grid>
                              <Grid className={classes.CaseRejectedComment}>
                                {caseAlertMessageDetailsFromApi && caseAlertMessageDetailsFromApi.message}
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                        :(<Grid></Grid>)
                      }                    
                    </Grid>
                  ):
                  (<Grid></Grid>)
                }      
                
                <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid>
                
                {user_permissions && user_permissions.permissions.add_payment_details &&
                  <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submitPaymentDetails}
                      href="/TrpsPaymentInfo"
                    >
                      Payment Details
                  </Button>
                }

                <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid>
                
                {/* <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid> */}

                <Grid container item xs={12} className={classes.casesetPriorityInfo}>
                  {/* {
                    ( casePriorityIs == "High")?(
                      <Grid>
                        <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                        <text style={{fontSize: 15, fontWeight: 'bold', color:'red'}}>{casePriorityIs && casePriorityIs}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</text>
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  }
                  {
                    ( casePriorityIs == "Medium")?(
                      <Grid>
                        <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                        <text style={{fontSize: 15, fontWeight: 'bold', color:'blue'}}>{casePriorityIs && casePriorityIs}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</text>
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  }
                  {
                    ( casePriorityIs == "Low")?(
                      <Grid>
                        <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                        <text style={{fontSize: 15, fontWeight: 'bold', color:'green'}}>{casePriorityIs && casePriorityIs}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</text>
                      </Grid>
                    ):
                    (<Grid></Grid>)
                  } */}
                  <Grid>
                    <text style={{fontSize: 15, fontWeight: 'bold'}}>Priority:  </text>
                    <text style={{fontSize: 15, fontWeight: 'bold', color:'black'}}>{casePriorityIs && casePriorityIs}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</text>
                  </Grid>

                  <Button
                    className={classes.setPriorityButton}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    id="setPriorityLabel"
                    onClick={handleToggle}
                  >
                    Change Priority
                    <span className={classes.verticalAlignTop}>
                      <ExpandMoreIcon />
                    </span>
                  </Button>
                  
                  <div>
                    <Popover placement="bottom" trigger="legacy" target="setPriorityLabel"
                      isOpen={open} 
                      toggle={handleToggle}
                      style={{ backgroundColor: "white", color: "white", width: "140%", marginLeft: "-20%",
                               padding: 10 }}
                    >
                      <PopoverBody>
                        {
                          (casePriorityIs == "High")?
                          (
                            <text style={{cursor: 'pointer', color: "blue", padding: 10, marginLeft: "25%"}}  
                              onClick={(e) => handleChangePriority("High")}
                            >
                              High
                            </text>
                          )
                          :(
                            <text style={{cursor: 'pointer', color: "black", padding: 10, marginLeft: "25%"}}  
                              onClick={(e) => handleChangePriority("High")}
                            >
                              High
                            </text>
                          )
                        }                  
                        <hr/>
                        {
                          (casePriorityIs == "Low")?
                          (
                             <text style={{cursor: 'pointer',color: "blue", padding: 10, marginLeft: "25%"}}  
                                onClick={(e) => handleChangePriority("Low")}
                              >
                                Low
                              </text>
                          )
                          :(
                             <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "25%"}}  
                                onClick={(e) => handleChangePriority("Low")}
                              >
                                Low
                              </text>
                          )
                        }
                        <hr/>
                        {
                          (casePriorityIs == "Medium")?
                          (
                             <text style={{cursor: 'pointer',color: "blue", padding: 10, marginLeft: "17%" }}  
                                onClick={(e) => handleChangePriority("Medium")}
                              >
                                Medium
                              </text>
                          )
                          :(
                             <text style={{cursor: 'pointer',color: "black", padding: 10, marginLeft: "17%" }}  
                                onClick={(e) => handleChangePriority("Medium")}
                              >
                                Medium
                              </text>
                          )
                        }
                        
                      </PopoverBody>
                    </Popover>
                  </div>
                </Grid>
                <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid>
                {/* <Grid item xs={12} className={classes.caseClientNameInfo} style={{fontWeight:'bold'}}>
                  Client Name and PAN:
                </Grid>
                <Grid item xs={12} className={classes.caseClientNameInfoLowMarginTop}>
                  {caseDetailsFromApi && caseDetailsFromApi.client_name}
                </Grid>
                <Grid item xs={12} className={classes.casePanCardInfo}>
                  {caseDetailsFromApi && caseDetailsFromApi.client_pan}
                </Grid> */}
                <Grid item xs={12} container className={classes.caseClientNameInfo}>
                  {/* SSE Name : {caseDetailsFromApi && caseDetailsFromApi.client_name} */}
                  <Grid style={{fontWeight:'bold'}}> Client Name : </Grid> 
                  <Grid style={{color:'blue', cursor:'pointer'}}
                    onClick={() => handleNavigateToClientDetails()}
                  > 
                    &nbsp; {caseDetailsFromApi && caseDetailsFromApi.client_name} 
                  </Grid>
                  <Grid style={{textAlign:'right'}}>
                    <Link to="/CommunicationInterface" target="_blank">
                      <MessageIconChat style={{ fontSize: 25, cursor: 'pointer', color: 'grey', }} />
                    </Link>
                  </Grid>
                </Grid>

                

                <Grid item xs={12} container className={classes.caseClientNameInfoLowMarginTop}>
                  {/* SSE PAN : {caseDetailsFromApi && caseDetailsFromApi.client_pan} */}
                  <Grid style={{fontWeight:'bold'}}> Client PAN : </Grid> 
                  <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.client_pan} </Grid>
                </Grid>
                <Grid item xs={12} style={{paddingTop:8, paddingBottom: 8}}>
                  <Divider />
                </Grid>
                {
                  ( caseDetailsFromApi.self_assessment == false)?
                  (
                    // <Grid>
                    //   <Grid item xs={12} className={classes.caseClientNameInfo} style={{fontWeight:'bold'}}>
                    //     {/* ASSESSE Name and PAN: */}
                    //     Tax Payer Name and PAN:
                    //   </Grid>
                    //   <Grid item xs={12} className={classes.caseClientNameInfoLowMarginTop}>
                    //     {/* John Kumar */}
                    //     {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name}
                    //   </Grid>
                    //   <Grid item xs={12} className={classes.casePanCardInfo}>
                    //     {/* ABCDE1234G */}
                    //     {caseDetailsFromApi && caseDetailsFromApi.for_assesse_pan}
                    //   </Grid>
                    // </Grid>
                    <Grid>
                      <Grid item xs={12} container className={classes.caseClientNameInfo}>
                        <Grid style={{fontWeight:'bold'}}> Tax Payer Name : </Grid> 
                        {/* <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name} </Grid> */}
                        <Grid style={{color:'blue', cursor:'pointer'}}
                          onClick={() => handleNavigateToClientDetails()}
                        > 
                          &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_name} 
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container className={classes.caseClientNameInfoLowMarginTop}>
                        <Grid style={{fontWeight:'bold'}}> Tax Payer PAN : </Grid> 
                        <Grid> &nbsp; {caseDetailsFromApi && caseDetailsFromApi.for_assesse_pan} </Grid>
                      </Grid>
                      
                      <Grid style={{paddingTop:8, paddingBottom: 8, width:'119%'}}>
                        <Divider />
                      </Grid>
                    </Grid>
                  )
                  :(<div></div>)
                }

                {/* <Grid item xs={12} className={classes.casePhoneNumberInfo}>
                  <span className={classes.phoneIcon}>
                    <LocalPhoneIcon />
                  </span>{" "}
                  {/* 812304662 */}
                  {/* {caseDetailsFromApi && caseDetailsFromApi.for_assesse_phone} */}
                  {/* Call
                </Grid>
                <Grid item xs={12} className={classes.caseMessageInfo}>
                  <span className={classes.messageIcon}>
                    <MessageIcon />
                  </span>{" "}
                  Messages
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid> */} 
                
                {/* tags */}
                <Grid item xs={12} style={{fontWeight:'bold'}}> Tags : </Grid>
                <Grid container>
                  {
                    caseTagDetails && caseTagDetails.map((e,i) => (
                      console.log("index in case tag details is",i,CaseTagDetailsLength),
                      // <Grid style={{ color: e.tag_color}}>{e.tag_name}</Grid>
                      <Grid style={{marginTop:"0.5%"}}>
                        <Grid  style={{ color: e.tag_color, padding: 3, fontWeight:'bold'}}>{e.tag_name}
                          { CaseTagDetailsLength-1 == i ? null : "," }
                          {/* {
                            (e.length -1)?(<div></div>):(<div>,</div>)
                            {e.length -1 ? null : ","}
                          } */}
                        </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
                
                {/* add tags */}
                {user_permissions && user_permissions.permissions.assign_tags_to_case &&
                  <Grid item xs={12} className={classes.caseAddTagInfo}>
                    <Button
                      className={classes.addButtonStyle}
                      // onClick={handleModalOpen}
                      onClick={handleTagsDropdownOpen}
                      startIcon={<AddIcon />}
                    >
                      <FormattedMessage id="addMoreLabel" />
                    </Button>
                  </Grid>
                } 
                {
                  ( ShwoTagsdropdown === true )?
                  (
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Multiselect
                          data={allTagDetails}
                          defaultValue={TagSelectedNames}
                          placeholder="Select Tags"
                          valueField='tag_id'
                          textField='name'
                          onChange={async (event) => {
                            console.log("event of multiselect",event);
                            var joined = [];
                            var joinedNames = [];
                            // console.log("length : "+event.length);
                            if (event.length === 0) {
                              await setTagSelected([])
                            }
                            event.map(async (data) => {
                              // console.log("data in eve map : "+JSON.stringify(data));
                              joined = joined.concat(data.tag_id);
                              joinedNames = joinedNames.concat(data);
                              // console.log("data join : ",joined);
                              await setTagSelected(joined)
                              await setTagSelectedNames(joinedNames)
                              // console.log("check this TagFilter",TagFilter)
                            })
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} container justify="flex-end" style={{ marginTop:'2%' }} spacing={2}>
                        <Button
                          // className={classes.textTransformNone}
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => handelSubmitSelectTags()}
                        >
                          Add
                        </Button>
                         <Grid style={{marginLeft:'2%'}}></Grid> 
                        <Button
                          
                          // className={classes.textTransformNone}
                          size="small"
                          variant="contained"
                          // color="primary"
                          onClick={() => handleTagsDropdownClose()}
                        >
                          Cancel
                        </Button>
                      </Grid>

                    </Grid>
                  ):
                  (<></>)
                }

                {/* craete new tag new design */}
                <Grid style={{}}>
                {role_of_user_Global && role_of_user_Global !== "client" && user_permissions && user_permissions.permissions.create_tags &&
                  <Button
                    className={classes.addButtonStyle}
                    startIcon={<AddIcon />}
                    onClick={() => handelAddNewTagModalOpen()}
                  >
                    <FormattedMessage id="createNewTagLabel" />
                  </Button>
                }
                </Grid>
                
                 
                <Grid>

                </Grid>

                <Grid item xs={12} className={classes.caseAddTagInfo}></Grid>
                <Modal
                  open={modalTagOpen}
                  onClose={handleModalClose}
                  className={classes.modal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {body}
                </Modal>
    
                <Modal
                  open={modalAddTagOpen}
                  onClose={handelAddNewTagModalClose}
                  className={classes.modal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {bodyAddTag}
                </Modal>
    
    
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Paper elevation={1} className={classes.customPaperStyle}>
                <div className={classes.casesDetailSubMenu}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className={classes.tabCustomStyle}
                      // style={{ minWidth: 50 }}
                      label={intl.formatMessage({ id: "overviewLink" })}
                    /> 
                    <Tab
                      className={classes.tabCustomStyle}
                      label="Notes"
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "activityLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "messagesLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label={intl.formatMessage({ id: "documentsLink" })}
                    />
                    <Tab
                      className={classes.tabCustomStyle}
                      label="Q and A"
                    />
                    
                  </Tabs>
                </div>
                <TabPanel value={value} index={0}>
                  <TaxManagementCasesOverView fromCaseOverviewCompleteCase={handlefromCaseOverviewCompleteCase}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <TaxManagementTrpNotes />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <TaxManagementCasesActivity />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <TaxManagementCasesMessages />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <TaxManagementCasesDocuments />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <TaxManagementCasesQandA caseQuestionAnswers={caseQuestionAnswers}/>
                </TabPanel>
                
              </Paper>
            </Grid>
          </Grid>
          
        </div>
          {/* switch step modal */}
          <Modal
            open={openSwitchStepConfirmed}
            onClose={handleSwitchStepConfirmedModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodySwitchStepConfirmed}
          </Modal>
          {/* Assign User same for both created and confirm/In progress case status */}
          <Modal
            open={openAssignUserModal}
            onClose={handleAssignUserConfirmedModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAssignUserConfirmed}
          </Modal>
          {/* Pending Info */}
          <Modal
            open={OpenPendingInfo}
            onClose={handlePendingInfoModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyPendingInfo}
          </Modal> 

          {/* close case modal */}
          <Modal
            open={OpenCloseCase}
            onClose={handleCloseCaseModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyCloseCase}
          </Modal> 

          {/* Reject case */}
          <Modal
            open={OpenRejectCase}
            onClose={handleRejectCaseModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyRejectCase}
          </Modal> 
        </div>
      );
    }
  }

}

export default injectIntl(TaxManagementCaseDetails);
